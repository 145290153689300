<template>
  <v-combobox
    multiple
    chips
    deletable-chips
    validate-on-blur
    v-on="$listeners"
    :items="availableProviders"
    :value="sortedProviders"
    item-text="name"
    item-value="id"
    label="Provider(s) *"
    placeholder="Provider"
    :outlined="true"
    :disabled="disabled"
    :menu-props="{ contentClass: 'sre-see-providers-select-menu' }"
    :rules="[
      validators.duplicateOption(isDuplicateOption),
      validators.required(providersData, 'Provider(s)'),
      validators.isProvidersExists(providersData, availableProviders)
    ]"
    no-data-text="Please select a title"
    :class="isDuplicateOption ? 'v-chip-select' : 'v-chip--clickable'"
    @change="updateProvidersData($event)"
    persistent-placeholder
  >
    <template #selection="{ item }">
      <v-chip
        close
        outlined
        :class="getProviderClass(item)"
        @click:close="removeProvider(item)"
      >
        <span>{{ item.name || item }}</span>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import validators from '@/helpers/validators'

export default {
  name: 'AppSettingsSreSeeProviders',
  props: {
    providers: {
      type: Array,
      default: () => []
    },
    availableProviders: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isDuplicateOption: {
      type: Boolean,
      default: false
    },
    seeSreText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      validators: {
        required: validators.required,
        duplicateOption: validators.duplicateOption,
        isProvidersExists: validators.providersExists
      },
      providersData: this.providers
    }
  },
  watch: {
    providers() {
      this.providersData = this.providers
    }
  },
  computed: {
    sortedProviders() {
      return [...this.providersData].sort((a, b) =>
        (a.name || a).localeCompare(b.name || b)
      )
    }
  },
  methods: {
    removeProvider(provider) {
      const providers = this.providersData.filter(function (item) {
        return item.id !== provider.id
      })
      this.providersData = providers
      this.$emit('change', this.providersData)
    },
    getProviderClass(provider) {
      const foundItem = this.availableProviders.find((item) => {
        return item.name === provider.name
      })
      return !foundItem ? 'error' : ''
    },
    updateProvidersData(obj) {
      this.providersData = obj
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-chip--clickable {
  border: 1px solid $color-primary;
  background: $color-selected-item !important;
}
</style>
<style lang="scss">
.sre-see-providers-select-menu .v-list .v-list-item--active {
  background: $color-selected-item !important;
  :before {
    background: $color-selected-item !important;
  }
  .v-list-item__title {
    color: $color-primary !important;
  }
}
.sre-option-wrapper,
.see-option-wrapper {
  .v-chip.v-chip--outlined,
  .v-chip.v-chip--outlined.v-chip.v-chip {
    border-color: $ads-primary-blue !important;
    background: $ads-primary-teal !important;
    background-color: $ads-primary-teal !important;
    color: $ads-primary-blue !important;
  }
  .v-chip.v-chip--outlined.error,
  .v-chip.v-chip--outlined.v-chip.v-chip.error {
    border-color: $ads-secondary-red-dark !important;
    background: rgba(255, 184, 193, 0.3) !important;
    background-color: rgba(255, 184, 193, 0.3) !important;
    color: $ads-secondary-red-dark !important;
    .v-chip__close {
      color: $ads-secondary-red-dark !important;
    }
  }
}
</style>
