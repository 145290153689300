<template>
  <div class="py-6 grey lighten-6">
    <Alert
      class="pb-6 round-alert"
      v-if="showUpcomingRoundsAlert"
      type="warning"
      allow-dismiss
      :text="`The current round finishes on ${formattedCurrentRoundEndDate}`"
      subtext="Please action all EOIs for this round before that date."
      in-page
      data-testid="rounds-warning"
    >
      <template slot="close">
        <v-btn
          @click="closeRoundsAlert"
          icon
          class="rounds-dismiss-button"
          aria-label="dismiss"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </Alert>
    <Alert
      v-if="unlinkedCoreLocalApplications"
      @click="openPendingLocalApplications()"
      class="pb-3 local-alert"
      type="warning"
      :html-text="`<p class='heading'>Year 7 local application</p>
      <p class='subtext'>You have <a href='#' class='link'>Year 7 local application(s)</a> to process. The tracking sheet will update once SRNs are linked.</p>`"
      in-page
      data-testid="local-warning"
    />
    <Alert
      v-if="unlinkedCoreOoaApplications"
      @click="openPendingOoaApplications()"
      class="pb-3 local-alert"
      type="warning"
      :html-text="`<p class='heading'>Year 7 Out-of-area application</p>
       <p class='subtext'>You have <a href='#' class='link'>Year 7 Out-of-area application(s)</a> to process. The tracking sheet will update once SRNs are linked.</p>`"
      in-page
      data-testid="ooa-warning"
    />
    <Alert
      v-if="duplicateLocalOoaAppsForSRN.hasDuplicates"
      class="pb-3 local-alert"
      type="warning"
      :html-text="`<p class='duplicate'>Duplicate applications exist for ${duplicateLocalOoaAppsForSRN.duplicateNames}. Please ensure that only one offer is made.</p>`"
      in-page
      data-testid="duplication-warning"
    />
    <!-- Y67T Filters -->
    <v-row class="py-3">
      <v-col class="pt-0">
        <span class="filterLabel">Filter by:</span>
        <span class="pl-3">
          <ListViewFilter
            ref="eoiTypeFilter"
            label="EOI type"
            type="checkbox"
            :filter-options="eoiTypeFilters"
            @updatefilter="updateEoiTypeFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span class="pl-3">
          <ListViewFilter
            ref="statusFilter"
            label="Status"
            type="checkbox"
            :filter-options="statusFilters"
            @updatefilter="updateStatusFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span class="pl-3">
          <ListViewFilter
            ref="flagsTagsFilter"
            class="flags-tags-filter"
            label="Flags &amp; tags"
            type="checkbox"
            comment="<strong>Only show</strong> students meeting <strong>all</strong> selected criteria below"
            width="590px"
            column-wrapper="column_wrapper"
            :filter-options="flagsTagsFilters"
            @updatefilter="updateFlagsTagsFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span class="pl-3">
          <YearDateRangeFilter
            ref="receivedDateFilter"
            label="Received date"
            :archive-type="archiveType"
            :year-options="yearOptions"
            :filter-options="receivedDateFilter"
            :show-default-filter="selectedReceivedYear"
            @updateYearFilter="updateYearFilter"
            @updatefilter="updateReceivedDateFilter"
            @menuOpened="sendFilterAnalytics"
            selected-application="Y67T_SECONDARY"
          />
        </span>
        <ClearFilters @click="clearAllFilters()" v-if="isFiltered" />
      </v-col>
      <v-col cols="auto" class="offerBtn">
        <AdsButton
          ref="bulkOffer"
          button-text="Offer"
          :disabled="activeCount === 0"
          icon="school"
          data-testid="y67tBulkOffer"
          @click="openBulkOfferModal()"
        />
      </v-col>
      <v-col cols="auto">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <AdsButton
              ref="moreActions"
              secondary
              v-bind="attrs"
              v-on="on"
              button-text="More actions"
              icon="more_vert"
              data-testid="y67tmoreActions"
            />
          </template>
          <v-list>
            <v-list-item
              v-for="(action, index) in actions"
              :key="index"
              @click="action.go"
              :disabled="action.disabled"
            >
              <v-list-item-icon>
                <v-icon :disabled="action.disabled">{{ action.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ action.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-card>
      <AdsDataTable
        :headers="headers"
        :items="applications"
        search-label="Find student"
        :search="internalSearch"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{
          'items-per-page-options': [10, 15, 50, -1]
        }"
        @click:row="openApplication"
        @current-items="setFilteredIds"
        show-expand
        :expanded="expandAll"
        item-key="eoiID"
        :custom-filter="filterApplications"
        @update:page="updatePagination"
        @pagination="totalResults"
        :page.sync="currentPage"
        :sort-by.sync="sortByColumn"
        :sort-desc.sync="sortByDesc"
        must-sort
        data-testid="y67tSecondaryEoiList"
      >
        <template #no-data>
          <NoResultsFound
            :archive-type="archiveType"
            :filtered="isFiltered || Boolean(internalSearch)"
          />
        </template>
        <template #[`header.data-table-expand`]="">
          <span tabindex="-1"></span>
        </template>
        <template #top>
          <v-row class="row-line">
            <v-col>
              <v-text-field
                class="searchInput"
                v-model="internalSearch"
                prepend-inner-icon="mdi-magnify"
                label="Find student"
                single-line
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="auto" class="results-text">
              <span>
                {{ resultsText(pagination.itemsLength) }}
                <a
                  tabindex="0"
                  class="select-all"
                  v-if="selectCheckCount"
                  @keyup.enter="selectEveryIdToggle(selectedItemsCount)"
                  @click="selectEveryIdToggle(selectedItemsCount)"
                >
                  {{
                    selectedItemsCount === pagination.itemsLength
                      ? 'Clear selection'
                      : `Select all ${pagination.itemsLength}`
                  }}
                </a>
              </span>
            </v-col>
            <v-col cols="auto" class="text-center">
              <AdsButton
                v-if="toggleExpandCollapse && enableButton"
                class="collapse-all-button"
                tertiary
                icon="unfold_less"
                button-text="Collapse all"
                @click="expandCollapse('collapse')"
              />
              <AdsButton
                v-else
                class="collapse-all-button"
                :disabled="!enableButton"
                tertiary
                icon="unfold_more"
                button-text="Expand all"
                @click="expandCollapse('expand')"
              />
            </v-col>
          </v-row>
        </template>
        <template #[`header.selectAll`]>
          <v-checkbox
            :value="allSelected"
            hide-details
            aria-label="Add all applications to PDF download."
            class="my-0 mx-3 pa-0"
            @click.prevent="toggleSelectAll"
            data-testid="y67tToggleSelectAll"
          />
        </template>
        <template #[`item.selectAll`]="{ item }">
          <OesCheckbox
            v-model="selectedIds[item.eoiID]"
            hide-details
            :aria-label="`Add application for ${item.name} to PDF download.`"
            class="ma-0 pa-0"
            @click.stop
            data-testid="y67tSelectApplicationCheckbox"
          />
        </template>
        <template #[`item.psDateSentToERN`]="{ item }">
          {{ item.psDateSentToERN | moment('DD MMM YYYY') }}
        </template>
        <template #[`item.SRN`]="{ item }">
          {{ item.student.SRN ? item.student.SRN : '--' }}
        </template>
        <template #[`item.name`]="{ item }">
          <a
            class="y7-item-name d-inline-flex flex-wrap align-center focus-ring"
            v-if="getOpenApplicationHref(item)"
            :href="`/#/${getOpenApplicationHref(item)}`"
            :aria-label="`${item.name}. Open application details.`"
            :ref="`itemfocusRef${item.index}`"
          >
            {{ item.name }}
            <img
              v-if="item.flagsTags.includes('TMP')"
              :src="require(`@/assets/flags/tmp.svg`)"
              alt="Temporary resident"
            />
          </a>
          <span v-else>{{ item.name }}</span>
        </template>
        <template #[`item.localHighSchool`]="{ item }">
          <div class="text-wrap-inline">
            <v-icon :class="statusBasedIconClass(item.localHighSchool)">{{
              statusBasedIcon(item.localHighSchool)
            }}</v-icon>
            <div
              :title="fullSchoolName(item.localHighSchool)"
              class="column-text"
              :class="sameSchoolClass(item.localHighSchool)"
            >
              {{
                trackingBreakpoint
                  ? shortSchoolName(item.localHighSchool)
                  : abbreviateSchoolName(item.localHighSchool)
              }}
            </div>
          </div>
        </template>
        <template #[`item.round1`]="{ item }">
          <div class="text-wrap-inline">
            <v-icon :class="statusBasedIconClass(item.round1)">{{
              statusBasedIcon(item.round1)
            }}</v-icon>
            <div
              :title="fullSchoolName(item.round1)"
              class="column-text"
              :class="sameSchoolClass(item.round1)"
            >
              {{
                trackingBreakpoint
                  ? shortSchoolName(item.round1)
                  : abbreviateSchoolName(item.round1)
              }}
            </div>
          </div>
        </template>
        <template #[`item.round2`]="{ item }">
          <div class="text-wrap-inline">
            <v-icon :class="statusBasedIconClass(item.round2)">{{
              statusBasedIcon(item.round2)
            }}</v-icon>
            <div
              :title="fullSchoolName(item.round2)"
              class="column-text"
              :class="sameSchoolClass(item.round2)"
            >
              {{
                trackingBreakpoint
                  ? shortSchoolName(item.round2)
                  : abbreviateSchoolName(item.round2)
              }}
            </div>
          </div>
        </template>
        <template #[`item.round3`]="{ item }">
          <div class="text-wrap-inline">
            <v-icon :class="statusBasedIconClass(item.round3)">{{
              statusBasedIcon(item.round3)
            }}</v-icon>
            <div
              :title="fullSchoolName(item.round3)"
              class="column-text"
              :class="sameSchoolClass(item.round3)"
            >
              {{
                trackingBreakpoint
                  ? shortSchoolName(item.round3)
                  : abbreviateSchoolName(item.round3)
              }}
            </div>
          </div>
        </template>
        <template #[`item.walkupsMain`]="{ item }">
          <div class="text-wrap-inline">
            <v-icon :class="statusBasedIconClass(item.walkupsMain)">{{
              statusBasedIcon(item.walkupsMain)
            }}</v-icon>
            <div
              :title="fullSchoolName(item.walkupsMain)"
              class="column-text"
              :class="sameSchoolClass(item.walkupsMain)"
            >
              {{
                trackingBreakpoint
                  ? shortSchoolName(item.walkupsMain)
                  : abbreviateSchoolName(item.walkupsMain)
              }}
            </div>
          </div>
        </template>
        <template #[`item.applicationCustomStatus`]="{ item }">
          <div class="d-flex justify-center">
            <Chip
              small
              v-bind="getStatusChipDetails(item.applicationCustomStatus)"
              class="ApplicationStatusChips"
            />
          </div>
        </template>
        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <div
            v-if="
              (item.flagsTags ? item.flagsTags.length > 0 : 0) ||
              item.walkups.length > 1 ||
              item.timelapsed
            "
          >
            <button
              @click.stop="expand(!isExpanded), singleExpand(item)"
              :aria-label="[
                isExpanded ? 'Click to Collapse' : 'Click to Expand'
              ]"
              type="button"
              class="v-icon v-data-table__expand-icon v-icon--link mdi"
              :class="[isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down']"
            />
          </div>
        </template>
        <template #expanded-item="{ item }">
          <td colspan="2">&nbsp;</td>
          <td colspan="2">
            <ul class="flags-list">
              <li
                class="flags-list-item"
                v-for="(flagTag, index) in item.flagsTags"
                :key="index"
              >
                <div v-if="getFlagTagDescription(flagTag)">
                  <div class="float-left">
                    <img
                      :src="
                        require(`@/assets/flags/${flagTag.toLowerCase()}.svg`)
                      "
                      :alt="`${flagTag}`"
                    />
                  </div>
                  <div
                    class="float-left flag-description"
                    v-html="getFlagTagDescription(flagTag)"
                  ></div>
                </div>
              </li>
            </ul>
          </td>
          <td :class="currentRoundClass(1)">&nbsp;</td>
          <td :class="currentRoundClass(2)">&nbsp;</td>
          <td :class="currentRoundClass(3)">&nbsp;</td>
          <td colspan="1">
            <ul class="walkups-list" v-if="item.walkupsAll.length > 1">
              <li
                class="walkup-list-item"
                v-for="(walkup, index) in item.walkupsAll"
                :key="index"
              >
                <div>
                  <div class="float-left">
                    <v-icon :class="statusBasedIconClass(walkup)">{{
                      statusBasedIcon(walkup)
                    }}</v-icon>
                  </div>
                  <div
                    :title="fullSchoolName(walkup)"
                    class="float-left walkup-description"
                    :class="sameSchoolClass(walkup)"
                    v-html="shortSchoolName(walkup)"
                  ></div>
                </div>
              </li>
            </ul>
          </td>
          <td colspan="1" class="timelapse-cell">
            <div v-if="item.timelapsed" class="timelapse-contain">
              <v-icon color="#002664">mdi-timelapse</v-icon>
              <p class="timelapse-text">{{ item.timelapsedDays }}</p>
            </div>
          </td>
        </template>
      </AdsDataTable>
    </v-card>
    <Y67TBulkOfferModal
      v-if="showBulkOfferModal"
      :show-bulk-offer-modal="showBulkOfferModal"
      :applications="selectedIdsForBulkOffer"
      @close="closeBulkOfferModal"
      @refresh="refreshList"
    />
    <Y67TDownloadDocuments
      v-if="showDownloadModal"
      :applications="selectedIdsForDownload"
      :school-code="schoolCode"
      :application-type="APPLICATION_TYPE.Y67T"
      @close="closeDownloadModal"
    />
    <ExportListAsCsv
      v-show="showExportModal"
      :applications="selectedIdsForExport"
      :file-name="csvFileName"
      @close="closeExportModal"
    />
    <LegendModal
      v-model="showLegendModal"
      @close="closeLegendModal"
      :dialog-width="400"
    />
  </div>
</template>
<script>
import LegendModal from '@/components/application/y67t/legendModal.vue'
import Y67TBulkOfferModal from '@/components/applicationList/Y67TBulkOfferModal.vue'
import Y67TDownloadDocuments from '@/components/applicationList/Y67TDownloadDocuments.vue'
import ExportListAsCsv from '@/components/applicationList/ExportListAsCsv.vue'
import ListViewFilter from '@/components/app/ListViewFilter.vue'
import YearDateRangeFilter from '@/components/app/YearDateRangeFilter.vue'
import NoResultsFound from '@/components/app/NoResultsFound.vue'
import ClearFilters from './ClearFilters.vue'
import { mapGetters } from 'vuex'
import { searchFilter, searchFilterForStringOnly } from '@/helpers/searchHelper'
import moment from 'moment'
import _ from 'lodash'
import OesCheckbox from '@/components/app/OesCheckbox'
import { Alert, Chip, AdsButton, AdsDataTable } from '@nswdoe/doe-ui-core'
import {
  APPLICATION_TYPE,
  STREAM,
  Y67T_STATUS,
  Y67T_STATUS_SORTING_ORDER,
  Y67T_STATUS_CHIPS,
  Y67T_STATUS_ICONS,
  Y67T_FLAGS_TAGS,
  USER_GROUP,
  ARCHIVE_TYPES
} from '@/constants'
import _cloneDeep from 'lodash/cloneDeep'

const Y67T_ROUNDS_ACK_SESSION_KEY = 'si.y67tRoundsAck'
const currentYear = new Date().getFullYear().toString()
const currentDate = moment().format('DD_MMM_YYYY')
const NO_RECEIVED_DATE = 'No received date'

export default {
  name: 'Y67TSecondaryEoiList',
  components: {
    Y67TBulkOfferModal,
    Y67TDownloadDocuments,
    ExportListAsCsv,
    Chip,
    ListViewFilter,
    YearDateRangeFilter,
    ClearFilters,
    OesCheckbox,
    AdsButton,
    AdsDataTable,
    Alert,
    LegendModal,
    NoResultsFound
  },
  data() {
    return {
      expandAll: [],
      expandOrCollapse: 'collapse',
      enableButton: true,
      selectedIds: {},
      toggleExpandCollapse: false,
      showBulkOfferModal: false,
      showDownloadModal: false,
      showExportModal: false,
      showLegendModal: false,
      selectedStatuses: [],
      selectedEoiTypes: [],
      selectedFlagsTags: [],
      selectedReceivedYear: [currentYear],
      selectedReceivedDateRange: [],
      selectedNonDirectApplications: [],
      selectedItemsCount: 0,
      selectAllToggle: false,
      APPLICATION_TYPE,
      sessionRoundsWarningAcknowledged: sessionStorage.getItem(
        Y67T_ROUNDS_ACK_SESSION_KEY
      ),
      currentPage: 1,
      sortByColumn: 'applicationCustomStatus',
      sortByDesc: false,
      selectCheckCount: false,
      idPerPage: [],
      filteredIds: 0,
      pagination: { itemsLength: 0, itemsPerPage: 50 },
      tabName: 'SECONDARYEOILIST',
      archiveType: ARCHIVE_TYPES.Y67T_EOIS.type
    }
  },
  activated() {
    // refresh the list in case the status changed in the details window
    this.$store.dispatch('getY67TApplications')
  },
  created() {
    // load the list on first load
    this.$store.dispatch('getY67TApplications')
    this.$store.dispatch('getPilotSchools')
  },
  watch: {
    schoolCode() {
      // clear selected applications for download pdf on change of school switcher
      this.selectedIds = {}
      this.selectAllToggle = false
      // clear selected filters on change of school switcher
      this.selectedStatuses = []
      this.selectedEoiTypes = []
      this.selectedFlagsTags = []
      this.expandAll = []
      this.expandOrCollapse = ''
      this.selectedReceivedYear = [currentYear]
      this.selectedReceivedDateRange = []
      this.$store.commit('setY67tSearchFilter', { tabName: 'RESET' })
      this.$store.dispatch('getY67TApplications')
    },
    selectedItemsCount() {
      const checkedOnes = _.pickBy(this.selectedIds, (value) => {
        return value
      })

      this.selectedNonDirectApplications = Object.keys(checkedOnes).filter(
        (i) => {
          return this.nonDirectApplicationIds.includes(i)
        }
      )
      const currentPageIds = this.visibleEoiIds.reduce((selected, appId) => {
        selected[appId] = true
        return selected
      }, {})

      this.filteredIds = Object.keys(checkedOnes).filter((i) => {
        return Object.keys(currentPageIds).includes(i)
      })

      this.idPerPage[this.currentPage - 1] =
        this.filteredIds.length === this.pagination.itemsPerPage ||
        this.filteredIds.length === this.pagination.itemsLength ||
        this.filteredIds.length ===
          this.pagination.pageStop - this.pagination.pageStart
          ? true
          : false

      this.selectCheckCount = this.idPerPage.includes(true)
    }
  },
  methods: {
    refreshList() {
      this.selectedIds = {}
      this.$store.dispatch('getY67TApplications')
    },
    totalResults(pagination) {
      this.pagination = pagination
    },
    resultsText(itemsLength) {
      this.selectedItemsCount = Object.values(this.selectedIds).filter(
        (value) => value === true
      ).length
      return this.selectCheckCount
        ? this.selectedItemsCount + ' of ' + itemsLength + ' selected.'
        : itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    },
    expandCollapse(type) {
      this.expandOrCollapse = type
      if (this.expandOrCollapse == 'expand') {
        this.getExpandCollapseApps()
        this.toggleExpandCollapse = true
      } else {
        this.expandAll = []
        this.toggleExpandCollapse = false
      }
    },
    getExpandCollapseApps() {
      this.expandAll = this.applications.filter((a) => {
        return (
          a.application.walkups?.length > 1 ||
          a.flagsTags?.length > 0 ||
          (a.applicationStatus === Y67T_STATUS.OFFERED && a.offeredDateTime)
        )
      })
    },
    singleExpand(item) {
      const indexExpandAll = this.expandAll.findIndex(
        (i) => i.eoiID === item.eoiID
      )
      if (indexExpandAll > -1) {
        this.expandAll.splice(indexExpandAll, 1)
      } else {
        this.expandAll.push(item)
      }
    },
    updatePagination() {
      this.$nextTick(() => {
        document.querySelector('tr td:first-child .v-input input').focus()
      })
    },
    getOpenApplicationHref(item) {
      if ([-1, -2].includes(item.preferenceNo)) {
        if (item.directLocalAppWithHref.length > 0) {
          return `application/${item.schoolCode}/${item.directLocalAppWithHref[0].applicationId}`
        } else {
          return false
        }
      }
      return `application/${item.schoolCode}/${item.eoiID}`
    },
    openApplication(item) {
      if (this.getOpenApplicationHref(item)) {
        this.$router.push(`/${this.getOpenApplicationHref(item)}`)
      }
    },
    setFilteredIds(items) {
      const ids = items.map((item) => item.eoiID)
      const flags = items.filter((a) => {
        return (
          a.walkups?.length > 1 ||
          a.flagsTags?.length > 0 ||
          (a.applicationStatus === Y67T_STATUS.OFFERED && a.offeredDateTime)
        )
      }).length

      flags > 0 ? (this.enableButton = true) : (this.enableButton = false)
      this.$store.dispatch('set', ['filteredApplicationIds', ids])
    },
    getStatusChipDetails(applicationStatus) {
      return Y67T_STATUS_CHIPS[applicationStatus.split('|')[3]]
    },
    toggleSelectAll() {
      if (!this.allSelected) {
        this.selectAllToggle = true
        const oldIds = this.selectedIds
        const newIds = this.visibleEoiIds.reduce((selected, appId) => {
          selected[appId] = true
          return selected
        }, {})
        this.selectedIds = { ...oldIds, ...newIds }
      } else {
        this.selectAllToggle = false
        const removeIds = this.visibleEoiIds.reduce((selected, appId) => {
          selected[appId] = true
          return selected
        }, {})
        let oldIds = this.selectedIds
        let arrayRem = Object.keys(removeIds)
        this.selectedIds = Object.keys(oldIds)
          .filter((key) => !arrayRem.includes(key))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: oldIds[key]
            })
          }, {})
      }
    },
    selectEveryIdToggle(item) {
      let sortByDesc = this.sortByDesc ? 'desc' : 'asc'
      const applications = _.orderBy(
        this.applications,
        [(application) => application[this.sortByColumn].toLowerCase()],
        [sortByDesc]
      )
      const ids = applications.map((item) => item.eoiID)
      if (item !== this.pagination.itemsLength || !this.allSelected) {
        this.idPerPage = Array(this.pagination.pageCount).fill(true)
        this.selectedIds = ids.reduce((selected, appId) => {
          selected[appId] = true
          return selected
        }, {})
      } else {
        this.idPerPage = []
        this.selectAllToggle = false
        this.selectedIds = {}
      }
    },
    openDownloadModal() {
      this.showDownloadModal = true
      this.$gtm.trackEvent({
        category: 'pdfApplication',
        action: 'Download',
        label: 'Secondary list view',
        value: Object.keys(this.selectedIds).length,
        stream: 'Y67T'
      })
    },
    closeExportModal() {
      this.showExportModal = false
      requestAnimationFrame(() => {
        this.$refs.moreActions.$el.focus()
      })
    },
    openExportModal() {
      this.showExportModal = true
      this.$gtm.trackEvent({
        category: 'csvApplication',
        action: 'Export',
        label: 'Secondary list view',
        value: Object.keys(this.selectedIds).length,
        stream: 'Y67T'
      })
    },
    closeLegendModal() {
      this.showLegendModal = false
      requestAnimationFrame(() => {
        this.$refs.moreActions.$el.focus()
      })
    },
    openLegendModal() {
      this.showLegendModal = true
    },
    openBulkOfferModal() {
      this.showBulkOfferModal = true
    },
    closeDownloadModal() {
      this.showDownloadModal = false
      requestAnimationFrame(() => {
        this.$refs.moreActions.$el.focus()
      })
    },
    closeBulkOfferModal() {
      this.showBulkOfferModal = false
      requestAnimationFrame(() => {
        this.$refs.bulkOffer.$el.focus()
      })
    },
    openPendingLocalApplications() {
      this.$store.commit('set', ['currentStream', STREAM.LOCAL_AREA])
      this.$store.commit('set', ['redirectFromY67TToLocalPending', true])
    },
    openPendingOoaApplications() {
      this.$store.commit('set', ['currentStream', STREAM.LOCAL_AREA])
      this.$store.commit('set', ['redirectFromY67TToOoaPending', true])
    },
    // clear selected applications for download pdf on change of status filter
    updateStatusFilter(selectedOptions) {
      this.selectedStatuses = selectedOptions
      this.selectedIds = {}
      this.selectAllToggle = false
      this.expandCollapse('collapse')
      this.currentPage = 1
    },
    updateEoiTypeFilter(selectedOptions) {
      this.selectedEoiTypes = selectedOptions
      this.selectedIds = {}
      this.selectAllToggle = false
      this.expandCollapse('collapse')
      this.currentPage = 1
    },
    updateFlagsTagsFilter(selectedOptions) {
      this.selectedFlagsTags = selectedOptions
      this.selectedIds = {}
      this.selectAllToggle = false
      this.expandCollapse('collapse')
      this.currentPage = 1
    },
    updateReceivedDateFilter(dateRange) {
      if (dateRange !== null && dateRange.length > 0) {
        this.selectedReceivedDateRange = dateRange
        this.expandCollapse('collapse')
        this.currentPage = 1
      } else {
        this.selectedReceivedDateRange = []
      }
      this.selectedIds = {}
      this.selectAllToggle = false
    },
    matchesSearchFilter(application) {
      return (
        !this.y67tSearchFilter(this.tabName) ||
        searchFilter(
          [
            application.application.student.familyName,
            application.application.student.firstName
          ],
          this.y67tSearchFilter(this.tabName)
        )
      )
    },
    matchesStatusFilter(application) {
      return (
        !this.selectedStatuses.length ||
        this.selectedStatuses.includes(application.applicationStatus)
      )
    },
    matchesEoiTypeFilter(application) {
      return (
        this.selectedEoiTypes.length === 0 ||
        this.selectedEoiTypes.includes(application.eoiType) ||
        (this.selectedEoiTypes.includes('Walk ups') &&
          application.walkups.find((w) => w.schoolName === this.selectedSchool))
      )
    },
    matchesFlagsTagsFilter(application) {
      const flagTagsTypes = []
      //healthConditionChecked is for Health condition checkbox or not
      let healthConditionChecked = false
      if (this.selectedFlagsTags.length > 0) {
        this.selectedFlagsTags.forEach((flagTagDesc) => {
          flagTagDesc === 'Health condition'
            ? (healthConditionChecked = true)
            : flagTagsTypes.push(this.getFlagTagType(flagTagDesc))
        })
      }
      //To find any health condition for application
      const healthConditionsArray = [
        'ORANGE',
        'ORANGE_STOP',
        'ORANGE_EXCLAMATION_MARK'
      ]
      const matchedhealthConditionsApp = healthConditionsArray.filter(
        (element) => application.flagsTags.includes(element)
      )
      //Add new value for Health condition
      //healthConditionCheckedResult is for checking each application has atleast one Health condition if Health condition checkbox checked
      let healthConditionCheckedResult = false
      if (
        (healthConditionChecked == true &&
          matchedhealthConditionsApp.length > 0) ||
        healthConditionChecked == false
      ) {
        healthConditionCheckedResult = true
      }
      const matchedflagTags = flagTagsTypes.filter((element) =>
        application.flagsTags.includes(element)
      )
      return (
        (!flagTagsTypes.length && !healthConditionChecked) ||
        (flagTagsTypes.length == matchedflagTags.length &&
          healthConditionCheckedResult)
      )
    },
    matchesReceivedYearFilter(application) {
      return (
        !this.selectedReceivedYear.length ||
        this.selectedReceivedYear.includes(
          this.getYearDate(application?.psDateSentToERN)
        )
      )
    },
    matchesReceivedDateRangeFilter(application) {
      return (
        !this.selectedReceivedDateRange.length ||
        this.checkReceivedDateInsideRange(application.psDateSentToERN)
      )
    },
    checkReceivedDateInsideRange(dateReceived) {
      const start = this.$moment(this.selectedReceivedDateRange[0])
      const end = this.$moment(this.selectedReceivedDateRange[1])
      const receivedDate = this.$moment(dateReceived)
      return start <= receivedDate && receivedDate <= end
    },
    filterApplications(value, search, item) {
      this.currentPage = 1
      return searchFilterForStringOnly(value, search, item)
    },
    sendFilterAnalytics(opened) {
      // send analytics data when each filter is closed
      if (!opened) {
        const statusData = this.selectedStatuses.length
          ? JSON.stringify(this.sortStatuses(this.selectedStatuses))
          : null
        const consolidatedData = `${statusData}`.replaceAll('"', '')
        this.$gtm.trackEvent({
          category: 'applicationFilter',
          action: STREAM.Y67T_SECONDARY,
          label: consolidatedData
        })
      }
    },
    sortStatuses(statuses) {
      const sortByStatus = Object.values(Y67T_STATUS)
      return statuses.sort(function (a, b) {
        return sortByStatus.indexOf(a) - sortByStatus.indexOf(b)
      })
    },
    updateYearFilter(selectedOptions) {
      this.selectedReceivedYear = selectedOptions
      this.expandCollapse('collapse')
      this.currentPage = 1
      // clear selected applications for download pdf on change of status filter
      this.selectedIds = {}
      this.selectAllToggle = false
    },
    clearAllFilters() {
      // clear all filtered values
      this.selectAllToggle = false
      this.$store.commit('setY67tSearchFilter', {
        tabName: this.tabName,
        value: ''
      })
      this.$refs.eoiTypeFilter.clearFilter()
      this.$refs.statusFilter.clearFilter()
      this.$refs.flagsTagsFilter.clearFilter()
      this.$refs.receivedDateFilter.clearFilter()
      this.expandCollapse(this.expandOrCollapse)
    },
    sameSchoolClass(round) {
      const statusClasses = []
      if (round.split('|')[0]) {
        round.split('|')[0] === this.selectedSchool
          ? statusClasses.push('same-school')
          : statusClasses.push('different-school')
      }
      if (round.split('|')[1]) {
        round.split('|')[1]
          ? statusClasses.push(
              'status-' + round.split('|')[1].replace(' ', '-').toLowerCase()
            )
          : ''
      }
      return statusClasses.join(' ')
    },
    statusBasedIcon(round) {
      return round ? Y67T_STATUS_ICONS[round.split('|')[1]] : ''
    },
    statusBasedIconClass(round) {
      const statusClasses = []
      if (round.split('|')[0]) {
        round.split('|')[0] === this.selectedSchool
          ? statusClasses.push('same-school')
          : statusClasses.push('different-school')
      }
      if (round) {
        round.split('|')[1]
          ? statusClasses.push(
              'status-icon-' +
                round.split('|')[1].replace(' ', '-').toLowerCase()
            )
          : ''
      }
      return statusClasses.join(' ')
    },
    shortSchoolName(round) {
      let schoolName = round.split('|')[0]
      if (schoolName.length > 30) {
        if (schoolName.includes('Secondary College')) {
          return (
            schoolName.replace('Secondary College', 'SC').substring(0, 30) +
            '...'
          )
        }
        if (schoolName.includes('High School')) {
          return (
            schoolName.replace('High School', 'HS').substring(0, 30) + '...'
          )
        }
        return schoolName.substring(0, 30) + '...'
      } else {
        return this.abbreviateSchoolName(round)
      }
    },
    fullSchoolName(round) {
      return round ? round.split('|')[0] : ''
    },
    abbreviateSchoolName(round) {
      let schoolName = round.split('|')[0]
      if (schoolName.includes('Secondary College')) {
        return schoolName.replace('Secondary College', 'SC')
      }
      if (schoolName.includes('High School')) {
        return schoolName.replace('High School', 'HS')
      }
      return schoolName || ''
    },
    getFlagTagDescription(type) {
      if (Y67T_FLAGS_TAGS.flags[type]) {
        return Y67T_FLAGS_TAGS.flags[type]
      } else if (Y67T_FLAGS_TAGS.tags[type]) {
        if (type == 'SIB') {
          return this.siblingsTag.description
        }
        return Y67T_FLAGS_TAGS.tags[type]
      } else {
        return false
      }
    },
    getFlagTagType(description) {
      const flags = Y67T_FLAGS_TAGS.flags
      const tags = {
        ...Y67T_FLAGS_TAGS.tags,
        SIB: this.siblingsTag.description
      }
      return Object.keys(flags).filter(function (key) {
        return flags[key] === description
      })[0]
        ? Object.keys(flags).filter(function (key) {
            return flags[key] === description
          })[0]
        : Object.keys(tags).filter(function (key) {
            return tags[key] === description
          })[0]
    },
    closeRoundsAlert() {
      this.roundsWarningAcknowledged = true
    },
    currentRoundClass(roundNo) {
      return this.getCurrentRound
        ? roundNo === this.getCurrentRound
          ? 'current-round'
          : ''
        : ''
    },
    hasAnySiblings(ooaHighSchools) {
      if (!ooaHighSchools) {
        return false
      }
      const selectedOoaHighSchool = ooaHighSchools?.filter(
        (ooaHighSchool) =>
          ooaHighSchool?.schoolCode === this.schoolCode &&
          ooaHighSchool?.hasAnySiblings &&
          ooaHighSchool?.siblings?.length > 0
      )
      return selectedOoaHighSchool && selectedOoaHighSchool.length > 0
    },
    getYearDate(dateValue) {
      return dateValue && new Date(dateValue).getFullYear()
        ? new Date(dateValue).getFullYear().toString()
        : NO_RECEIVED_DATE
    }
  },
  computed: {
    ...mapGetters({
      schoolCode: 'selectedSchoolCode',
      y67tApplications: 'y67tApplications',
      y67tSearchFilter: 'y67tSearchFilter',
      y67tRoundDates: 'y67tRoundDates',
      filteredApplicationIds: 'filteredApplicationIds'
    }),
    unlinkedCoreLocalApplications() {
      return this.y67tApplications(this.schoolCode)
        ?.unlinkedCoreLocalApplications
    },
    unlinkedCoreOoaApplications() {
      return this.y67tApplications(this.schoolCode)?.unlinkedCoreOOAApplications
    },
    duplicateLocalOoaAppsForSRN() {
      const duplicates =
        this.y67tApplications(this.schoolCode)?.duplicateLocalOoaAppsForSRN ||
        []

      const duplicateNames = duplicates
        .map(({ firstName, familyName }) => `<b>${firstName} ${familyName}</b>`)
        .join(', ')
        .replace(/,(?!.*,)/gim, ' and')

      return {
        hasDuplicates: Boolean(duplicates.length),
        duplicateNames
      }
    },
    nonDirectApplicationIds() {
      return this.applications
        .map((item) => {
          if (!item.noDownload) {
            return item.eoiID
          } else {
            return ''
          }
        })
        .filter((i) => i !== '')
    },
    actions() {
      let items = [
        {
          title: 'View legend',
          icon: 'mdi-eye-outline',
          go: this.openLegendModal
        }
      ]
      if (this.isERNAdmin) {
        items = [
          {
            title: 'Download EOI(s)/PNR(s)',
            icon: 'mdi-download-outline',
            go: this.openDownloadModal,
            disabled: this.selectedNonDirectApplications.length === 0
          },
          {
            title: 'Export list as CSV',
            icon: 'list_alt',
            go: this.openExportModal,
            disabled: this.selectedItemsCount === 0
          },
          ...items
        ]
      }
      return items
    },
    selectIdsArray() {
      return Object.entries(this.selectedIds)
        .filter((item) => item[1] === true)
        .map((item) => item[0])
    },
    activeCount() {
      let activeIds = this.applications
        .filter(
          (item) =>
            item.applicationStatus === 'Active' && !item.activePaperQuickERN
        )
        .map((item) => item.eoiID)

      return this.selectIdsArray.filter((item) => activeIds?.includes(item))
        .length
    },
    trackingBreakpoint() {
      return this.$vuetify.breakpoint.width <= 1712
    },
    isOesSupport() {
      return this.$store.state.userGroup === USER_GROUP.OES_SUPPORT
    },
    isERNAdmin() {
      return this.$store.state.userGroup === USER_GROUP.ERN_ADMIN
    },
    internalSearch: {
      get() {
        return this.y67tSearchFilter(this.tabName) || ''
      },
      set(value) {
        this.selectedIds = {}
        this.idPerPage = []
        this.$store.commit('setY67tSearchFilter', {
          tabName: this.tabName,
          value: value
        })
      }
    },
    isFiltered() {
      return (
        Boolean(this.selectedEoiTypes.length) ||
        Boolean(this.selectedStatuses.length) ||
        Boolean(this.selectedFlagsTags.length) ||
        Boolean(this.selectedReceivedDateRange.length) ||
        Boolean(this.selectedReceivedYear.length)
      )
    },
    selectedSchool() {
      return this.$store.state.selectedSchool.schoolName
    },
    siblingsTag() {
      return {
        type: 'SIB',
        description: `Sibling at ${this.abbreviateSchoolName(
          this.selectedSchool
        )}`
      }
    },
    visibleEoiIds() {
      return this.filteredApplicationIds
    },
    allSelected: {
      get() {
        const allIds = [...this.visibleEoiIds]
        return (
          Object.keys(this.selectedIds).length &&
          allIds.every((id) => this.selectedIds[id] === true)
        )
      },
      set() {}
    },
    selectedIdsForDownload() {
      const keys = Object.keys(this.selectedIds)
      let selectedIds = []
      for (let k of keys) {
        if (this.selectedIds[k] === true) {
          const app = this.applications.filter((a) => a.eoiID === k)
          if (app.length > 0) {
            selectedIds.push({
              applicationID: app[0].eoiID,
              studentFirstName: app[0].student.firstName,
              studentFamilyName: app[0].student.familyName,
              supportingDocuments: app[0].supportingDocuments,
              isDigitizeEOIConsiderationDocUploaded:
                app[0].isDigitizeEOIConsiderationDocUploaded
            })
          }
        }
      }
      return selectedIds
    },
    selectedIdsForBulkOffer() {
      const keys = Object.keys(this.selectedIds)
      let selectedIds = []
      for (let k of keys) {
        if (this.selectedIds[k] === true) {
          const app = this.applications.filter(
            (a) =>
              a.eoiID === k &&
              a.applicationStatus === Y67T_STATUS.ACTIVE &&
              !a.activePaperQuickERN
          )
          if (app.length > 0) {
            selectedIds.push({
              applicationID: app[0].eoiID,
              isLocal: app[0].localHighSchool
                ?.split('|')[0]
                .includes(this.selectedSchool)
                ? true
                : false,
              studentName: `${app[0].student.firstName.trim()} ${app[0].student.familyName.trim()}`
            })
          }
        }
      }
      return selectedIds
    },
    selectedIdsForExport() {
      const keys = Object.keys(this.selectedIds)
      let selectedEOIs = []
      for (let k of keys) {
        if (this.selectedIds[k] === true) {
          const app = this.applications.filter((a) => a.eoiID === k)
          if (app.length > 0) {
            //OOA choices for CSV
            let school1 = ''
            let school2 = ''
            let school3 = ''
            if (app[0].ooaHighSchools?.length) {
              let roundTemp1 = app[0].ooaHighSchools.filter(
                (o) => o.preferenceNo == 1
              )
              school1 = roundTemp1.length > 0 ? roundTemp1[0].schoolName : ''
              let roundTemp2 = app[0].ooaHighSchools.filter(
                (o) => o.preferenceNo == 2
              )
              school2 = roundTemp2.length > 0 ? roundTemp2[0].schoolName : ''
              let roundTemp3 = app[0].ooaHighSchools.filter(
                (o) => o.preferenceNo == 3
              )
              school3 = roundTemp3.length > 0 ? roundTemp3[0].schoolName : ''
            }
            //Flags and tags for CSV
            let redFlagTag = ''
            let blueFlagTag = ''
            let yellowFlagTag = ''
            let greenFlagTag = ''
            let pinkFlagTag = ''
            let orangeFlagTag = ''
            let yellowStrikeFlagTag = ''
            let greenStrikeFlagTag = ''
            let greyFlagTag = ''
            let pnrFlagTag = ''
            let pfFlagTag = ''
            let naFlagTag = ''
            let scFlagTag = ''
            let shFlagTag = ''
            let vaFlagTag = ''
            let sibFlagTag = ''
            let tmpFlagTag = ''
            const appFlagsTags = app[0].flagsTags
            if (appFlagsTags && !app[0].noDownload) {
              appFlagsTags.includes('RED')
                ? (redFlagTag = 'Y')
                : (redFlagTag = 'N')
              appFlagsTags.includes('BLUE')
                ? (blueFlagTag = 'Y')
                : (blueFlagTag = 'N')
              appFlagsTags.includes('YELLOW')
                ? (yellowFlagTag = 'Y')
                : (yellowFlagTag = 'N')
              appFlagsTags.includes('GREEN')
                ? (greenFlagTag = 'Y')
                : (greenFlagTag = 'N')
              appFlagsTags.includes('PINK')
                ? (pinkFlagTag = 'Y')
                : (pinkFlagTag = 'N')
              appFlagsTags.filter(
                (f) =>
                  f == 'ORANGE' ||
                  f == 'ORANGE_STOP' ||
                  f == 'ORANGE_EXCLAMATION_MARK'
              ).length
                ? (orangeFlagTag = 'Y')
                : (orangeFlagTag = 'N')
              appFlagsTags.includes('YELLOW_STRIKE_THROUGH')
                ? (yellowStrikeFlagTag = 'Y')
                : (yellowStrikeFlagTag = 'N')
              appFlagsTags.includes('GREEN_STRIKE_THROUGH')
                ? (greenStrikeFlagTag = 'Y')
                : (greenStrikeFlagTag = 'N')
              appFlagsTags.includes('GREY')
                ? (greyFlagTag = 'Y')
                : (greyFlagTag = 'N')
              appFlagsTags.includes('NO')
                ? (pnrFlagTag = 'Y')
                : (pnrFlagTag = 'N')
              appFlagsTags.includes('NA')
                ? (naFlagTag = 'Y')
                : (naFlagTag = 'N')
              appFlagsTags.includes('SC')
                ? (scFlagTag = 'Y')
                : (scFlagTag = 'N')
              appFlagsTags.includes('SH')
                ? (shFlagTag = 'Y')
                : (shFlagTag = 'N')
              appFlagsTags.includes('VA')
                ? (vaFlagTag = 'Y')
                : (vaFlagTag = 'N')
              appFlagsTags.includes('PF')
                ? (pfFlagTag = 'Y')
                : (pfFlagTag = 'N')
              appFlagsTags.includes('SIB')
                ? (sibFlagTag = 'Y')
                : (sibFlagTag = 'N')
              appFlagsTags.includes('TMP')
                ? (tmpFlagTag = 'Y')
                : (tmpFlagTag = 'N')
            }
            //Walkups details for CSV
            let walkupSchools = []
            if (app[0].walkups?.length) {
              walkupSchools = app[0].walkups.map((w) => w.schoolName)
              walkupSchools = walkupSchools.sort().join('; ')
            }
            //Parent details for CSV
            let parentCarerTitle = ''
            let parentName = ''
            let parentEmail = ''
            let contactNumbers = []
            if (app[0].parentCarers?.length) {
              parentCarerTitle = app[0].parentCarers[0].parentCarerTitle
                ? app[0].parentCarers[0].parentCarerTitle + ' '
                : ''
              parentName =
                parentCarerTitle +
                app[0].parentCarers[0].parentCarerGivenName +
                ' ' +
                app[0].parentCarers[0].parentCarerFamilyName
              parentEmail = app[0].parentCarers[0].parentCarerEmail
              contactNumbers = app[0].parentCarers[0]?.contactDetails.map(
                (w) => w.contactValue
              )
              contactNumbers.length === 1
                ? (contactNumbers = contactNumbers + ';')
                : (contactNumbers = contactNumbers.join('; '))
            }
            selectedEOIs.push([
              app[0].student.SRN,
              app[0].student.familyName,
              app[0].student.firstName,
              app[0].eoiID,
              app[0].student.genderCode,
              app[0].applicationStatus,
              app[0].localHighSchool.split('|')[0],
              school1,
              school2,
              school3,
              walkupSchools,
              app[0].currentPrimarySchool?.schoolName,
              app[0].student.dateOfBirth,
              redFlagTag,
              blueFlagTag,
              yellowFlagTag,
              greenFlagTag,
              pinkFlagTag,
              orangeFlagTag,
              yellowStrikeFlagTag,
              greenStrikeFlagTag,
              greyFlagTag,
              pnrFlagTag,
              pfFlagTag,
              naFlagTag,
              scFlagTag,
              shFlagTag,
              vaFlagTag,
              sibFlagTag,
              tmpFlagTag,
              parentName,
              parentEmail,
              app[0].residentialAddress?.addressLine1,
              app[0].residentialAddress?.suburbName,
              app[0].residentialAddress?.postCode,
              contactNumbers,
              app[0].psDateSentToERN,
              app[0].applicationCustomStatus
            ])
          }
        }
      }
      selectedEOIs
        .sort((a, b) => (a[35] > b[35] ? 1 : -1))
        .forEach((element) => element.pop())
      selectedEOIs.unshift(
        ['Sensitive - personal and health information'],
        [''],
        [
          'SRN',
          'Family Name',
          'First Name',
          'EOI ID',
          'Gender',
          'EOI Status',
          'Designated HS',
          'School 1',
          'School 2',
          'School 3',
          'Walk Ups',
          'Primary School Name',
          'DOB',
          'Current behaviour',
          'Multiple enrolments',
          'Current PL & SP dossier',
          'Current risk assessment dossier',
          'Existing parental dossier',
          'Health condition',
          'Historical PL & SP dossier',
          'Historical risk assessment dossier',
          'Past behaviour',
          'Placement not required',
          'Paper EOI',
          'Non-Australian citizens',
          'Support class',
          'Selective HS applicant',
          'Verbal advice',
          `${this.siblingsTag.description}`,
          'Temporary resident',
          'Parent Name',
          'Parent Email',
          'Address line 1',
          'Suburb',
          'Postcode',
          'Contact',
          'Received Date'
        ]
      )
      return selectedEOIs
    },
    headers() {
      return [
        {
          value: 'selectAll',
          align: 'center',
          class: 'select-all',
          sortable: false
        },
        {
          text: 'Received',
          value: 'psDateSentToERN',
          class: 'date-received',
          align: 'start'
        },
        {
          text: 'Student name',
          value: 'name',
          class: 'name',
          align: 'start'
        },
        {
          text: 'Designated high school',
          value: 'localHighSchool',
          class: 'designated-high-school',
          align: 'start'
        },
        {
          text: 'Round 1',
          value: 'round1',
          class: 'round-1',
          align: `start ${this.currentRoundClass(1)}`
        },
        {
          text: 'Round 2',
          value: 'round2',
          class: 'round-2',
          align: `start ${this.currentRoundClass(2)}`
        },
        {
          text: 'Round 3',
          value: 'round3',
          class: 'round-3',
          align: `start ${this.currentRoundClass(3)}`
        },
        {
          text: 'Walk ups',
          value: 'walkupsMain',
          class: 'walkup',
          align: 'start'
        },
        {
          text: 'Status',
          value: 'applicationCustomStatus',
          class: 'status',
          align: 'start',
          width: '170'
        },
        {
          text: '',
          value: 'data-table-expand',
          class: 'data-table-expand',
          sortable: false,
          align: 'start'
        }
      ]
    },
    applications() {
      const applications =
        this.y67tApplications(this.schoolCode)?.filter(
          (app) =>
            app.psDateSentToERN ||
            app.application.applicationStatus !== 'Withdrawn'
        ) || []

      const applicationsMapped = applications.map((a) => {
        // flatten application data
        const app = { ...a, ...a.application }
        app.localHighSchool = app.localHighSchool?.schoolName
          ? app.localHighSchool.schoolName +
            '|' +
            app.localHighSchool.registrationStatus
          : ''
        let roundTemp1 = app.ooaHighSchools
          ? app.ooaHighSchools.filter((o) => o.preferenceNo == 1)
          : []
        app.round1 =
          roundTemp1.length > 0
            ? roundTemp1[0].schoolName + '|' + roundTemp1[0].registrationStatus
            : ''
        let roundTemp2 = app.ooaHighSchools
          ? app.ooaHighSchools.filter((o) => o.preferenceNo == 2)
          : []
        app.round2 =
          roundTemp2.length > 0
            ? roundTemp2[0].schoolName + '|' + roundTemp2[0].registrationStatus
            : ''
        let roundTemp3 = app.ooaHighSchools
          ? app.ooaHighSchools.filter((o) => o.preferenceNo == 3)
          : []
        app.round3 =
          roundTemp3.length > 0
            ? roundTemp3[0].schoolName + '|' + roundTemp3[0].registrationStatus
            : ''
        const flagsTags = []
        if (this.hasAnySiblings(app?.ooaHighSchools)) {
          flagsTags.push(this.siblingsTag)
        }
        if (app?.flags) {
          flagsTags.push(...app?.flags)
        }
        app.flagsTags =
          flagsTags.length > 0
            ? Object.values(flagsTags).map((a) => a.type)
            : []

        if (app.flagsTags.includes('TMP') && app.flagsTags.includes('NA')) {
          app.flagsTags.splice(app.flagsTags.indexOf('NA'), 1)
        }

        app.walkupsAll = []
        app.walkups = app.walkups ? _.orderBy(app.walkups, 'schoolName') : []
        app.walkups?.forEach((walkup) => {
          app.walkupsAll.push(
            walkup.schoolName + '|' + walkup.registrationStatus
          )
        })
        app.walkupsMain =
          app.walkupsAll?.length > 0
            ? app.walkupsAll.length > 1
              ? 'Multiple'
              : app.walkupsAll[0]
            : ''
        let schoolOrder = '5'
        const appPreferenceNoArray = [-1, -2]
        if (appPreferenceNoArray.includes(app.preferenceNo)) {
          // if a direct application of any kind do not allow downloading
          app.noDownload = app?.walkups?.length > 0

          // if a unstamped/stamped application use this to route to enrolment applications
          app.directLocalAppWithHref = app?.walkups?.filter(
            (w) =>
              appPreferenceNoArray.includes(w.preferenceNo) &&
              w.schoolCode === app.schoolCode &&
              w.applicationId
          )

          // prevent enabling bulk offer button for paperLocalERN and paperOoaERN registrations
          app.activePaperQuickERN = app?.walkups?.some(
            (w) =>
              w.schoolCode === app.schoolCode &&
              w.registrationStatus === 'Active'
          )
        }

        if (
          app.applicationStatus === Y67T_STATUS.OFFERED &&
          app.offeredDateTime
        ) {
          const currentDate = moment().startOf('day')
          const offeredDate = moment(app.offeredDateTime).startOf('day')
          const daysPassed = currentDate.diff(offeredDate, 'days')
          app.timelapsed = daysPassed > 365 ? false : true
          app.timelapsedDays =
            daysPassed === 0
              ? 'Today'
              : daysPassed === 1
              ? daysPassed + ' day ago'
              : daysPassed + ' days ago'
        }

        if (app.localHighSchool.includes(this.selectedSchool)) {
          app.eoiType = 'Local students'
          schoolOrder = 1
        } else if (app.round1.includes(this.selectedSchool)) {
          app.eoiType = 'OOA Round 1'
          schoolOrder = 2
        } else if (app.round2.includes(this.selectedSchool)) {
          app.eoiType = 'OOA Round 2'
          schoolOrder = 3
        } else if (app.round3.includes(this.selectedSchool)) {
          app.eoiType = 'OOA Round 3'
          schoolOrder = 4
        }
        let statusOrder = Y67T_STATUS_SORTING_ORDER.indexOf(
          app.applicationStatus
        )
        app.applicationCustomStatus =
          statusOrder +
          '|' +
          schoolOrder +
          '|' +
          app.student.familyName.toLowerCase() +
          '|' +
          app.applicationStatus
        return app
      })
      return applicationsMapped.filter(
        (application) =>
          this.matchesSearchFilter(application) &&
          this.matchesStatusFilter(application) &&
          this.matchesEoiTypeFilter(application) &&
          this.matchesFlagsTagsFilter(application) &&
          this.matchesReceivedYearFilter(application) &&
          this.matchesReceivedDateRangeFilter(application)
      )
    },
    statusFilters() {
      const applications = this.y67tApplications(this.schoolCode)
      const applicationStatuses = [
        ...new Set(applications.map((app) => app.applicationStatus))
      ]
      const index = applicationStatuses.indexOf('AutoDraft')
      if (index > -1) {
        applicationStatuses.splice(index, 1)
      }

      return this.sortStatuses(applicationStatuses)
    },
    eoiTypeFilters() {
      const eoiTypes = []
      const applications = this.y67tApplications(this.schoolCode)
      let localHighSchools = applications.filter(
        (a) => a.localHighSchool?.schoolName === this.selectedSchool
      )
      localHighSchools.length > 0 ? eoiTypes.push('Local students') : ''
      let ooaRound1Schools = applications.filter((a) =>
        a.ooaHighSchools?.find(
          (o) => o.preferenceNo === 1 && o.schoolName === this.selectedSchool
        )
      )
      ooaRound1Schools.length > 0 ? eoiTypes.push('OOA Round 1') : ''
      let ooaRound2Schools = applications.filter((a) =>
        a.ooaHighSchools?.find(
          (o) => o.preferenceNo === 2 && o.schoolName === this.selectedSchool
        )
      )
      ooaRound2Schools.length > 0 ? eoiTypes.push('OOA Round 2') : ''
      let ooaRound3Schools = applications.filter((a) =>
        a.ooaHighSchools?.find(
          (o) => o.preferenceNo === 3 && o.schoolName === this.selectedSchool
        )
      )
      ooaRound3Schools.length > 0 ? eoiTypes.push('OOA Round 3') : ''
      let walkupsCheck = applications.filter((a) =>
        a.walkups?.find((w) => w.schoolName === this.selectedSchool)
      )
      walkupsCheck.length > 0 ? eoiTypes.push('Walk ups') : ''
      return eoiTypes
    },
    flagsTagsFilters() {
      const applications = this.y67tApplications(this.schoolCode)

      const flagsTagsAllArray = applications.map((a) => {
        const app = _cloneDeep(a)
        const flagsTags = []
        if (this.hasAnySiblings(app?.ooaHighSchools)) {
          flagsTags.push(this.siblingsTag)
        }
        if (app?.flags) {
          flagsTags.push(...app?.flags)
        }
        app.flagsTags =
          flagsTags.length > 0
            ? Object.values(flagsTags).map((a) => a.type)
            : []
        return app.flagsTags
      })
      const flagsTagsUniqueArray = [...new Set([].concat(...flagsTagsAllArray))]
      const flagTagsDescrptions = []
      flagsTagsUniqueArray.forEach((flagTag) => {
        flagTagsDescrptions.push(this.getFlagTagDescription(flagTag))
      })
      return [...new Set([].concat(...flagTagsDescrptions))].sort()
    },
    yearOptions() {
      const applications =
        this.y67tApplications(this.schoolCode)?.filter(
          (app) =>
            app.psDateSentToERN ||
            app.application.applicationStatus !== 'Withdrawn'
        ) || []

      const applicationReceivedYear = [
        ...new Set(
          applications.map((app) => this.getYearDate(app?.psDateSentToERN))
        )
      ]
      // push current year in the filter if no applications for the current year are present in the list
      if (!applicationReceivedYear.includes(currentYear)) {
        applicationReceivedYear.push(currentYear)
      }
      return applicationReceivedYear.reverse().sort(function (a, b) {
        return b - a
      })
    },
    receivedDateFilter() {
      const applications = this.y67tApplications(this.schoolCode)
      return { disabled: applications.length ? false : true }
    },
    downloadBtnAriaLabel() {
      return Object.keys(this.selectedIds).length > 1
        ? 'Download EOIs/PNRs'
        : 'Download EOI/PNR'
    },
    roundsWarningAcknowledged: {
      get() {
        return this.sessionRoundsWarningAcknowledged
      },
      set(value) {
        sessionStorage.setItem(Y67T_ROUNDS_ACK_SESSION_KEY, value)
        this.sessionRoundsWarningAcknowledged = value
      }
    },
    showUpcomingRoundsAlert() {
      return (
        !this.roundsWarningAcknowledged && this.dateWithinCurrentRoundEndDate
      )
    },
    currentRoundEndDate() {
      const currentRound = this.y67tRoundDates?.find((element) => {
        return moment().isBetween(
          moment(element.startDate),
          moment(element.endDate),
          'days',
          []
        )
      })
      return currentRound?.endDate
    },
    formattedCurrentRoundEndDate() {
      return moment(this.currentRoundEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    dateWithinCurrentRoundEndDate() {
      const roundEndDate = this.currentRoundEndDate
      if (!roundEndDate) {
        return false
      }
      const endDate = moment(roundEndDate)
      const days7BeforeEndDate = moment(roundEndDate).subtract(7, 'd')
      return moment().isBetween(days7BeforeEndDate, endDate, 'days', [])
    },
    getCurrentRound() {
      const currentRoundInfo = this.y67tRoundDates?.find((element) => {
        return moment().isBetween(
          moment(element.startDate),
          moment(element.endDate),
          'days',
          []
        )
      })
      return currentRoundInfo ? currentRoundInfo.round : 0
    },
    csvFileName() {
      return `year 6-7 EOI list_${currentDate}.csv`
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .local-alert {
  .subtext {
    font-weight: 500 !important;
    margin-bottom: 10px !important;
  }
  .duplicate {
    line-height: 1.5 !important;
    font-weight: 500 !important;
    margin-bottom: 0px !important;
  }
  .link {
    color: $ads-primary-blue;
    font-weight: 800;
    text-decoration: underline;
    cursor: pointer;
  }
  .heading {
    color: $ads-grey-01;
  }
  .v-alert__icon {
    font-size: 30px !important;
    margin-top: 3px;
  }
  .v-alert:focus {
    border: none;
    outline: 3px solid $ads-primary-blue;
    border-radius: 4px;
  }
  a:focus {
    border: none;
    outline: 2px solid $ads-primary-blue;
    border-radius: 4px;
  }
}
.offerBtn {
  padding-right: 0px !important;
}
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}
.select-all {
  text-decoration: underline;
}
::v-deep .dataTable.v-data-table .v-data-table__wrapper > table > tbody {
  vertical-align: top !important;
}
::v-deep .headerBtn .v-btn__content {
  white-space: normal !important;
  text-align: left;
  flex: initial;
}
::v-deep .v-btn:not(.v-btn--round).v-size--small {
  height: auto !important;
}
.focus-ring:focus {
  outline: 2px solid $ads-navy;
  border-radius: 3px;
}
.results-text {
  margin: 25px 10px 0 0 !important;
}

::v-deep .mdi-magnify {
  margin-top: 3px !important;
}
.collapse-all-button {
  margin: 13px 20px 0 0 !important;
}
::v-deep .v-ripple__container {
  width: 0 !important;
}
::v-deep .ApplicationStatusChips {
  .v-chip__content span {
    display: inline-block;
    text-align: center;
    min-width: 100px;
    font-weight: bold;
  }
}
.v-card__actions {
  padding: 1rem 3rem;
  background: $grey-6;
}

::v-deep .v-input.searchTxtField {
  background-color: transparent;
  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }
  input::placeholder {
    color: $color-placeholder;
  }
  input:-ms-input-placeholder,
  input::-ms-input-placeholder {
    color: $color-placeholder;
  }
  //overridding clear button styles after updating to vuetify 3.2.8
  .v-input__icon--clear button {
    padding: 0;
    border: none;
  }
  .v-input__icon--clear .theme--light.v-icon:before {
    color: white;
    background-color: $color-text-light;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }
  .v-input__icon.v-input__icon--append .theme--light.v-icon {
    color: $color-secondary;
  }
}
.alert {
  color: $color-red;
}
// data table
::v-deep .v-data-table {
  padding: 0;
  border: 0;
  .v-data-table__expand-icon {
    color: $grey-darken1;
    margin-left: -5px;
  }
  //focus styles for expand
  .v-data-table__expand-icon:focus {
    border-radius: 50%;
    color: $ads-navy;
    outline: 3px solid $ads-navy !important;
    border: none !important;
  }
  .v-data-table__expand-icon--active {
    color: $ads-navy;
    outline: none !important;
  }
  .theme--light.v-icon:focus::after {
    opacity: 0;
  }
  .v-data-table__wrapper {
    overflow-x: hidden !important;
  }
  table {
    table-layout: fixed;
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    th[scope='col'] {
      color: $color-primary;
      font-size: 16px;
      font-weight: normal;
      padding: 20px 8px;
      vertical-align: top;
      .v-data-table-header__icon {
        color: $color-primary-lighten-1;
        margin-left: 3px;
      }
      &.active {
        font-weight: bold;
        color: $color-primary;
        .v-data-table-header__icon {
          color: $color-primary;
        }
      }
      &.select-all {
        width: 60px;
      }
      &.date-received {
        width: 110px;
      }
      &.name {
        width: 90px;
      }
    }
  }

  div.v-data-table__wrapper table tbody {
    tr:not(:last-child) td {
      border-bottom: 1px solid white;
      padding: 25px 8px;
    }
    tr:last-child td {
      border-bottom: 1px solid white;
      padding: 10px;
    }
    td {
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
    }
  }
}
@include desktop {
  .ApplicationList {
    padding: 0 3rem;
    min-height: 100%;
  }

  ::v-deep .v-data-table {
    td {
      cursor: default;
    }
    tbody tr.v-data-table__expanded__content {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      vertical-align: top;
    }
    &.pending thead.v-data-table-header th.sortable.date-received {
      padding-left: 20px;
    }

    thead.v-data-table-header th.sortable {
      padding: 20px 10px;
      &.date-received {
        width: 122px;
      }
      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: auto;
        min-width: 120px;
      }
      &.status {
        width: 150px;
      }
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        padding: 20px 10px;
      }
      tr:last-child td {
        padding: 20px 10px;
      }
      tr td:first-child {
        padding-left: 20px;
      }
      tr td.status-processed {
        padding-right: 20px;
      }
    }
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
    }
  }
}
#app.v-application
  button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}

body
  button:not(
    .v-expansion-panel-header,
    .no-styling,
    .v-btn--fab,
    .v-icon,
    .v-picker button,
    .v-btn--disabled,
    .stepper button
  ),
body input[type='button'] {
  margin: 0px;
}
.filterLabel {
  color: $color-placeholder;
}
.chip-label {
  min-width: 90px;
  font-weight: bold;
}
.data-table-header {
  align: right;
}
.column-text {
  display: inline;
  margin-left: 3px;
}
.timelapse-text {
  display: inline;
  margin-left: 5px;
  color: $grey-1;
}
.timelapse-cell {
  display: flex;
  justify-content: center;
}
.timelapse-contain {
  margin-top: -20px;
}
.text-wrap-inline {
  padding-left: 1.5em;
  text-indent: -1.95em;
}
::v-deep .v-data-table.dataTable .searchInput {
  padding: 16px 0px 16px 20px !important;
}
::v-deep
  .dataTable
  table
  thead:not(.v-data-table-header-mobile)
  th:first-child {
  padding-left: 10px !important;
}
::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
  border: 3px solid transparent;
  .v-icon {
    color: $ads-white !important;
  }
}
.theme--light.v-btn.v-btn--outlined:focus {
  box-shadow: 0 0 0 2px $ads-navy !important;
}

.status-active,
.status-offered,
.status-submitted {
  font-weight: bold;
}
.status-offer-expired,
.status-declined,
.status-withdrawn,
.status-not-required,
.status-inactive,
.status-not-accepted {
  text-decoration-line: line-through;
  color: $ads-dark-60;
}

.status-icon-withdrawn,
.status-icon-declined,
.status-icon-offer-expired,
.status-icon-not-required,
.status-icon-inactive,
.status-icon-not-accepted,
.status-icon-pending {
  color: $ads-dark-60;
}

.status-pending {
  color: $ads-dark-60;
}

.status-icon-submitted.same-school {
  color: $ads-success-green;
}
[title='Multiple'] {
  color: $ads-dark !important;
  font-weight: bold;
}
.status-icon-offered.same-school {
  color: $ads-primary-blue;
}
.status-icon-active.same-school {
  color: $ads-warning-orange;
}
.different-school {
  color: $ads-dark-60;
}
.row-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 5px 10px 0px;
  padding: 0px;
  width: 100%;
}
.flags-list {
  list-style-type: none;
  color: $grey-1;
  margin-top: -20px;
  margin-left: -20px;
  img {
    border-radius: 4px;
  }
  .flag-description {
    padding-left: 5px;
  }
  .flags-list-item {
    float: left;
    clear: left;
  }
}
.walkups-list {
  list-style-type: none;
  color: $grey-1;
  margin-top: -20px;
  margin-left: -20px;
  img {
    border-radius: 4px;
  }
  .walkup-description {
    padding-left: 5px;
  }
  .walkup-list-item {
    float: left;
    clear: left;
  }
}
.rounds-dismiss-button {
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  &:focus {
    border: 3px solid $ads-navy;
  }
}
::v-deep .current-round {
  background-color: rgb(203, 237, 253, 0.43) !important;
}
::v-deep .round-alert {
  .alertElement:focus {
    border: none;
    outline: 3px solid $ads-navy;
  }
  .rounds-dismiss-button {
    top: 18px;
  }
}

.y7-item-name {
  column-gap: 0.5rem;
}
</style>
