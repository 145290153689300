import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import laApplications from './modules/laApplications'
import ooaApplications from './modules/ooaApplications'
import y67tApplications from './modules/y67tApplications'
import y67tSummary from './modules/y67tSummary'
import y67tPaperApplication from './modules/y67tPaperApplication'
import settings from './modules/settings'
import documentUpload from './modules/documentUpload'
import y67tDocumentUpload from './modules/y67tDocumentUpload'
import stepperModule from './modules/stepper'
import studentDetailUpdatesModule from './modules/studentDetailUpdates'
import notifications from './modules/notifications'
import archiving from './modules/archiving'

Vue.use(Vuex)

// eslint-disable-next-line import/no-named-as-default-member
const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    laApplications,
    ooaApplications,
    y67tApplications,
    y67tSummary,
    y67tPaperApplication,
    settings,
    documentUpload,
    y67tDocumentUpload,
    stepperModule,
    studentDetailUpdatesModule,
    notifications,
    archiving
  }
})
store.dispatch('setStore') // Passes store instance on to any other helpers that need it
export default store
