<!-- Component for filter options (Checkbox and radio seclection controls) -->
<template>
  <v-menu
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <a role="button" href="#" @click="menu = true" :aria-label="chipLabel">
        <v-chip
          class="chip"
          :disabled="!filterOptions.length"
          :class="selectedFilterOptions ? 'selected' : ''"
          outlined
          v-on="on"
        >
          {{ label }}
          <span v-if="selectedFilterOptions">
            <v-icon class="pl-1">cancel</v-icon>
          </span>
          <span v-else>
            <v-icon>arrow_drop_down</v-icon>
          </span>
        </v-chip>
      </a>
    </template>
    <FocusTrap :is-active="isActive" v-if="filterOptionsInternal.length">
      <v-card class="filterOptions" :style="getStyle">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><strong>{{ label }}</strong></v-list-item-title
              >
              <!--
              FUS-1813: Changes to eslint rules now disallow the use of
              v-text and v-html on components. The error for the next
              block has been disabled as it conforms to the standards set
              by Vuetify 2.
              @see https://v2.vuetifyjs.com/en/components/lists/#dense
              -->
              <!-- eslint-disable vue/no-v-text-v-html-on-component -->
              <v-list-item-subtitle
                v-if="comment"
                v-html="comment"
                tabindex="0"
              ></v-list-item-subtitle>
              <!-- eslint-enable vue/no-v-text-v-html-on-component -->
            </v-list-item-content>
            <v-list-item-action>
              <a
                href="#"
                @click="menu = false"
                class="dialogCloseBtn"
                aria-label="Close filter options menu"
                role="button"
              >
                <v-icon class="text-right closeBtn">mdi-close</v-icon>
              </a>
            </v-list-item-action>
          </v-list-item>
          <div v-if="type === 'checkbox'" :class="columnWrapper">
            <v-list-item
              v-for="filter in filterOptionsInternal"
              :key="filter.value"
            >
              <OesCheckbox
                v-model="selectedOptions"
                :label="filter.label"
                :value="filter.value"
                class="checkFilter"
                @click="$emit('updatefilter', selectedOptions)"
              />
            </v-list-item>
          </div>
          <div v-if="type === 'radio'">
            <v-list-item>
              <v-radio-group v-model="radioOption">
                <v-radio
                  v-for="filter in filterOptionsInternal"
                  :key="filter.value"
                  :label="filter.label"
                  :value="filter.value"
                  @click="$emit('updatefilter', radioOption)"
                ></v-radio>
              </v-radio-group>
            </v-list-item>
            <div class="pl-4 pb-4 clearfilter">
              <a role="button" href="#" @click="clearFilter">Clear filter</a>
            </div>
          </div>
          <v-list-item class="mb-2" v-if="archiveType">
            <SearchArchive :archive-type="archiveType" />
          </v-list-item>
        </v-list>
      </v-card>
    </FocusTrap>
  </v-menu>
</template>

<script>
import { FocusTrap } from '@nswdoe/doe-ui-core'
import OesCheckbox from './OesCheckbox.vue'
import _isEqual from 'lodash/isEqual'
import SearchArchive from '@/components/app/SearchArchive.vue'

export default {
  name: 'ListViewFilter',
  props: {
    filterOptions: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Show only'
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    showDefaultFilter: {
      type: Array,
      default: () => []
    },
    comment: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '250px'
    },
    columnWrapper: {
      type: String,
      default: ''
    },
    archiveType: {
      type: String,
      default: ''
    }
  },
  components: {
    SearchArchive,
    FocusTrap,
    OesCheckbox
  },
  data() {
    return {
      menu: false,
      selectedOptions: [],
      radioOption: null,
      isActive: false
    }
  },
  watch: {
    // DG: this logic won't actually clear the filters when the school is swapped.
    // This will only clear the filters if the options are different.
    filterOptions(newVal, oldVal) {
      if (newVal && oldVal && _isEqual(newVal, oldVal)) {
        // don't clear the filters ( back to list view button)
        return
      }
      if (this.showDefaultFilter && this.showDefaultFilter.length > 0) {
        // don't clear the current year filter
        this.selectedOptions = this.showDefaultFilter
        return
      }
      this.selectedOptions = []
      this.radioOption = null
    },
    menu(opened) {
      this.isActive = opened
      this.$emit('menuOpened', opened)
    },
    schoolCode(newVal, oldVal) {
      // clear selected filters on change of school switcher
      if (newVal && oldVal && _isEqual(newVal, oldVal)) {
        return
      }
      if (this.showDefaultFilter && this.showDefaultFilter.length > 0) {
        // don't clear the current year filter
        this.selectedOptions = this.showDefaultFilter
        return
      }
      this.selectedOptions = []
      this.radioOption = null
    }
  },
  created() {
    // y67t set default filter - display applications for the current year
    if (this.showDefaultFilter && this.showDefaultFilter.length > 0) {
      this.selectedOptions = this.showDefaultFilter
      this.$emit('updatefilter', this.selectedOptions)
    }
  },
  computed: {
    selectedFilterOptions() {
      if (this.type === 'checkbox') {
        return this.selectedOptions.length ? true : false
      }
      if (this.type === 'radio') {
        return this.radioOption ? true : false
      }
      return false
    },
    chipLabel() {
      return `Filter by ${this.label}`
    },
    filterOptionsInternal() {
      return this.filterOptions.map((option) =>
        typeof option === 'object' ? option : { value: option, label: option }
      )
    },
    schoolCode() {
      return this.$store.getters.selectedSchoolCode
    },
    getStyle() {
      if (this.width) {
        return { 'max-width': this.width, width: this.width }
      }
      return {}
    }
  },
  methods: {
    clearFilter() {
      if (this.type === 'radio') {
        this.radioOption = null
        this.$emit('updatefilter', this.radioOption)
      } else {
        this.selectedOptions = []
        this.$emit('updatefilter', this.selectedOptions)
      }
    },
    selectFilter(selectedOptions) {
      if (this.type === 'radio') {
        this.radioOption = selectedOptions
        this.$emit('updatefilter', this.radioOption)
      } else {
        this.selectedOptions = selectedOptions
        this.$emit('updatefilter', this.selectedOptions)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-chip.chip.v-chip--outlined {
  border-color: $grey-darken1;
  background-color: white !important;
  color: $grey-darken1;
}
.v-chip.chip.v-chip--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
  border-color: $ads-navy;
}
.checkFilter {
  margin-top: 2px;
}
a {
  text-decoration: none;
}

::v-deep .filterOptions .theme--light.v-label {
  color: $grey-dark;
}

::v-deep .filterOptions .theme--light.v-list-item .v-list-item__subtitle {
  font-size: 16px;
  color: $grey-dark;
}

::v-deep .v-list-item__title {
  font-size: 16px;
}
.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  a {
    text-decoration: underline;
  }
}
.column_wrapper {
  max-height: 400px;
  display: flex;
  flex-flow: column wrap;
}
</style>
