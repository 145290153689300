import Vue from 'vue'
import Router from 'vue-router'
import API from '@/store/apiUtils'
import ApplicationView from './views/ApplicationView.vue'
import UTILS from '@/store/utils'
import ApplicationListView from './views/ApplicationListView.vue'

Vue.use(Router)

const queryParams = getQueryParams()
const isRedirectFromOpenAm = queryParams.code
const appWindow = UTILS.isChildWindow() ? window.opener : window
const router = new Router({
  routes: [
    {
      path: '/',
      name: 'ApplicationListView',
      component: ApplicationListView
    },
    {
      path: '/application/:schoolCode/:id',
      name: 'ApplicationViewOther',
      component: ApplicationView
    },
    {
      path: '/application/:id',
      name: 'ApplicationView',
      component: ApplicationView
    },
    {
      path: '/archive/:schoolCode/:id/:type/:primary?',
      name: 'ArchiveApplicationView',
      component: ApplicationView
    },
    {
      path: '/student-detail-update/:schoolCode/:id',
      name: 'StudentDetailUpdateView',
      component: () => import('./views/StudentDetailUpdateView.vue')
    },
    {
      path: '/print/:id',
      name: 'PrintView',
      component: () => import('./views/PrintView.vue')
    },
    {
      path: '/logout',
      beforeEnter(to, from, next) {
        window.location.href = process.env.VUE_APP_AUTH_LOGOUT_URI

        // #DSE-877
        // Remove the auth tokens in the next engine cycle rather than
        // immediately to prevent a race condition with `reauthenticateSession()`
        // in <AppSessionCountdownBar/>
        setTimeout(API.clearTokens, 0)

        // Make absolutely sure the router doesn't proceed from here.
        next(false)
      }
    }
  ]
})

// Before each route, check tokens and redirect to SSO if necessary...
if (!API.isOfflineTesting) {
  router.beforeEach((to, from, next) => {
    if (
      !isTokens() &&
      !isCode() &&
      !isRedirectFromOpenAm &&
      to.path !== '/logout'
    ) {
      router.app.$auth.DET.startAuth()
      return
    }
    next()
  })
}

export default router

// HELPER FUNCTIONS-------------------------------------------------------------

function isTokens() {
  // True if tokens present in session storage
  return !!(
    appWindow.sessionStorage.getItem('accessToken') &&
    appWindow.sessionStorage.getItem('idToken')
  )
}

function isCode() {
  // True if tokens present in session storage
  return !!appWindow.sessionStorage.getItem('si.auth.code')
}

function getQueryParams() {
  return location.search
    .slice(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
}
