import API from '../../store/apiUtils'

export const SEARCH_STATUS = {
  READY: 'READY',
  WORKING: 'WORKING',
  OK: 'OK',
  ERROR: 'ERROR'
}

export const API_RESPONSE = {
  ID: 'applicationId',
  DATE_RECEIVED: 'dateReceived',
  TYPE: 'applicationType',
  FIRST_NAME: 'firstName',
  FAMILY_NAME: 'familyName',
  FULL_NAME: 'fullName',
  DATE_OF_BIRTH: 'dateOfBirth',
  SCHOLASTIC_YEAR: 'scholasticYear',
  DATE_START: 'intendedStartDate',
  UPDATE_STATUS: 'applicationStatus',
  SCHOLASTIC_YEAR_FORMAT: 'scholasticYearFormatted',
  IS_PRIMARY: 'isPrimary'
}

const DEFAULT_SEARCH_ERROR =
  'Unable to retrieve archived applications. Please try again.'

const archiving = {
  namespaced: true,
  state: () => ({
    selectedType: null,
    searchResults: [],
    searchStatus: SEARCH_STATUS.READY,
    searchError: DEFAULT_SEARCH_ERROR,
    dummyCounter: 0
  }),
  getters: {
    selectedType: (state) => state.selectedType,
    searchStatus: (state) => state.searchStatus,
    searchResults: (state) => state.searchResults,
    searchError: (state) => state.searchError
  },
  mutations: {
    setType(state, value) {
      state.selectedType = value
    },
    setSearchStatus(state, value) {
      state.searchStatus = value
    },
    setSearchResults(state, value) {
      state.searchResults = value
    },
    setSearchError(state, value) {
      state.searchError = value ?? DEFAULT_SEARCH_ERROR
    },
    setDummyCounter(state, value) {
      state.dummyCounter = value
    }
  },
  actions: {
    setType({ commit }, value) {
      commit('setType', value)
    },
    setSearchResults({ commit }, value) {
      commit('setSearchResults', value)
    },
    resetSearch({ commit }) {
      commit('setType', null)
      commit('setSearchStatus', SEARCH_STATUS.READY)
      commit('setSearchResults', [])
    },
    search({ dispatch, commit, state, rootGetters }, filters) {
      commit('setSearchStatus', SEARCH_STATUS.WORKING)
      commit('setSearchResults', [])

      const { schoolCode } = rootGetters.selectedSchool

      const apiUrl = `${process.env.VUE_APP_API_PI}/v1/archived/applications/${schoolCode}`
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        'x-id-token': sessionStorage.getItem('idToken'),
        schoolCode
      }

      const payload = {
        applicationType: state.selectedType,
        schoolCode,
        ...filters
      }

      API.post(apiUrl, payload, true, headers, true)
        .then((response) => response)
        .catch(({ response }) => response)
        .then(({ status, data }) => {
          const items = data?.body ?? []
          let searchStatus = SEARCH_STATUS.OK

          if (status !== 200 || !data || data?.status !== 200) {
            searchStatus = SEARCH_STATUS.ERROR
            dispatch(
              'showMessageBox',
              {
                icon: 'priority_high',
                html: `<h2>Unable to complete request</h2><p>A connection error has occurred. Please try again later. If this problem persists, please contact our support team.</p>`
              },
              { root: true }
            )
          }

          // Combine firstName and familyName to create fullName
          if (Array.isArray(items)) {
            for (let i = 0; i < items.length; ++i) {
              const firstName = items[i][API_RESPONSE.FIRST_NAME]
              const familyName = items[i][API_RESPONSE.FAMILY_NAME]
              items[i][API_RESPONSE.FULL_NAME] = `${familyName}, ${firstName}`
            }
          }

          commit('setSearchError', DEFAULT_SEARCH_ERROR)
          commit('setSearchResults', items)
          commit('setSearchStatus', searchStatus)
        })
    }
  }
}

export default archiving
