<template>
  <v-card class="mt-10" data-testid="app-settings-sre-see-options">
    <div v-for="(option, index) in options" :key="index">
      <div class="pl-10 pb-5">
        <v-row>
          <v-col></v-col>
          <v-col></v-col>
          <v-col class="text-right">
            <v-btn
              icon
              style="border: none"
              :disabled="disabled"
              @click="showRemoveOptionDialog(index, option.religion)"
              class="remove-option-button"
              :aria-label="
                removeButtonAriaLabel(
                  isSeeOptions ? option.name : option.religion
                )
              "
              data-testid="sre-see-options-remove-button"
              :ref="`sreSeeOptionRemoveBtn-${index}`"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pr-15">
            <div class="sre-see-number-icon">
              {{ index + 1 }}
            </div>
          </v-col>
          <v-col v-if="isSeeOptions">
            <v-combobox
              :value="option.name"
              :items="option.religions"
              v-model="option.name"
              label="Title *"
              placeholder="Title"
              :outlined="true"
              :disabled="disabled"
              @change="setReligion(index, $event)"
              :rules="[
                validators.required(option.name, 'Title'),
                validators.duplicateOption(isDuplicateOption),
                validators.isTitleExists(option)
              ]"
              data-testid="sre-see-options-religion"
              persistent-placeholder
            />
          </v-col>
          <v-col v-if="!isSeeOptions">
            <v-combobox
              :value="option.religion"
              :items="option.religions"
              v-model="option.religion"
              label="Title *"
              placeholder="Title"
              :outlined="true"
              :disabled="disabled"
              @change="setReligion(index, $event)"
              :rules="[
                validators.required(option.religion, 'Title'),
                validators.duplicateOption(isDuplicateOption),
                validators.isTitleExists(option)
              ]"
              data-testid="sre-see-options-religion"
              persistent-placeholder
            />
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col></v-col>
          <v-col>
            <AppSettingsSreSeeProviders
              :providers="option.providers"
              :available-providers="option.availableProviders"
              :disabled="disabled"
              @change="setProviders(index, $event)"
              data-testid="sre-see-options-providers"
              :is-duplicate-option="isDuplicateOption"
              :see-sre-text="seeSreText"
              v-model="option.providers"
            />
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col></v-col>
          <v-col>
            <AdsTextField
              label="Curriculum link *"
              v-model="option.curriculumLink"
              :disabled="disabled"
              data-testid="sre-see-curriculum-link"
              placeholder=" "
              @keyup="setCurriculumLink(index, $event.target.value)"
              :rules="[
                validators.required(option.curriculumLink, 'Curriculum link'),
                validators.isValidURL(option.curriculumLink)
              ]"
              persistent-placeholder
            />
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col></v-col>
          <v-col>
            <AdsTextArea
              v-model="option.description"
              label="Description *"
              placeholder=" "
              :outlined="true"
              :rows="3"
              :counter="300"
              :disabled="disabled"
              maxlength="300"
              @keyup="setDescription(index, $event.target.value)"
              class="app-settings__sre-description"
              data-testid="sre-see-options-description"
              :rules="[validators.required(option.description, 'Description')]"
              hint="Any text changes to be made in consultation with the Provider(s)"
              persistent-placeholder
            />
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row>
          <v-col></v-col>
          <v-col>
            <AppSettingsScholasticYear
              :disabled="disabled"
              :scholastic-years="option.scholasticYears"
              :available-scholastic-years="option.availableScholasticYears"
              @updatedScholasticYears="setScholasticYears(index, $event)"
              :is-duplicate-option="isDuplicateOption"
            />
          </v-col>
          <v-col></v-col>
        </v-row>
        <v-row v-if="isDuplicateOption">
          <v-col></v-col>
          <v-col>
            <div class="alertDuplicateError">
              <div><v-icon size="26" color="red">mdi-alert-circle</v-icon></div>
              <div class="errorMessage">
                Duplication error. Please specify a unique Title, Provider and
                Scholastic Year combination.
              </div>
            </div>
          </v-col>
          <v-col></v-col>
        </v-row>
      </div>
      <v-divider
        v-if="options.length > 1 && options.length !== index + 1"
        data-testid="sre-see-options-divider"
      />
    </div>
    <DialogGeneric
      persistent
      display-close-btn
      max-width="520px"
      icon="warning_amber"
      :title="`You are about to delete a ${seeSreText} option`"
      :message="dialogMessage"
      :actions="dialogButtons"
      @click:action="removeOption"
      btn-class="dialog-buttons-style"
      v-model="showDialog"
      aria-role="alert"
    />
  </v-card>
</template>

<script>
import validators from '@/helpers/validators'
import { AdsTextArea, AdsTextField, DialogGeneric } from '@nswdoe/doe-ui-core'
import AppSettingsSreSeeProviders from './AppSettingsSreSeeProviders.vue'
import AppSettingsScholasticYear from './AppSettingsScholasticYear.vue'
import settingsUtils from '@/helpers/settingsUtils'

export default {
  name: 'AppSettingsSreSeeOptions',
  components: {
    AdsTextArea,
    DialogGeneric,
    AppSettingsSreSeeProviders,
    AppSettingsScholasticYear,
    AdsTextField
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    defaultSreSeeOptions: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    isSeeOptions: {
      type: Boolean,
      default: false
    },
    isDuplicateOption: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      religion: '',
      religionIndex: null,
      showDialog: false,
      dialogButtons: [
        { name: 'Cancel', color: 'primary', outlined: true },
        { name: 'Confirm delete', color: '#B81237' }
      ],
      validators: {
        required: validators.required,
        isValidURL: validators.isValidURL,
        duplicateOption: validators.duplicateOption,
        isTitleExists: validators.titleExists
      }
    }
  },
  computed: {
    seeSreText() {
      return this.isSeeOptions ? 'SEE' : 'SRE'
    },
    dialogMessage() {
      return `If you proceed, the ${this.seeSreText} option ${
        this.religion && !this.isSeeOptions ? `<b>'${this.religion}'</b>` : ''
      } will be deleted from the system. Future applicants will not be able to choose this option.`
    }
  },
  methods: {
    getOptionId(religion) {
      const option = this.defaultSreSeeOptions.find(
        (option) => option.name === religion
      )
      if (option) {
        return option.id
      }
      return ''
    },
    getProviders(religion) {
      const options = this.defaultSreSeeOptions.find(
        (option) => option.name === religion
      )
      return options?.providers || []
    },
    getDescription(religion) {
      const options = this.defaultSreSeeOptions.find(
        (option) => option.name === religion
      )
      return options?.defaultDescription || options?.description || ''
    },
    setReligion(index, religion) {
      for (let i in this.options) {
        if (i == index) {
          // FUS-1813: Changes to eslint rules now disallow the mutation of
          // component props. The error for the next line ishas been disabled
          // as the component is currently working.
          // eslint-disable-next-line vue/no-mutating-props
          this.options[i].id = this.getOptionId(religion)
          // FUS-1813: Changes to eslint rules now disallow the mutation of
          // component props. The error for the next line ishas been disabled
          // as the component is currently working.
          // eslint-disable-next-line vue/no-mutating-props
          this.options[i].religion = religion
          // FUS-1813: Changes to eslint rules now disallow the mutation of
          // component props. The error for the next line ishas been disabled
          // as the component is currently working.
          // eslint-disable-next-line vue/no-mutating-props
          this.options[i].name = religion
          // FUS-1813: Changes to eslint rules now disallow the mutation of
          // component props. The error for the next line ishas been disabled
          // as the component is currently working.
          // eslint-disable-next-line vue/no-mutating-props
          this.options[i].providers = []
          // FUS-1813: Changes to eslint rules now disallow the mutation of
          // component props. The error for the next line ishas been disabled
          // as the component is currently working.
          // eslint-disable-next-line vue/no-mutating-props
          this.options[i].availableProviders = this.getProviders(religion)
          // FUS-1813: Changes to eslint rules now disallow the mutation of
          // component props. The error for the next line ishas been disabled
          // as the component is currently working.
          // eslint-disable-next-line vue/no-mutating-props
          this.options[i].description = this.getDescription(religion)
        }
      }
      this.$emit('updatedSreSeeOptions', this.options, this.isSeeOptions)
    },
    setProviders(index, providers) {
      const prov = this.options[index].availableProviders.filter((element) => {
        return providers.indexOf(element.id) >= 0
      })

      for (let i in this.options) {
        if (i == index) {
          if (prov.length > 0) {
            // FUS-1813: Changes to eslint rules now disallow the mutation of
            // component props. The error for the next line is has been disabled
            // as the component is currently working.
            // eslint-disable-next-line vue/no-mutating-props
            this.options[i].providers = prov
          } else {
            // FUS-1813: Changes to eslint rules now disallow the mutation of
            // component props. The error for the next line is has been disabled
            // as the component is currently working.
            // eslint-disable-next-line vue/no-mutating-props
            this.options[i].providers = providers.sort()
          }
        }
      }
      this.$emit('updatedSreSeeOptions', this.options, this.isSeeOptions)
    },
    setDescription(index, description) {
      for (let i in this.options) {
        if (i == index) {
          // FUS-1813: Changes to eslint rules now disallow the mutation of
          // component props. The error for the next line ishas been disabled
          // as the component is currently working.
          // eslint-disable-next-line vue/no-mutating-props
          this.options[i].description = description
        }
      }
      this.$emit('updatedSreSeeOptions', this.options, this.isSeeOptions)
    },
    setCurriculumLink(index, curriculumLink) {
      for (let i in this.options) {
        if (i == index) {
          // IF Staff gave URL start with 'www.' and then replacing with 'https://'
          if (
            curriculumLink != null &&
            curriculumLink.trim().length > 0 &&
            curriculumLink.trim().substr(0, 4) === 'www.'
          ) {
            curriculumLink = curriculumLink.replace('www.', 'https://www.')
          }
          // FUS-1813: Changes to eslint rules now disallow the mutation of
          // component props. The error for the next line ishas been disabled
          // as the component is currently working.
          // eslint-disable-next-line vue/no-mutating-props
          this.options[i].curriculumLink = curriculumLink
        }
      }
      this.$emit('updatedSreSeeOptions', this.options, this.isSeeOptions)
    },
    setScholasticYears(index, years) {
      for (let i in this.options) {
        if (i == index) {
          // FUS-1813: Changes to eslint rules now disallow the mutation of
          // component props. The error for the next line ishas been disabled
          // as the component is currently working.
          // eslint-disable-next-line vue/no-mutating-props
          this.options[i].scholasticYears = years
        }
      }
      this.$emit('updatedSreSeeOptions', this.options, this.isSeeOptions)
    },
    removeButtonAriaLabel(title) {
      return `Delete the ${this.isSeeOptions ? 'SEE' : 'SRE'} option ${title}`
    },
    showRemoveOptionDialog(index, religion) {
      this.religionIndex = index
      this.religion = religion
      this.showDialog = true
    },
    removeOption(button) {
      if (button === this.dialogButtons[1].name) {
        // FUS-1813: Changes to eslint rules now disallow the mutation of
        // component props. The error for the next line ihas been disabled
        // as the component is currently working.
        // eslint-disable-next-line vue/no-mutating-props
        this.options.splice(this.religionIndex, 1)
        const updatedDuplicateOption = settingsUtils.checkDuplicateOption(
          this.options
        )
        this.$eventHub.$emit(
          this.isSeeOptions ? 'duplicateSeeOption' : 'duplicateSreOption',
          updatedDuplicateOption
        )
        this.$emit('updatedSreSeeOptions', this.options, this.isSeeOptions)
      }
      this.showDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}

.col {
  padding: 0;
}

.col:nth-child(1) {
  max-width: 60px;
}

.col:nth-child(3) {
  max-width: 100px;
}

.sre-see-number-icon {
  border-radius: 50%;
  background: $ads-blue-1;
  color: #ffffff;
  min-width: 28px;
  height: 28px;
  font-weight: 700;
  padding-top: 4px;
  text-align: center;
}

.duplicate-option {
  background: $color-error-dark;
}

.app-settings__sre-description {
  margin-left: 0 !important;

  ::v-deep .v-text-field.v-textarea {
    margin-left: 0 !important;
  }
}

.error--text {
  margin-bottom: 0.5rem !important;
}

.remove-option-button:focus,
.remove-option-button:hover {
  border: 2px solid $color-primary !important;
}
</style>
<style lang="scss">
.dialog-buttons-style {
  margin-top: 2rem !important;
}

.dialog-buttons-style:focus {
  box-shadow: 0 0 0 2px #041e42 !important;
}

.alertDuplicateError {
  display: flex;
  margin-top: 1rem;
}

.errorMessage {
  margin: auto;
  font-size: 14px;
  color: $ads-primary-red;
}
</style>
