import moment from 'moment'
import { DATE_FORMAT, DATE_OF_BIRTH_MINIMUM_YEAR } from '@/constants'

export default {
  minLength(value, expectedLength) {
    if (value && value.length < expectedLength) {
      return `Enter a minimum of ${expectedLength} characters.`
    }
    return true
  },
  required(v, k) {
    const validArray = Array.isArray(v) && v.length > 0
    const validNonArray = !Array.isArray(v) && v != null && v !== ''
    const error = k === undefined ? 'Field is required' : `${k} is required`
    return validArray || validNonArray || error
  },
  customError(error) {
    return function customErrorClosure(value) {
      return (
        (value != null && value !== '') ||
        (error === undefined ? 'Error' : error)
      )
    }
  },
  name(v, k) {
    return (
      !v ||
      /^[a-zA-Z ()'-]+$/.test(v) ||
      (k === undefined
        ? "Can only contain letters, spaces, and - ( ) '"
        : `${k} can only contain letters, spaces, and - ( ) `)
    )
  },
  phone(val, key) {
    return (
      !val ||
      /^[0-9 ()\\-]+$/.test(val) ||
      (key === undefined
        ? `Can only contain 0-9, spaces and - ( ) \\`
        : `${key} can only contain 0-9, spaces and - ( ) \\`)
    )
  },
  /**
   * This is required because the vuetify rules prop is run before the onBlur event
   * Later on the non ascii characters are removed before formatting the phone number
   * See method ParentCarer.updateAndFormatContactValue()
   *
   * \x00-\x1f = non ascii characters (char code 0-31 in hex)
   * \x7f-\uffff = non ascii characters (char code 127-65535 in hex)
   * x30-\x39 = ascii characters (digit 0-9)
   * x20\ = ascii characters (space)
   * x28\x29 = ascii characters (left and right bracket)
   * x2b\x2d = ascii characters (+ and -)
   * @param {*} v
   * @returns
   */
  containsNonAsciiOrValidCharactersForPhoneNumber(v) {
    return (
      !v ||
      // eslint-disable-next-line no-control-regex
      /^[\x00-\x1f\x7f-\uffff\x30-\x39\x20\x28\x29\x2b\x2d]+$/.test(v) ||
      'Can only contain 0-9, spaces and - ( ) +'
    )
  },
  email(v, k) {
    return (
      !v ||
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      (k === undefined ? 'Invalid email' : `Please enter a valid ${k}`)
    )
  },
  isValidURL(url) {
    if (!url) {
      return true
    }
    var res = url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    )
    return (url.trim() !== '' && res !== null) || 'Invalid URL'
  },
  emptyArray(value, error) {
    return (
      (value != null && value.length !== 0) ||
      (error === undefined ? 'Error' : error)
    )
  },
  noFutureDate(date, key) {
    if (
      date &&
      moment(date, ['MM/YYYY', DATE_FORMAT, 'DD-MM-YYYY', 'DD MMM YYYY']) >
        moment()
    ) {
      return key === undefined
        ? 'Cannot be a future date'
        : `${key} cannot be a future date`
    }
    return true
  },
  noFutureMonthAndYear(date) {
    if (
      date &&
      moment(date, ['MM-YYYY', 'YYYY-MM']).format('YYYY-MM') >
        moment().format('YYYY-MM')
    ) {
      return 'Cannot be a future date'
    }
    return true
  },
  noMonthAndYearBefore16years(date) {
    const thisYear = new Date().getFullYear()
    const minAttendedYear = thisYear - 16
    if (
      date &&
      moment(date, ['MM-YYYY', 'YYYY-MM']).format('YYYY-MM') <
        moment(
          new Date(`${minAttendedYear}-01-01`).toISOString().slice(0, 7)
        ).format('YYYY-MM')
    ) {
      return `Cannot be earlier than ${minAttendedYear}`
    }
    return true
  },
  noPastDate(date) {
    if (date) {
      const mDate = moment(date, [
        'MM/YYYY',
        DATE_FORMAT,
        'DD-MM-YYYY',
        'DD MMM YYYY'
      ])
      if (
        mDate.isBefore(
          moment(),
          mDate.creationData().format === 'MM/YYYY' ? 'month' : 'day'
        )
      ) {
        return 'Cannot be a past date'
      }
    }
    return true
  },
  getMaxFutureYearValidator(yearsInTheFuture) {
    // returns a validator function
    return function getMaxFutureYearValidatorClosure(date) {
      if (date) {
        const maxYear = new Date().getFullYear() + yearsInTheFuture
        const mDate = moment(date, [
          'MM/YYYY',
          DATE_FORMAT,
          'DD-MM-YYYY',
          'DD MMM YYYY'
        ])
        return mDate.year() <= maxYear || `Cannot be a later than ${maxYear}`
      }
      return true
    }
  },
  dateOrder(fromDate, toDate) {
    return (
      fromDate &&
      toDate &&
      moment(fromDate, ['MM-YYYY', DATE_FORMAT]) <=
        moment(toDate, ['MM-YYYY', DATE_FORMAT])
    )
  },
  getPrimaryStudentMinAgeValidator(enrolmentYear) {
    const minAge = 5
    return function getPrimaryStudentMinAgeValidatorClosure(value) {
      const newIntakeYearStartDate = moment(`${enrolmentYear}-07-31`, [
        'YYYY-MM-DD'
      ])

      const mDate = moment(value, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD MMM YYYY'])
      const studentAgeAtStartDate = newIntakeYearStartDate.diff(
        mDate,
        'year',
        true
      )
      return (
        studentAgeAtStartDate >= minAge ||
        `This student is too young to begin school in ${enrolmentYear}.`
      )
    }
  },
  primaryStudentMaxAge(date) {
    const thisYear = new Date().getFullYear()
    const cutOffYear = thisYear - DATE_OF_BIRTH_MINIMUM_YEAR
    const cutOffDate = moment(`01-01-${cutOffYear}`, [
      'YYYY-MM-DD',
      'DD-MM-YYYY',
      'DD MMM YYYY'
    ])
    return (
      cutOffDate.diff(
        moment(date, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD MMM YYYY'])
      ) <= 0 ||
      `Please ensure the date is no earlier than ${cutOffYear} and no later than one week ago.`
    )
  },
  getSecondaryStudentMinAgeValidator(enrolmentYear) {
    // student must not be younger than 9
    const minAge = 9
    const thisYear = new Date().getFullYear()

    return function getSecondaryStudentMinAgeValidatorClosure(value) {
      const cutOffDate =
        enrolmentYear === thisYear
          ? moment().startOf('day')
          : moment(`${enrolmentYear}-01-01`, ['YYYY-MM-DD'])
      const mDate = moment(value, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD MMM YYYY'])

      const studentAgeAtStartOfCalendarYear = cutOffDate.diff(
        mDate,
        'year',
        true
      )
      return (
        studentAgeAtStartOfCalendarYear >= minAge ||
        `This student is too young to begin high school in ${enrolmentYear}.`
      )
    }
  },
  getSecondaryStudentMaxAgeValidator(enrolmentYear) {
    // Student must not be older than 18
    // note: 18 is ok, 18 and 1 day is not ok
    const maxAge = 18
    const thisYear = new Date().getFullYear()

    return function getSecondaryStudentMaxAgeValidatorClosure(value) {
      const cutOffDate =
        enrolmentYear === thisYear
          ? moment().startOf('day')
          : moment(`${enrolmentYear}-01-01`, ['YYYY-MM-DD'])
      const mDate = moment(value, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD MMM YYYY'])

      const studentAgeAtStartOfCalendarYear = cutOffDate.diff(
        mDate,
        'year',
        true
      )
      return (
        studentAgeAtStartOfCalendarYear < maxAge ||
        `This student must be no younger than 9 and no older than ${maxAge} years of age to enrol in high school in ${enrolmentYear}.`
      )
    }
  },

  getY67TStudentMinAgeValidator(birthDate) {
    const dateSeventeenYearsAgo = new Date()
    dateSeventeenYearsAgo.setFullYear(dateSeventeenYearsAgo.getFullYear() - 17)
    const seventeenYearsAgo = dateSeventeenYearsAgo.toISOString().slice(0, 10)

    const dateObject = moment(birthDate, [
      'YYYY-MM-DD',
      'DD-MM-YYYY',
      'DD MMM YYYY'
    ])
    const minBirthDate = moment(seventeenYearsAgo)
    return (
      dateObject.diff(minBirthDate) >= 0 ||
      'Children enrolling in high school must be no younger than 9 and no older than 17 years of age. Please choose a date of birth within this range.'
    )
  },

  getY67TStudentMaxAgeValidator(birthDate) {
    const dateNineYearsAgo = new Date()
    dateNineYearsAgo.setFullYear(dateNineYearsAgo.getFullYear() - 9)
    const nineYearsAgo = dateNineYearsAgo.toISOString().slice(0, 10)

    const dateObject = moment(birthDate, [
      'YYYY-MM-DD',
      'DD-MM-YYYY',
      'DD MMM YYYY'
    ])
    const nineYearsAgoDate = moment(nineYearsAgo)
    return (
      dateObject.diff(nineYearsAgoDate) <= 0 ||
      'Children enrolling in high school must be at least 9 years of age.'
    )
  },
  nan: (v) =>
    !isNaN(v) ||
    'Forecast entered is not a valid number. Please use an integer between 1 to 999.',
  max: (v) =>
    (v <= 999 && v > 0 && /^[1-9]\d*$/.test(v)) ||
    'Forecast number should be in between 1 to 999. Please choose an integer within this range.',
  duplicateOption(isDuplicateOption) {
    return !isDuplicateOption
  },
  titleExists(option, availableReligions = []) {
    const religions = option.religions || availableReligions

    if (option.religion && option.religion != '') {
      if (option.religion) {
        return religions.includes(option.religion) || 'Invalid title'
      } else if (option.name) {
        return religions.includes(option.name) || 'Invalid title'
      }
    }
    return religions.includes(option.name) || 'Invalid title'
  },
  providersExists(providers, availableProviders = []) {
    let avlProviders = availableProviders
    if (availableProviders && availableProviders[0]?.name) {
      avlProviders = availableProviders.map(({ name }) => name)
    }

    const checkProviders = providers.filter((provider) => {
      return avlProviders.includes(provider.name)
    })

    return providers.length === checkProviders.length || 'Invalid provider(s)'
  }
}
