var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isWithdrawn)?_c('Alert',{staticClass:"withdrawnAlert",attrs:{"type":"info","text":"Withdrawn from submission","subtext":`by ${_vm.application.lastModifiedUser || ''} on
      ${_vm.$moment(_vm.fieldValue('lastModifiedDatetime')).format('LLLL')}`,"in-page":"","icon":"mdi-lock-outline","data-testid":"withdrawn-status-bar","role":"none"}}):(_vm.isEhubLinkedStudent && !_vm.isSentToErn)?_c('Alert',{attrs:{"type":"success","text":`Linked SRN: ${_vm.studentSrn || ''}`,"in-page":"","icon":"link","role":"none"}}):(!_vm.isSentToErn)?[(_vm.isLinked)?_c('Alert',{attrs:{"type":"success","text":`Linked SRN: ${_vm.fieldValue('student.srn')}`,"in-page":"","icon":"link","action-text":`${_vm.showUnlinkSrn ? 'UNLINK SRN' : ''}`,"data-testid":"local-link-status-bar","action-handler":_vm.check,"role":"none"}}):_vm._e(),(!_vm.isLinked)?_c('Alert',{attrs:{"type":"warning","text":"SRN has not been matched","subtext":`You need to check if ${_vm.fieldValue(
        'student.firstName'
      )} ${_vm.fieldValue('student.familyName')} has an existing SRN record.`,"in-page":"","action-text":"CHECK","action-handler":_vm.check,"data-testid":"local-new-status-bar","role":"none"}}):_vm._e()]:_vm._e(),(_vm.isSentToErn)?_c('Alert',{attrs:{"type":"success","text":'Locked and sent to ERN',"subtext":`by ${
      _vm.application.lastModifiedUser || _vm.fieldValue('lastModifiedDatetime')
    } on
    ${_vm.$moment(_vm.fieldValue('lastModifiedDatetime')).format('LLLL')}`,"in-page":"","icon":"send","role":"none"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }