import {
  APPLICATION_TYPE,
  FIELD_TYPE,
  RESOLUTION_TYPE,
  CONFLICT_ALERTING,
  USER_GROUP,
  STREAM,
  CATCHMENT_LEVEL
} from '@/constants'

let schoolsAPI = process.env.VUE_APP_REFDATA_API

import Vue from 'vue'
import UTILS from '@/store/utils'
import API from '@/store/apiUtils'
import REFDATA from '@/helpers/referenceDataHelper'
import CONFLICT from '@/helpers/conflictHelper'
import applicationModel from '@/store/applicationModel'
import router from '@/router'

const isResolvedWithDifferentValue = (resolution) => {
  // Returns true if resolution resulted in the OES value being replaced.
  // Resolving with the ERN value, or a TEXTAREA MERGE, or any kind of
  // LIST-type resolution all result in the OES value being replaced.
  return (
    resolution.type === RESOLUTION_TYPE.AB_ERN ||
    resolution.type === RESOLUTION_TYPE.LIST ||
    resolution.type === RESOLUTION_TYPE.MERGE
  )
}
const retryCountApi = (apiCall, dispatch) => {
  async function retryWithBackoff(retries) {
    try {
      return await Promise.resolve(apiCall)
    } catch (e) {
      if (retries < 3) {
        return retryWithBackoff(retries + 1)
      } else {
        dispatch('setCountApiError', true)
        throw e
      }
    }
  }
  return retryWithBackoff(0)
}

const getPDFURLBase = (applicationType) => {
  switch (applicationType) {
    case APPLICATION_TYPE.Y67T:
      return `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submitted`
    case APPLICATION_TYPE.OUT_OF_AREA:
    default:
      return `${process.env.VUE_APP_API_PI}/v1/ooa/applications/submitted`
  }
}

const copyAddressFromErn = (fieldKey) => {
  let addr = {}
  const modelAddr = window.MODEL.find((field) => field.id === fieldKey)

  if (
    modelAddr &&
    !modelAddr.isConflict &&
    modelAddr.ernValue != null &&
    !modelAddr.isResolved
  ) {
    if (Object.keys(modelAddr.ernValue).length) {
      addr = {
        addressLine1: modelAddr.ernValue.addressLine1,
        addressLine2: modelAddr.ernValue.addressLine2,
        startDate: modelAddr.ernValue.startDate,
        endDate: modelAddr.ernValue.endDate
      }
    }
  }

  return addr
}

const updateOESAddresses = (app) => {
  const resAddr = copyAddressFromErn('family>residentialAddress')
  app.residentialAddress = {
    ...app.residentialAddress,
    ...resAddr
  }
  const corrAddr = copyAddressFromErn('family>correspondenceAddress')
  app.correspondenceAddress = {
    ...app.correspondenceAddress,
    ...corrAddr
  }
  if (app.parentCarers && app.parentCarers.length > 0) {
    for (let i = 0; i < app.parentCarers.length; i++) {
      let pc = app.parentCarers[i]
      if (pc.residentialAddress) {
        const pcAddr = copyAddressFromErn(
          `other>parentCarers[${i}].residentialAddress`
        )
        pc.residentialAddress = {
          ...pc.residentialAddress,
          ...pcAddr
        }
      }
      if (pc.correspondenceAddress) {
        const pcCorrAddr = copyAddressFromErn(
          `other>parentCarers[${i}].correspondenceAddress`
        )
        pc.correspondenceAddress = {
          ...pc.correspondenceAddress,
          ...pcCorrAddr
        }
      }
    }
  }
  if (app.doctorDetails && app.doctorDetails.length > 0) {
    for (let i = 0; i < app.doctorDetails.length; i++) {
      let doc = app.doctorDetails[i]
      if (doc.doctorAddress) {
        const docAddr = copyAddressFromErn(
          `medical>doctorDetails[${i}].doctorAddress`
        )
        doc.doctorAddress = {
          ...doc.doctorAddress,
          ...docAddr
        }
      }
    }
  }
  return app
}

export default {
  setStore(store) {
    // Sets store instance for any helpers that need it
    API.setStore(store)
  },

  set({ commit }, [key, value]) {
    // Sets a value into state
    commit('set', [key, value])
  },

  renderModel({ commit, state }) {
    // Increments the counter to re-render the model (as the model is not reactive)
    commit('set', ['renderModel', state.renderModel + 1])
  },

  setFieldValue({ commit }, [field, value]) {
    // Sets a field value for a specified field (object)
    commit('set', [`application.${field.apiKey}`, value])
  },

  setReferenceData(store, [category, data]) {
    // Adds or updates a category of reference data in the store
    let referenceData = store.state.referenceData || {}
    referenceData[category] = data
    store.commit('set', ['referenceData', referenceData])
  },

  setFocus({ state, commit, dispatch }, modelRow) {
    /*
      Sets focus to the field referenced by modelRow. If that section is collapsed,
      expand it.

      Setting state.focusFieldId will trigger the UI to focus the specified field.
      However focusFieldId might already be set to this field (from a previous
      setFocus), in which case Vue would not detect the change if we set the value
      again. Instead we must clear focusFieldId before setting it. The timeout
      prevents Vue combining these two commits and not updating.
    */

    // If focusing a field inside a collapsed section, expand it...
    if (state.sectionExpandedId !== modelRow.section.id) {
      dispatch('toggleSection', modelRow.section)
    }

    commit('set', ['focusFieldId', null])
    setTimeout(function () {
      commit('set', ['focusFieldId', modelRow.id])
    }, 0)
  },

  showSpinner({ commit }) {
    commit('set', ['showSpinner', true])
  },

  hideSpinner({ commit }) {
    commit('set', ['showSpinner', false])
  },

  showMessageBox({ commit }, options) {
    commit('set', ['messageBox', options])
  },

  setCountApiError({ commit }, apiError) {
    commit('set', ['countApiError', apiError])
  },

  hideMessageBox({ commit }) {
    commit('set', ['messageBox', null])
  },

  showSnackbar({ commit }, options) {
    commit('set', ['snackbar', options])
  },

  hideSnackbar({ commit }) {
    commit('set', ['snackbar', null])
  },

  setCleanApplication({ commit, state }) {
    // Should be set before application data is modified. When applcation data
    // is saved, this clean version is included in the payload so that the API
    // can detect and save only the changed data. This was implemented as part
    // of the performance upgrades, although it is only a band-aid solution.
    commit('set', ['cleanApplication', UTILS.clone(state.application)])
  },

  setCurrentUser({ commit }) {
    let token = API.getToken('idToken')
    if (token) {
      commit('set', ['userName', `${token.givenName} ${token.sn}`])
      //set the user's role and ID
      commit('set', ['userID', `${token.uid}`])

      const userHash = UTILS.md5Hash(token.det_user_guid || token.uid)
      if (userHash) {
        Vue.gtm.trackEvent({
          event: 'login',
          action: 'login',
          category: 'login',
          userID: userHash
        })
      }
    }
  },

  // AJAX actions -----------------------------------------------------------------------

  // Temporary API to retrieve all NSW schools for none-school users: TO DO: combine the schools to reference data.
  // get all Australian schools.
  getAllAuSchools() {
    return new Promise((done) => {
      API.get(schoolsAPI + `/all-schools.json`, true).then((schools) => {
        done(schools)
      })
    })
  },

  // API to retrieve high schools
  getHighSchoolsRefData() {
    return new Promise((done) => {
      API.get(schoolsAPI + `/high_schools.json`, true).then((schools) => {
        done(schools)
      })
    })
  },

  // Temporary API to retrieve all NSW schools for none-school users. The store parameter is passed on for showing any API error message that may occour in the process. All the APIs are called seperately for the considerations of performance.
  getAllSchools() {
    return new Promise((done) => {
      API.get(schoolsAPI + `/oes-pilot-schools.json`, true).then((schools) => {
        done(schools)
      })
    })
  },

  getSettingsEnabledSchools() {
    return new Promise((done) => {
      API.get(schoolsAPI + `/si-settings-enabled-schools.json`, true).then(
        (schools) => {
          done(schools)
        }
      )
    })
  },

  getSchoolsForUser() {
    let allNSWSchools = new Promise((done) => {
      API.get(schoolsAPI + `/oes-pilot-schools.json`, true).then(
        (allSchools) => {
          done(allSchools.data)
        }
      )
    })
    let userSchoolsWithoutFullname = new Promise((done) => {
      API.get(`/applications/schools`, true).then((schools) => {
        done(schools.data)
      })
    })
    return Promise.all([allNSWSchools, userSchoolsWithoutFullname]).then(
      (data) => {
        const allNswSchools = data[0] || []
        const userSchools = data[1] || []
        let userSchoolcodes = userSchools.map((s) => s.schoolCode)
        let schoolsWithFullName = allNswSchools.filter(
          (s) => userSchoolcodes.indexOf(s.schoolCode) > -1
        )

        return schoolsWithFullName
      }
    )
  },
  getUserGroupAndSchools(store) {
    // We have to call '/applications' to get some data to pass to the get user groups and schools api
    return API.get('/applications').then((response) => {
      return store.dispatch('getSchools', response.data.userGroup)
    })
  },
  getSchools(store, userGroup) {
    // Updates the store with user group details and available schools
    return new Promise((done) => {
      if (!store.state.userGroup) {
        store.commit('set', ['userGroup', userGroup])

        store.dispatch('getOesProperties').then((oesProperties) => {
          store.commit('set', ['oesProperties', oesProperties.data])
          done()
        })

        store.dispatch('getSettingsEnabledSchools').then((schools) => {
          store.commit('set', ['settingsEnabledSchools', schools.data])
          done()
        })

        if (userGroup === USER_GROUP.OES_SUPPORT) {
          store.dispatch('getAllSchools').then((schools) => {
            store.commit('set', ['schools', schools.data])
            done()
          })
        } else {
          store.dispatch('getSchoolsForUser').then((schools) => {
            store.commit('set', ['schools', schools])
            let previouslySelectedSchool = JSON.parse(
              localStorage.getItem('selectedSchool')
            )
            if (
              previouslySelectedSchool &&
              previouslySelectedSchool.uid === store.state.userID
            ) {
              store.commit('set', ['selectedSchool', previouslySelectedSchool])
            } else {
              store.commit('set', ['selectedSchool', schools[0]])
            }
            done()
          })
        }
      } else {
        done()
      }
    })
  },

  getApplication(store, [id, schoolCode]) {
    const applicationType = {
      LA: 'getLocalAreaApplication',
      OOA: 'getOutofAreaApplication',
      Y67T: 'getY67TApplication'
    }
    return store.dispatch(applicationType[store.state.applicationType], [
      id,
      schoolCode
    ])
  },

  getReferenceData(store) {
    return new Promise((done) => {
      if (store.state.referenceData) {
        done(store.state.referenceData)
      } else {
        API.get(
          `${process.env.VUE_APP_REFDATA_API}/si-reference-data.json`,
          true
        ).then((response) => {
          done(REFDATA.mapReferenceData(response.data))
        })
      }
    })
  },

  getOesProperties() {
    return new Promise((done) => {
      API.get(schoolsAPI + `/oes-properties.json`, true).then(
        (oesProperties) => {
          done(oesProperties)
        }
      )
    })
  },

  getScholasticYears(store) {
    // Gets scholastic years for current school and maps field
    // names to our standard naming for use in droplists.
    return new Promise((done) => {
      if (
        store.state.referenceData &&
        store.state.referenceData.scholasticYear
      ) {
        done(store.state.referenceData.scholasticYear)
      } else {
        let schoolCode = store.state.application.schoolCode
        API.get(
          `${process.env.VUE_APP_API_PI}/scholasticYr?schoolCode=${schoolCode}`,
          true,
          {}
        )
          .then((response) => {
            done(REFDATA.mapScholasticYears(response.data))
          })
          .catch(() => {
            // Note: Our reference data getter (getters.referenceData) will
            // automatically return all scholastic years if no api data available.
            UTILS.log(
              'SCHOLASTIC YEAR API UNAVAILABLE - All years will be made available'
            )
            done(null)
          })
      }
    })
  },

  saveApplication(store, options) {
    // options.skipValidation - If true, skips validation checks
    return new Promise((done) => {
      if (store.getters.isSpinner) {
        return // Prevents saving while a save is already in progress
      }
      options = options || {}
      store.dispatch('refreshModel')
      if (options.skipValidation || !isApplicationInvalid()) {
        let apiCalls = []
        let applicationID = store.state.application.applicationID

        let app = UTILS.clone(store.state.application)
        app = updateOESAddresses(app)

        store.commit('set', ['application', app])

        let updateApplication = API.put(`/updateapplication/${applicationID}`, {
          newApplication: API.preparePutData(),
          oldApplication: API.preparePutData({
            isOldApplication: true
          })
        })

        apiCalls.push(updateApplication)

        if (
          UTILS.hasArrayWithValues(store.state.application.supportingDocuments)
        ) {
          //Get supporting documents for the application as the update application API doesn't return supportingDocuments
          let getSupportingDocuments = store.dispatch(
            'getLocalAreaSupportingDocs',
            store.state.application.applicationID
          )
          apiCalls.push(getSupportingDocuments)
        }

        Promise.all(apiCalls)
          .then((response) => {
            if (!response[1]) {
              //API is serving an empty object for supporting documents if no docments have been uploaded or only the categories with files, we have to repopulate supporting documents with empty arrays.
              response[0].data.oesApplication.supportingDocuments =
                store.state.cleanApplication.supportingDocuments
            } else {
              response[0].data.oesApplication.supportingDocuments = response[1]
            }
            store.dispatch('displayApplication', response[0].data)
            done()
          })
          .catch(() => {
            // Reverts application data to previous state
            store.commit('set', ['application', store.state.cleanApplication])
            store.dispatch('refreshModel')
            done()
          })

        store.commit('set', ['isEditing', false])
      }
    })

    function isApplicationInvalid() {
      let fieldsToCheck = store.getters.model.filter(
        (modelRow) => modelRow.isInvalid
      )
      if (fieldsToCheck.length) {
        store.dispatch('showMessageBox', {
          html: `<h2>Please check the following:</h2>${getFieldListHtml(
            fieldsToCheck
          )}`,
          onAlways: function () {
            store.dispatch('setFocus', fieldsToCheck[0])
          },
          textConfirm: 'OK',
          icon: 'mdi-exclamation-thick'
        })
        return true
      }
    }

    function getFieldListHtml(modelRows) {
      let fieldListHtml = []
      modelRows.forEach((modelRow) => {
        fieldListHtml.push(
          `<li>${modelRow.section.label} > <strong>${modelRow.label}</strong></li>`
        )
      })
      return '<ul>' + fieldListHtml.join('') + '</ul>'
    }
  },

  sendApplicationToERN(store, showFamilyTreeLinkMessage) {
    const { state, dispatch, getters, commit } = store
    if (getters.isSpinner || getters.totalAlerts || state.isSendingToErn) {
      return // Don't send to ERN if alerts present or an existing process is running with a spinner
    }

    var applicationId = state.application.applicationID
    if (showFamilyTreeLinkMessage) {
      dispatch('showMessageBox', {
        icon: 'mdi-link-off',
        html: `
        <h2>Unmatched parent/carer records found in ERN</h2>
        <div>Unmatched parent/carer records associated with this application have been found in ERN. Please check the <a href="https://education.nsw.gov.au/platoapps/ern/ern-help-screens/family-manager"
        target="_blank">Family Manager quick reference guide</a> and fix any family tree issues before sending this application to ERN.</div>
      `,
        showCloseButton: true,
        textConfirm: 'Send to ERN',
        textCancel: `<a class="linkButton" href="https://education.nsw.gov.au/platoapps/ern/ern-help-screens/family-manager" target="_blank">
        Open Family Manager QRG</a>`,
        onConfirm() {
          dispatch('showMessageBox', {
            icon: 'mdi-lock-outline',
            html: `
              <h2>Send to ERN</h2>
              <div>Applications that have been completed and validated should be sent to ERN. Once sent, the application will be locked and will only be editable in ERN. This action is not reversible.</div>
            `,
            textConfirm: 'Send and lock',
            onConfirm() {
              dispatch('set', ['isSendingToErn', true])
              API.post(
                `/sendtoern/${applicationId}`,
                API.preparePutData({ isSendToErn: true }),
                true // Hide spinner because Send to ERN has its own custom popup
              )
                .then(() => {
                  commit('set', ['sectionExpandedId', null]) // Collapses current section so that "Sent to ERN" status can be seen when record reloads
                  dispatch('getApplication', [applicationId])
                })
                .catch(() => {
                  dispatch('set', ['isSendingToErn', false])
                })
            }
          })
        }
      })
    } else {
      dispatch('showMessageBox', {
        icon: 'mdi-lock-outline',
        html: `
        <h2>Send to ERN</h2>
        <div>Applications that have been completed and validated should be sent to ERN. Once sent, the application will be locked and will only be editable in ERN. This action is not reversible.</div>`,
        textConfirm: 'Send and lock',
        onConfirm() {
          dispatch('set', ['isSendingToErn', true])
          API.post(
            `/sendtoern/${applicationId}`,
            API.preparePutData({ isSendToErn: true }),
            true // Hide spinner because Send to ERN has its own custom popup
          )
            .then(() => {
              commit('set', ['sectionExpandedId', null]) // Collapses current section so that "Sent to ERN" status can be seen when record reloads
              dispatch('getApplication', [applicationId])
            })
            .catch(() => {
              dispatch('set', ['isSendingToErn', false])
            })
        }
      })
    }
  },

  withdrawApplication(store, sendWithdrawEmail) {
    const { dispatch, commit, state, getters } = store
    const application = state.application
    API.post(
      `/withdrawapplication/${application.applicationID}?sendWithdrawEmail=${sendWithdrawEmail}`,
      {
        applicationID: application.applicationID, // oesApplication.applicationId (Mandatory for OES)
        schoolCode: application.schoolCode, // oesApplication.schoolCode (Mandatory for OES)
        scholasticYear: application.scholasticYear, // oesApplication.scholasticYear (Mandatory for OES)
        srn: application.student.srn || null, // oesApplication.student.srn OR ernStudentInfo.student.srn (Optional for OES)
        ernRegistrationRecordNo:
          application.student.registrationRecordNo || null, // oesApplication.student.registrationRecordNo changed by ALP-500
        ernStudentRecordNo: application.student.ernStudentRecordNo || null, // oesApplication.student.ernStudentRecordNo OR ernStudentInfo.student.studentRecordNo (Optional for OES)
        lastModifiedUser: store.state.userID || null, // Currently authenticated user (Mandatory for OES)
        lastModifiedDatetime: application.lastModifiedDatetime || null
      },
      false
    )
      .then(() => {
        dispatch('set', ['applicationWithdrawn', 'withdrawn'])
        commit('set', ['sectionExpandedId', null])
        dispatch('getApplication', [application.applicationID])
      })
      .catch(() => {
        const params = {
          coreWithdrawFailed: true
        }
        if (application.student.srn) {
          params.SRN = application.student.srn
        }
        // For local application
        if (
          UTILS.isNextCalendarYear7LocalApp(application, getters.oesProperties)
        ) {
          dispatch('y67tUnstampedLocalApplication', params)
        }
        // For OOA application
        if (
          UTILS.isNextCalendarYear7OoaApp(application, getters.oesProperties)
        ) {
          dispatch('y67tUnstampedOoaApplication', params)
        }

        dispatch('set', ['openWithdrawModal', false])
      })
  },

  unlinkSrn(store) {
    const { dispatch, commit, state, getters } = store
    const application = state.application

    API.post(
      `/withdrawapplication/unlinksrn/${application.applicationID}`,
      {
        applicationID: application.applicationID, // oesApplication.applicationId (Mandatory for OES)
        schoolCode: application.schoolCode, // oesApplication.schoolCode (Mandatory for OES)
        scholasticYear: application.scholasticYear, // oesApplication.scholasticYear (Mandatory for OES)
        srn: application.student.srn || null, // oesApplication.student.srn OR ernStudentInfo.student.srn (Optional for OES)
        ernRegistrationRecordNo:
          application.student.registrationRecordNo || null, // oesApplication.student.registrationRecordNo changed by ALP-500
        ernStudentRecordNo: application.student.ernStudentRecordNo || null, // oesApplication.student.ernStudentRecordNo OR ernStudentInfo.student.studentRecordNo (Optional for OES)
        lastModifiedUser: store.state.userID || null, // Currently authenticated user (Mandatory for OES)
        lastModifiedDatetime: application.lastModifiedDatetime || null
      },
      false
    )
      .then(() => {
        commit('set', ['sectionExpandedId', null])
        dispatch('getApplication', [application.applicationID])
      })
      .catch(() => {
        const params = {
          SRN: application.student.srn || null,
          coreUnlinkFailed: true
        }
        // For local application
        if (
          UTILS.isNextCalendarYear7LocalApp(application, getters.oesProperties)
        ) {
          dispatch('y67tUnstampedLocalApplication', params)
        }
        // For OOA application
        if (
          UTILS.isNextCalendarYear7OoaApp(application, getters.oesProperties)
        ) {
          dispatch('y67tUnstampedOoaApplication', params)
        }
        dispatch('set', ['openUnlinkSrnModal', false])
      })
  },

  updateAlertResolution(store, [modelRow, newResolution]) {
    // If a newResolution parameter is provided, the resolution will be stored
    // for the specified modelRow. If no resolution is provided, any existing
    // resolution will be removed for the specified modelRow.
    if (store.getters.isSpinner) {
      return
    }
    store.dispatch('setCleanApplication')
    let application = store.state.application
    let alertResolutions = application.alertResolutions
    let originalResolution

    if (newResolution) {
      // Add a new resolution
      alertResolutions.push(UTILS.clone(newResolution))
    } else {
      // Remove a resolution
      let alertResolutionIndex = alertResolutions.findIndex(
        (resolution) => resolution.id === modelRow.id
      )
      if (alertResolutionIndex >= 0) {
        originalResolution = UTILS.clone(alertResolutions[alertResolutionIndex])
        alertResolutions.splice(alertResolutionIndex, 1)
      }
    }
    store.commit('set', ['application.alertResolutions', alertResolutions])
    if (newResolution) {
      resolveData()
    } else if (originalResolution) {
      store.dispatch('undoResolvedData', [modelRow, originalResolution])
    }
    store.dispatch('saveApplication', { skipValidation: true })

    function resolveData() {
      // Replaces current field value with resolved field value if
      // resolving with a different value.
      if (isResolvedWithDifferentValue(newResolution)) {
        store.commit('set', [
          `application.${modelRow.apiKey}`,
          newResolution.resolvedValue
        ])
      }
    }
  },

  getSrnMatches(store, options) {
    // options
    // - onComplete (callback to run when data has been retrieved)
    // - matchSibling (if true, find sibling matches)
    options = options || {}
    var maxResults = 20
    var student = store.state.application.student
    var sibling = store.state.application.siblings[0] || {} // NOTE: Only one sibling is ever submitted through PI
    var isSibling = options.matchSibling
    var params = {
      surName: isSibling ? sibling.siblingsFamilyName : student.familyName,
      givenName: isSibling ? sibling.siblingsFirstName : student.firstName,
      otherName: isSibling ? null : student.otherName,
      gender: isSibling ? sibling.siblingsGenderCode : student.genderCode,
      dateofBirth: isSibling ? sibling.siblingsDOB : student.dateOfBirth,
      invalidateCache: !!student.srnCreatedByOes
    }
    API.get(`/students?` + getParamString(), true).then((matches) => {
      // NOTE: Spinner is hidden by ApplicationSrnSearch.vue when student & sibling
      // requests have completed
      if (options.onComplete) {
        options.onComplete(convertParentsDisplayNameToArray(matches.data))
      }
    })

    function getParamString() {
      var parts = []
      Object.keys(params).forEach((key) => {
        if (params[key]) {
          parts.push(`${key}=${params[key]}`)
        }
      })
      return parts.join('&') + '&maxRecords=' + maxResults
    }

    function convertParentsDisplayNameToArray(srnMatches) {
      // Parent display names currently arrive as a concatenated string, e.g.:
      // "Mother: Jones,  Helen Ann; Father: Jones,  Warwick Ian"

      // We convert this to a parent names array and remove the relationship
      srnMatches.forEach((match) => {
        var parents = []
        if (match.parentsDisplayName) {
          match.parentsDisplayName.split(';').forEach((parent) => {
            var parentWithoutRelationship = parent.split(': ')[1]
            parents.push(parentWithoutRelationship)
          })
        }
        match.parents = parents
      })
      return srnMatches
    }
  },

  createSrn(store, siblingSrn) {
    // Creates SRN for brand new student
    // If siblingSrn is specified, links new SRN to sibling
    var { dispatch, state, getters } = store
    var originalApplication = UTILS.clone(state.application)
    var siblingParam = ''
    dispatch('clearErnRecordLinks')
    dispatch('set', ['application.student.srn', null])
    var payload = API.preparePutData()

    if (siblingSrn) {
      siblingParam = `?siblingSrn=${siblingSrn}`
      payload.siblingSrn = Number(siblingSrn)
    }

    API.post(
      `/createstudent/${state.application.applicationID}${siblingParam}`,
      payload
    )
      .then((response) => {
        dispatch('refreshAfterSrnLinking', response)
        dispatch('showMessageBox', {
          icon: 'mdi-check',
          message: 'SRN created and linked successfully'
        })
        const application = state.application
        const params = { SRN: getters.srnCreatedByOes }

        if (
          UTILS.isNextCalendarYear7LocalApp(application, getters.oesProperties)
        ) {
          store.dispatch('y67tUnstampedLocalApplication', params)
        }
        if (
          UTILS.isNextCalendarYear7OoaApp(application, getters.oesProperties)
        ) {
          dispatch('y67tUnstampedOoaApplication', params)
        }
      })
      .catch(() => {
        dispatch('set', ['application', originalApplication]) // Reverts application data to previous state
      })
  },

  y67tCoreLinkUnlinkSrn({ dispatch }, params) {
    const { sendWithdrawEmail, ...payload } = params
    return dispatch('y67tUnstampedLocalApplication', payload)
      .then(() => {
        if (params.unlinkSRN) {
          dispatch('unlinkSrn')
        } else if (params.applicationStatus === 'Withdrawn') {
          dispatch('withdrawApplication', sendWithdrawEmail)
        } else {
          dispatch('linkSrn', params.SRN)
        }
      })
      .catch((error) => {
        if (
          !params.unlinkSRN &&
          params.applicationStatus !== 'Withdrawn' &&
          error.response.data.body.description !==
            'LHS applicationStatus is already in Submitted status'
        ) {
          params.unlinkSRN = true
          return dispatch('y67tCoreLinkUnlinkSrn', params)
        }
        return Promise.reject(error)
      })
  },

  y67tOoaLinkUnlinkSrn({ dispatch }, params) {
    const { sendWithdrawEmail, ...payload } = params
    return dispatch('y67tUnstampedOoaApplication', payload)
      .then(() => {
        if (params.unlinkSRN) {
          dispatch('unlinkSrn')
        } else if (params.applicationStatus === 'Withdrawn') {
          dispatch('withdrawApplication', sendWithdrawEmail)
        } else {
          dispatch('linkSrn', params.SRN)
        }
      })
      .catch((error) => {
        if (
          !params.unlinkSRN &&
          params.applicationStatus !== 'Withdrawn' &&
          error.response.data.body.description !==
            'LHS applicationStatus is already in Submitted status'
        ) {
          params.unlinkSRN = true
          return dispatch('y67tOoaLinkUnlinkSrn', params)
        }
        return Promise.reject(error)
      })
  },

  y67tUnstampedLocalApplication({ getters, dispatch }, params) {
    const schoolCode = getters.selectedSchool?.schoolCode
    return API.put(
      `${process.env.VUE_APP_API_PI}/v1/y67t/application/${schoolCode}/${getters.application.applicationID}/unstampedLocalApplication`,
      params,
      false,
      { schoolCode }
    ).catch((error) => {
      dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>Error: Unable to complete request</h2>${
          error?.response?.data?.body?.description ||
          error?.response?.data?.message
        }`
      })
      return Promise.reject(error)
    })
  },

  y67tUnstampedOoaApplication({ getters, dispatch }, params) {
    const schoolCode = getters.selectedSchool?.schoolCode
    const applicationID = getters.application?.applicationID
    return API.put(
      `${process.env.VUE_APP_API_PI}/v1/y67t/application/${schoolCode}/${applicationID}/unstampedOoaApplication`,
      params,
      false,
      { schoolCode }
    ).catch((error) => {
      dispatch('showMessageBox', {
        icon: 'priority_high',
        html: `<h2>Error: Unable to complete request</h2>${
          error?.response?.data?.body?.description ||
          error?.response?.data?.message
        }`
      })
      return Promise.reject(error)
    })
  },
  linkSrn(store, srn) {
    // Links current application to a specified SRN
    var { dispatch, state, getters } = store
    return new Promise((resolve) => {
      var originalApplication = UTILS.clone(state.application)
      dispatch('set', ['application.student.srn', Number(srn)])
      dispatch('set', ['application.student.createdByOes', 'N'])
      dispatch('clearErnRecordLinks') // Allows auto-linking to run again for newly linked srn
      var payload = API.preparePutData()
      API.post(
        `/createstudent/${state.application.applicationID}?srn=${srn}`,
        payload
      )
        .then((response) => {
          dispatch('validateAddressForSRN', srn)
          dispatch('refreshAfterSrnLinking', response)
          resolve()
        })
        .catch((error) => {
          const application = state.application
          const params = { SRN: srn, unlinkSRN: true }

          if (
            UTILS.isNextCalendarYear7LocalApp(
              application,
              getters.oesProperties
            )
          ) {
            store.dispatch('y67tCoreLinkUnlinkSrn', params)
          }

          if (
            UTILS.isNextCalendarYear7OoaApp(application, getters.oesProperties)
          ) {
            store.dispatch('y67tOoaLinkUnlinkSrn', params)
          }

          if (error.response.status === 422) {
            dispatch('showMessageBox', {
              icon: 'mdi-exclamation-thick',
              html: `<h2>Unable to link SRN</h2>The selected ERN student is already linked to another OES application.`
            })
          }
          dispatch('set', ['application', originalApplication]) // Reverts application data to previous state
        })
    })
  },

  validateAddressForSRN({ dispatch, getters }, srn) {
    API.get(`/student/${srn}/address`, true).then((resp) => {
      const ernAddress = resp.data.residentialAddress
      const address = getters.application
        ? getters.application.residentialAddress
        : null

      if (address && ernAddress) {
        let eoiAddressString = [
          address.addressLine1,
          address.suburbName,
          address.stateCode,
          address.postCode,
          address.countryCode
        ].join(' ')
        eoiAddressString = CONFLICT.normaliseAddress(eoiAddressString)

        let ernAddressString = [
          ernAddress.addressLine1,
          ernAddress.addressLine2,
          ernAddress.suburbName,
          ernAddress.stateCode,
          ernAddress.postCode,
          ernAddress.countryCode
        ].join(' ')
        ernAddressString = CONFLICT.normaliseAddress(ernAddressString)

        return dispatch('matchAddress', [eoiAddressString, ernAddressString])
      }
    })
  },

  matchAddress(store, [eoiAddr, ernAddr]) {
    store.commit('set', ['isAddressConflict', eoiAddr !== ernAddr])
    if (eoiAddr === ernAddr) {
      store.commit('set', ['setReadyToSendErn', true])
      store.commit('set', ['isAddressMatched', true])
    }
  },

  refreshAfterSrnLinking({ state, dispatch, getters }, response) {
    // After SRN linking/creating, reloads the application from the returned
    // data. If alerts are now present, save the application to update the
    // 'alertsFound' flag.
    dispatch('setCleanApplication')
    dispatch('displayApplication', response.data)
    let alertsFound = getters.totalAlerts > 0
    if (alertsFound !== state.cleanApplication.alertsFound) {
      dispatch('saveApplication', { skipValidation: true })
    }
  },

  // APPLICATION ACTIONS -----------------------------------------------------------------------

  refreshModel(store) {
    let { state, dispatch, getters } = store

    applicationModel.create(state.application, state.ernRecord, store)

    // Unnecessary for OoA applications (for v1), withdrawn applications and those sent to ERN
    if (
      state.applicationType === 'LA' &&
      !getters.isSentToErn &&
      !getters.isWithdrawn
    ) {
      dispatch('undoObsoleteResolutions') // Check if any resolved fields have since changed in ERN
      dispatch('alertFamilyTreeChanges')
    }
  },

  toggleSection({ commit, state }, section) {
    commit('set', [
      'sectionExpandedId',
      section.id !== state.sectionExpandedId ? section.id : null
    ])
  },

  editApplication({ commit, dispatch, getters }, modelRowToFocus) {
    window.OES.initialFocusField = modelRowToFocus ? modelRowToFocus.id : null
    if (!getters.isEditing) {
      dispatch('setCleanApplication')
    }
    commit('set', ['isEditing', true])
    if (modelRowToFocus) {
      dispatch('setFocus', modelRowToFocus)
    }
  },

  cancelApplication({ commit, state, dispatch }) {
    commit('set', ['application', state.cleanApplication])
    commit('set', ['cleanApplication', null])
    commit('set', ['isEditing', false])
    dispatch('refreshModel')
  },

  displayApplication(store, application) {
    // Takes the supplied application data and:
    // 1. Auto-links records
    // 2. Sets application data into state
    // 3. Builds alerts and renders the UI
    var oesRecord = application.oesApplication
    var ernRecord = application.ernStudentInfo

    // API Mis-design: Can't rely on keys, so rely on state.
    if (store.state.applicationType === 'OOA') {
      oesRecord = application.application

      oesRecord.ooa = true
      oesRecord.applicationID = application.applicationID
      oesRecord.alertResolutions = []
      oesRecord.alerts = {}

      ernRecord = {}
    }
    if (store.state.applicationType === 'Y67T') {
      oesRecord = application.application
      oesRecord.applicationID = application.eoiID
      oesRecord.schoolCode = application.schoolCode
      oesRecord.dateReceived = application.dateReceived
      oesRecord.alertResolutions = []
      oesRecord.alerts = {}
      ernRecord = {}
    }

    if (!Object.keys(ernRecord).length) {
      ernRecord = null
    }

    // why is the api returning an object all of a sudden?
    if (
      !oesRecord.alertResolutions ||
      !Object.keys(oesRecord.alertResolutions).length
    ) {
      oesRecord.alertResolutions = []
    }
    store.commit('set', ['ernRecord', ernRecord])
    store.commit('set', ['application', oesRecord])
    store.dispatch('refreshModel')
  },

  undoResolvedData(store, [modelRow, resolution]) {
    // Reinstates original field value if it was changed by resolution...
    if (isResolvedWithDifferentValue(resolution)) {
      store.commit('set', [
        `application.${modelRow.apiKey}`,
        getOriginalValue()
      ])
    }

    function getOriginalValue() {
      var originalValue = resolution.originalValue
      if (resolution.type === RESOLUTION_TYPE.LIST) {
        // To undo a LIST-type resolution we must revert all records to their original
        // state. HOWEVER if any OES records were discarded when resolving, these would
        // have been deleted from the database. Therefore when we reinstate them, we must
        // clear their record numbers so that they get recreated.
        let oesRecordNumberField = modelRow.field.oesRecordNumberField
        originalValue.forEach((originalRecord) => {
          let isDiscardedOesRecord = !resolution.resolvedValue.find(
            (resolvedRecord) =>
              resolvedRecord[oesRecordNumberField] ===
              originalRecord[oesRecordNumberField]
          )
          if (isDiscardedOesRecord) {
            // Clear record number of discarded OES record so it can be reinstated
            delete originalRecord[oesRecordNumberField]
          }
        })
      } else if (
        resolution.type === RESOLUTION_TYPE.AB_ERN &&
        modelRow.field.conflictAlerting === CONFLICT_ALERTING.AB_GROUP
      ) {
        // Any child addresses also need their record numbers clearing
        clearRecordNumberOfChildAddresses(originalValue)
      }
      return originalValue
    }

    function clearRecordNumberOfChildAddresses(record) {
      try {
        // Check all fields for an address object, then clear any address record numbers
        Object.keys(record).forEach((key) => {
          if (record[key].addressRecordNo) {
            delete record[key].addressRecordNo
          }
        })
      } catch (e) {
        // No child address records found
      }
    }
  },

  undoConflictResolutions(store, modelRowIds) {
    // Undoes all conflict resolutions. Note that 'saveApplication' still
    // needs to be dispatched to save these changes.
    // modelRowIds - If specified, only model row ids in this array will be undone

    var resolvedRows = store.getters.model.filter(
      (modelRow) =>
        modelRow.resolution &&
        (!modelRowIds || modelRowIds.indexOf(modelRow.id) >= 0)
    )

    // Return each resolved OES value to its original state...
    resolvedRows.forEach((modelRow) => {
      store.dispatch('undoResolvedData', [modelRow, modelRow.resolution])
    })

    // Remove the resolution records...
    store.commit('set', [
      'application.alertResolutions',
      store.state.application.alertResolutions.filter((resolution) => {
        let isFilterOut =
          resolution.type !== RESOLUTION_TYPE.IGNORE &&
          (!modelRowIds || modelRowIds.indexOf(resolution.id) >= 0)
        return !isFilterOut
      })
    ])
  },

  clearErnRecordLinks({ state, getters }) {
    // Clears all OES to ERN record linking from the current application
    // (state.application). This is necessary prior to changing the linked SRN.
    if (state.application) {
      getters.model.forEach((modelRow) => {
        // Clear links in all records that use linking (ernRecordNumberField).
        // Note that unmatched ERN records are excluded because their data only
        // lives in the model and not in "state.application".
        if (
          modelRow.type === FIELD_TYPE.RECORD &&
          modelRow.field.ernRecordNumberField &&
          !modelRow.isInsideUnmatchedErnRecord
        ) {
          eval(
            `delete state.application.${modelRow.apiKey}.${modelRow.field.ernRecordNumberField}`
          )
        }
      })
    }
  },

  undoObsoleteResolutions({ getters, dispatch }) {
    // Warns if any resolved fields have since changed in ERN. If so, the
    // resolutions are now obsolete. They must be undone so that the original
    // OES values can be recompared with the new ERN values.

    if (getters.isEditing) {
      return
    }

    let obsoleteRows = getters.model.filter((row) => row.isResolutionObsolete)
    let obsoleteRowsHtml = obsoleteRows
      .map(
        (row) =>
          `<li>${row.section.label} > <strong>${UTILS.sanitize(
            row.label
          )}</strong></li>`
      )
      .join('')
    let obsoleteRowIds = obsoleteRows.map((row) => row.id)
    if (obsoleteRows.length) {
      dispatch('showMessageBox', {
        icon: 'mdi-exclamation-thick',
        textConfirm: `Undo ${obsoleteRows.length} resolution${
          obsoleteRows.length === 1 ? '' : 's'
        }`,
        html: `
          <h2>ERN information has changed</h2>
          <p>The following information must be resolved again because values have changed in ERN:</p>
          <ul>${obsoleteRowsHtml}</ul>
        `,
        onAlways() {
          dispatch('setCleanApplication')
          dispatch('undoConflictResolutions', obsoleteRowIds)
          dispatch('saveApplication', { skipValidation: true })
        }
      })
    }
  },

  alertFamilyTreeChanges({ state, getters, dispatch }) {
    // Warns if any parent carer has had their "enrolment owner" status changed
    // by comparing the OES isEnrolmentOwner flag with the ERN record. If a change
    // is detected, a message box will warn the user and allow them to navigate to
    // ERN to make family tree changes (via an iFrame popup).

    if (getters.isEditing || !state.ernRecord) {
      return
    }

    let oesParents = state.application.parentCarers || []
    let ernParents = state.ernRecord.parentCarers || []
    let changedParentsHtml = ''
    oesParents.forEach((oesParent) => {
      let linkedErnParent = ernParents.find(
        (ernParent) =>
          ernParent.ernParentRecordNo === oesParent.ernParentRecordNo
      )
      if (
        linkedErnParent &&
        linkedErnParent.isEnrolmentOwner !== oesParent.isEnrolmentOwner
      ) {
        let parentName = `${oesParent.parentCarerTitle} ${oesParent.parentCarerGivenName} ${oesParent.parentCarerFamilyName}`
        changedParentsHtml += `<li><strong>${UTILS.sanitize(
          parentName
        )}</strong></li>`
      }
    })
    if (changedParentsHtml) {
      dispatch('showMessageBox', {
        icon: 'mdi-exclamation-thick',
        textConfirm: `Go to ERN`,
        html: `
          <h2>Please update the ERN Family Tree</h2>
          <p>Changes have been detected in the following parent:</p>
          <ul>${changedParentsHtml}</ul>
        `,
        onConfirm() {
          dispatch('openErnFamilyTree')
          dispatch('showMessageBox', {
            message: 'Please refresh this page after making any changes in ERN.'
          })
        }
      })
    }
  },

  openErnFamilyTree({ state }) {
    // Launch ERN family tree in new tab

    let ernUrl = process.env.VUE_APP_FAMILY_TREE_API+'?command=showStudentDetails&functionSelected=familyManagerPopup'+
    '&studentRecordNo='+state.application.student.ernStudentRecordNo+
    '&registrationRecordNo='+state.application.student.registrationRecordNo    
     var _newWindow = window.open(ernUrl, '_blank')
     _newWindow.document.title = "NSW Public Schools"

  },

  async refreshTokens() {
    const refreshToken = sessionStorage.getItem('refreshToken')
    if (refreshToken) {
      const { id_token, refresh_token, access_token } =
        await this._vm.$auth.DET.refreshTokens(refreshToken)
      if (id_token) {
        sessionStorage.setItem('idToken', id_token)
      }
      if (access_token) {
        sessionStorage.setItem('accessToken', access_token)
      }
      if (refresh_token) {
        sessionStorage.setItem('refreshToken', refreshToken)
      }
      return !!id_token
    } else {
      throw 'No refresh token was found'
    }
  },

  logout() {
    router.push('logout')
  },

  reauthenticateUser() {
    this._vm.$auth.DET.startAuth()
  },

  beginFinalInactivityTimer({ commit }) {
    this._vm.$activityMonitor.deactivate()
    commit('set', ['showTimeoutBanner', true])
  },

  showOrDownloadDocument({ dispatch }, document) {
    dispatch('getSignedDocUrl', document)
      .then((response) => {
        window.open(
          response.data.body.Url,
          document.previewMode === 'INLINE' ? '_blank' : '_self'
        )
      })
      .catch(() => {
        dispatch('showMessageBox', {
          icon: 'mdi-exclamation-thick',
          html: `<h2>This file could not be opened</h2>Please try again later`
        })
      })
  },

  getSignedDocUrl({ state }, document) {
    let filePayload = API.getSignedURLPayload(
      document.filePath,
      document.previewMode
    )
    let schoolCode = state.application.schoolCode
    return API.post(
      `${process.env.VUE_APP_API_PI}/application/self/downloads/generateSignedUrl/${schoolCode}`,
      filePayload,
      false,
      {
        schoolCode: schoolCode //header required for downloads API
      }
    )
  },

  getApplicationPDF(_, { applicationId, schoolCode, applicationType }) {
    const urlBase = getPDFURLBase(applicationType)
    const school = _.getters.selectedSchool
    const currentStream = _.getters.currentStream
    let centralSchoolParam = ''
    if (
      currentStream === STREAM.Y67T_PRIMARY &&
      school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL
    ) {
      centralSchoolParam = '?schoolType=centralPrimarySchool'
    }
    return API.get(
      `${urlBase}/${schoolCode}/${applicationId}/download${centralSchoolParam}`,
      false,
      { schoolCode },
      null,
      true
    ).then((response) => response.data)
  },

  getAppsDocStatus(_, { applicationsInfo, schoolCode, applicationType }) {
    const urlBase = getPDFURLBase(applicationType)
    const school = _.getters.selectedSchool
    const currentStream = _.getters.currentStream
    let idsKeyName =
      applicationType === APPLICATION_TYPE.Y67T ? 'eoiIds' : 'applications'
    let payload = { [idsKeyName]: applicationsInfo }
    if (
      currentStream === STREAM.Y67T_PRIMARY &&
      school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL
    ) {
      payload.schoolType = 'centralPrimarySchool'
    }
    return API.post(
      `${urlBase}/${schoolCode}/documentScanStatus`,
      payload,
      true,
      { schoolCode }
    ).then((response) => response.data.body)
  },

  getSupportingDocumentsPDFs(
    _,
    { downloadApplications, schoolCode, applicationType }
  ) {
    const urlBase = getPDFURLBase(applicationType)
    const school = _.getters?.selectedSchool
    const currentStream = _.getters?.currentStream
    let idsKeyName =
      applicationType === APPLICATION_TYPE.Y67T ? 'eois' : 'applications'
    let payload = { [idsKeyName]: downloadApplications }
    if (
      currentStream === STREAM.Y67T_PRIMARY &&
      school?.catchmentLevel === CATCHMENT_LEVEL.CENTRAL
    ) {
      payload.schoolType = 'centralPrimarySchool'
    }
    applicationType === APPLICATION_TYPE.LOCAL_AREA
      ? (payload.local = true)
      : ''
    return API.post(`${urlBase}/${schoolCode}/download`, payload, true, {
      schoolCode
    }).then((response) => response.data)
  },

  getApplicationCounts({ getters, commit, dispatch }) {
    if (!getters.selectedSchool) {
      return Promise.resolve()
    }
    const schoolCode = getters.selectedSchool.schoolCode
    const countApiResponse = API.get(
      `${process.env.VUE_APP_API_PI}/v1/applications/count/${schoolCode}`,
      false,
      { schoolCode: schoolCode },
      null,
      true
    ).then((response) => {
      const data = { ...response.data.body }
      dispatch('setCountApiError', false)
      return commit('set', ['applicationCounts', data])
    })
    return retryCountApi(countApiResponse, dispatch)
  }
}
