import Vue from 'vue'
import API from '@/store/apiUtils'
import FIELD from '@/helpers/fieldHelper'
import allScholasticYears from '@/store/allScholasticYears'
import { STREAM, APPLICATION_OFFER_TYPE_NAME, STATUS } from '@/constants'
import { searchFilter } from '@/helpers/searchHelper'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import router from '@/router'

const checkStartDateInsideRange = (startDateFilter, intendedStartDate) => {
  const start = moment(startDateFilter[0])
  const end = moment(startDateFilter[1])
  const startDate = moment(intendedStartDate)

  return start <= startDate && startDate <= end
}

const filterApps = (
  applications,
  search,
  statusFilter,
  scholasticYearFilter,
  startYearFilter,
  startDateFilter,
  applicationTypeFilter,
  intakeFilter,
  srnLinkFilter
) => {
  return applications.filter((a) => {
    const isName =
      (search || '') !== '' ? searchFilter(a.name.split(', '), search) : true
    const isStatus =
      statusFilter && statusFilter.length
        ? statusFilter.includes(a.applicationStatus)
        : true
    const isScholasticYear =
      scholasticYearFilter && scholasticYearFilter.length
        ? scholasticYearFilter.includes(a.scholasticYear)
        : true
    let isStartDate = true
    if (startYearFilter && startYearFilter.length) {
      isStartDate = startYearFilter.includes(
        new Date(a.intendedStartDate).getFullYear().toString()
      )
    } else if (startDateFilter && startDateFilter.length) {
      isStartDate = checkStartDateInsideRange(
        startDateFilter,
        a.intendedStartDate
      )
    }
    let itemApplicationType
    if (a.ooaApplicationNo && a.ooaApplicationNo.length > 0) {
      itemApplicationType = APPLICATION_OFFER_TYPE_NAME.OUT_OF_AREA_OFFER
    } else if (a.eoiID && a.eoiID.length > 0) {
      itemApplicationType = APPLICATION_OFFER_TYPE_NAME.Y67T_OFFER
    } else {
      itemApplicationType = APPLICATION_OFFER_TYPE_NAME.LOCAL_AREA_OFFER
    }
    const isApplicationType =
      applicationTypeFilter && applicationTypeFilter.length
        ? applicationTypeFilter.includes(itemApplicationType)
        : true
    const isIntake =
      intakeFilter && intakeFilter.length
        ? intakeFilter.includes(a.inArea === true ? 'In area' : 'Out-of-area')
        : true
    const isSrnLinked =
      srnLinkFilter && srnLinkFilter.length
        ? srnLinkFilter.includes(a.srn ? 'Linked' : 'Unlinked')
        : true
    return (
      isName &&
      isStatus &&
      isScholasticYear &&
      isStartDate &&
      isIntake &&
      isSrnLinked &&
      isApplicationType
    )
  })
}

const applicationsModule = {
  state: {
    localAreaApplications: {},
    searchApplicationsFilter: '',
    statusFilter: [],
    scholasticYearFilter: [],
    startYearFilter: [],
    startDateFilter: [],
    applicationTypeFilter: [],
    intakeFilter: [],
    srnLinkFilter: [],
    defaultPage: 50
  },
  getters: {
    localAreaApplications: (state) => (schoolId) => {
      return state.localAreaApplications[schoolId] || []
    },
    searchApplicationsFilter: (state) => state.searchApplicationsFilter,

    // processed
    processedApplications: (state, getters) => (schoolId) => {
      return getters
        .localAreaApplications(schoolId)
        .filter((a) => a.isProcessed)
    },
    processedApplicationsFiltered: (state, getters) => (schoolId) => {
      const applications = getters.processedApplications(schoolId)
      return filterApps(
        applications,
        state.searchApplicationsFilter,
        state.statusFilter,
        state.scholasticYearFilter,
        state.startYearFilter,
        state.startDateFilter,
        state.applicationTypeFilter,
        state.intakeFilter,
        state.srnLinkFilter
      )
    },

    // pending
    pendingApplications: (state, getters) => (schoolId) => {
      return getters
        .localAreaApplications(schoolId)
        .filter((a) => !a.isProcessed)
    },
    pendingApplicationsFiltered: (state, getters) => (schoolId) => {
      const applications = getters.pendingApplications(schoolId)
      return filterApps(
        applications,
        state.searchApplicationsFilter,
        state.statusFilter,
        state.scholasticYearFilter,
        state.startYearFilter,
        state.startDateFilter,
        state.applicationTypeFilter,
        state.intakeFilter,
        state.srnLinkFilter
      )
    },

    // list view filters
    statusFilter: (state) => state.statusFilter,
    scholasticYearFilter: (state) => state.scholasticYearFilter,
    startYearFilter: (state) => state.startYearFilter,
    startDateFilter: (state) => state.startDateFilter,
    applicationTypeFilter: (state) => state.applicationTypeFilter,
    intakeFilter: (state) => state.intakeFilter,
    srnLinkFilter: (state) => state.srnLinkFilter
  },
  mutations: {
    setApplications(state, { schoolId, applications }) {
      if (!state.localAreaApplications[schoolId]) {
        // init the object
        // eslint-disable-next-line import/no-named-as-default-member
        Vue.set(state.localAreaApplications, schoolId, [])
      }

      const allApplicationsMapped = applications.map((application) => {
        let item = {}

        let year = allScholasticYears.find(
          (year) => year.value === application.scholasticYear
        )
        item.scholasticYear = year ? year.text : ''

        item.scholasticYearCode = application.scholasticYear
        item.intendedStartDate = application.intendedStartDate

        item.name =
          application.student.surname + ', ' + application.student.firstName

        item.residencyStatus = application.residencyStatus ?? ''

        item.alertsFound =
          application.alertsFound &&
          application.applicationStatus !== STATUS.SENT

        item.applicationStatus = application.applicationStatus
        item.dateReceived = application.dateReceived
        item.applicationID = application.applicationID
        item.studentDOB = application.student.dateOfBirth
        item.lastModifiedDate = application.lastModifiedDate
        item.inArea = application.inArea

        item.isProcessed =
          item.applicationStatus === STATUS.SENT ||
          item.applicationStatus === STATUS.WITHDRAWN

        item.srn = application?.student?.srn
        item.ooaApplicationNo = application.ooaApplicationNo
        item.eoiID = application.eoiID

        if (
          application.ooaApplicationNo &&
          application.ooaApplicationNo.length > 0
        ) {
          item.applicationType = APPLICATION_OFFER_TYPE_NAME.OUT_OF_AREA_OFFER
        } else if (application.eoiID && application.eoiID.length > 0) {
          item.applicationType = APPLICATION_OFFER_TYPE_NAME.Y67T_OFFER
        } else {
          item.applicationType = APPLICATION_OFFER_TYPE_NAME.LOCAL_AREA_OFFER
        }
        return item
      })

      // use Vue.set to trigger updates on object modification
      // eslint-disable-next-line import/no-named-as-default-member
      Vue.set(state.localAreaApplications, schoolId, allApplicationsMapped)
    },
    setApplicationsFilter(state, value) {
      state.searchApplicationsFilter = value
    },
    setStatusFilter(state, value) {
      state.statusFilter = value
    },
    setScholasticYearFilter(state, value) {
      state.scholasticYearFilter = value
    },
    setStartYearFilter(state, value) {
      state.startYearFilter = value
    },
    setStartDateFilter(state, value) {
      state.startDateFilter = value
    },
    setApplicationTypeFilter(state, value) {
      state.applicationTypeFilter = value
    },
    setIntakeFilter(state, value) {
      state.intakeFilter = value
    },
    setSrnLinkFilter(state, value) {
      state.srnLinkFilter = value
    },
    setDefaultPage(state, value) {
      state.defaultPage = value
    }
  },
  actions: {
    getApplications(store) {
      if (!store.getters.selectedSchool) {
        return Promise.resolve()
      }

      const schoolCode = store.getters.selectedSchool.schoolCode
      const schoolCodeParam = `?schoolCode=${schoolCode}`

      return API.get(`/applications${schoolCodeParam}`).then((response) => {
        const applications = response.data.studentApplications.map((obj) => ({
          ...obj,
          ooa: false
        }))

        const data = { ...response.data, studentApplications: applications }
        const allApplications = data.studentApplications

        return store.commit('setApplications', {
          schoolId: schoolCode,
          applications: allApplications
        })
      })
    },
    getLocalAreaApplication(store, [id]) {
      FIELD.setStore(store)

      return new Promise((done) => {
        store.commit('set', ['isEditing', false])
        store.commit('set', ['ernRecord', null])
        store.commit('set', ['application', null])
        store.dispatch('showSpinner')

        // Gets application record...
        const schoolCode = store.getters.selectedSchool.schoolCode
        const currentStream = store.getters.currentStream
        let localAreApplicationEndPoint = '/application/' + id
        let apiHeaders = {}
        if (currentStream === STREAM.ARCHIVE) {
          const applicationType = router.currentRoute.params.type
          localAreApplicationEndPoint = `${process.env.VUE_APP_API_PI}/v1/archived/application/${schoolCode}/${id}?applicationType=${applicationType}`
          apiHeaders.schoolCode = schoolCode
        }
        API.get(localAreApplicationEndPoint, true, apiHeaders).then(
          (response) => {
            let updatedResponse = response.data
            if (currentStream === STREAM.ARCHIVE) {
              updatedResponse = response.data.body
            }
            let allPromises = []

            store.commit('set', ['application', updatedResponse.oesApplication])

            // TODO no catch statements are attached to the following dispatch promises only one has been added to getLocalAreaSupportingDocs for FUS-1918. Further investigation is required to determine if the other dispatches require catch statements.
            let getReferenceData = store.dispatch('getReferenceData')
            allPromises.push(getReferenceData)
            let getScholasticYears = store.dispatch('getScholasticYears')
            allPromises.push(getScholasticYears)

            if (!_isEmpty(updatedResponse.oesApplication.supportingDocuments)) {
              //Get supporting documents for the application as the retrieve application API couldn't handle it
              let getSupportingDocuments = store.dispatch(
                'getLocalAreaSupportingDocs',
                id
              )
              allPromises.push(getSupportingDocuments)
            }

            // Gets all reference data...
            Promise.all(allPromises)
              .then((data) => {
                let referenceData = data[0]
                referenceData.scholasticYear = data[1]
                if (data[2]) {
                  updatedResponse.oesApplication.supportingDocuments = data[2]
                }
                store.commit('set', ['referenceData', referenceData])
              })
              .catch(() => {
                // TODO something if reference data or supporting docs isn't loaded
              })
              .finally(() => {
                store.dispatch('displayApplication', { ...updatedResponse })
                store.dispatch('hideSpinner')
                done()
              })
          }
        )
      })
    },
    getLocalAreaSupportingDocs({ getters }, id) {
      return new Promise((resolve, reject) => {
        // Gets local area application supporting documents
        let schoolCode = getters.application.schoolCode
        API.get(
          `${process.env.VUE_APP_API_PI}/v1/application/${schoolCode}/${id}/documentScanStatus`,
          true,
          { schoolCode }
        )
          .then((response) => {
            resolve(response.data.body.supportingDocuments)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}

export default applicationsModule
