<!--
  Provides the template for the yellow/green bar indicating SRN link status. If linked,
  the bar will turn green and the SRN will be displayed. If sent to ERN, the bar will
  change to reflect this.
-->
<template>
  <div>
    <Alert
      v-if="isWithdrawn"
      type="info"
      text="Withdrawn from submission"
      :subtext="`by ${application.lastModifiedUser || ''} on
        ${$moment(fieldValue('lastModifiedDatetime')).format('LLLL')}`"
      in-page
      icon="mdi-lock-outline"
      data-testid="withdrawn-status-bar"
      class="withdrawnAlert"
      role="none"
    />
    <Alert
      v-else-if="isEhubLinkedStudent && !isSentToErn"
      type="success"
      :text="`Linked SRN: ${studentSrn || ''}`"
      in-page
      icon="link"
      role="none"
    />
    <template v-else-if="!isSentToErn">
      <Alert
        v-if="isLinked"
        type="success"
        :text="`Linked SRN: ${fieldValue('student.srn')}`"
        in-page
        icon="link"
        :action-text="`${showUnlinkSrn ? 'UNLINK SRN' : ''}`"
        data-testid="local-link-status-bar"
        :action-handler="check"
        role="none"
      />
      <Alert
        v-if="!isLinked"
        type="warning"
        text="SRN has not been matched"
        :subtext="`You need to check if ${fieldValue(
          'student.firstName'
        )} ${fieldValue('student.familyName')} has an existing SRN record.`"
        in-page
        action-text="CHECK"
        :action-handler="check"
        data-testid="local-new-status-bar"
        role="none"
      />
    </template>
    <Alert
      v-if="isSentToErn"
      type="success"
      :text="'Locked and sent to ERN'"
      :subtext="`by ${
        application.lastModifiedUser || fieldValue('lastModifiedDatetime')
      } on
      ${$moment(fieldValue('lastModifiedDatetime')).format('LLLL')}`"
      in-page
      icon="send"
      role="none"
    />
  </div>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import { Alert } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
export default {
  name: 'SrnStatusBar',
  mixins: [fieldHelperMixin],
  components: { Alert },
  computed: {
    ...mapGetters(['isEhubLinkedStudent', 'studentSrn']),
    isLinked() {
      return !this.$store.getters.isCheckForSrn
    },
    application() {
      return this.$store.state.application
    },
    isY67TOfferedApp() {
      return !!this.application?.eoiID
    },
    showUnlinkSrn() {
      return this.isLinked && !this.isY67TOfferedApp
    }
  },
  methods: {
    fieldValue(id) {
      // Safely returns values without failing if object is null
      try {
        return eval(`this.$store.state.application.${id}`)
      } catch (e) {
        return ''
      }
    },
    check() {
      if (this.isLinked) {
        this.$store.dispatch('set', ['openUnlinkSrnModal', true])
      } else {
        this.$store.dispatch('set', ['showSrnSearch', true])
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-alert {
  margin: 0 3rem;
}

::v-deep .v-alert__wrapper {
  margin: 5px 18px 0;
}

.withdrawnAlert {
  ::v-deep .v-alert__border {
    background-color: $color-placeholder !important;
    border-color: $color-placeholder !important;
  }

  ::v-deep .v-icon {
    color: $color-placeholder !important;
  }
}
</style>
