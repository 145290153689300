import store from '@/store/store'
import { USER_GROUP, SCHOOL_CAPS, CATCHMENT_LEVEL } from '@/constants'
import _isEqual from 'lodash/isEqual'

export default {
  isSuperAdmin() {
    return store.state.userGroup === USER_GROUP.OES_SUPPORT
  },
  isOverCap(capacity) {
    return capacity === SCHOOL_CAPS.OVER
  },
  isHighSchool(school) {
    return (
      school.catchmentLevel === CATCHMENT_LEVEL.SECONDARY ||
      school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL
    )
  },
  isY67THighSchoolOnly(school) {
    return school.y67t && this.isHighSchool(school) && !this.isOOASchool(school)
  },
  isY67TAndOoaSchool(school) {
    return school.y67t && this.isHighSchool(school) && this.isOOASchool(school)
  },
  isOOASchool(school) {
    const settingsEnabledSchools = store.state.settingsEnabledSchools
    return (
      school.ooa ||
      (settingsEnabledSchools?.length > 0 &&
        settingsEnabledSchools.includes(parseInt(school.schoolCode)))
    )
  },
  getScholasticYears(catchmentLevel) {
    const infants = [
      { value: 'K', label: 'Kindergarten' },
      { value: '1', label: 'Year 1' },
      { value: '2', label: 'Year 2' }
    ]
    const primary = [
      { value: 'K', label: 'Kindergarten' },
      { value: '1', label: 'Year 1' },
      { value: '2', label: 'Year 2' },
      { value: '3', label: 'Year 3' },
      { value: '4', label: 'Year 4' },
      { value: '5', label: 'Year 5' },
      { value: '6', label: 'Year 6' }
    ]
    const secondary = [
      { value: '7', label: 'Year 7' },
      { value: '8', label: 'Year 8' },
      { value: '9', label: 'Year 9' },
      { value: '10', label: 'Year 10' },
      { value: '11', label: 'Year 11' },
      { value: '12', label: 'Year 12' }
    ]
    if (catchmentLevel === CATCHMENT_LEVEL.INFANTS) {
      return infants
    } else if (catchmentLevel === CATCHMENT_LEVEL.PRIMARY) {
      return primary
    } else if (catchmentLevel === CATCHMENT_LEVEL.SECONDARY) {
      return secondary
    } else {
      return primary.concat(secondary)
    }
  },
  checkDuplicateOption(options) {
    if (!options || options.length <= 1) {
      return false
    }

    function compareDuplicateOption(a, b) {
      const isDuplicateScholasticYear =
        a?.scholasticYears.filter((scholasticYear) =>
          b?.scholasticYears.includes(scholasticYear)
        ).length > 0
      return (
        a?.religion === b?.religion &&
        _isEqual(a?.providers.sort(), b?.providers.sort()) &&
        isDuplicateScholasticYear
      )
    }
    const filteredOptions = options.map((option) => {
      return {
        religion: option.religion || option.name,
        providers: option.providers,
        scholasticYears: option.scholasticYears
      }
    })
    if (filteredOptions.length === 0 || filteredOptions.length === 1) {
      return false
    }

    for (let i = 0; i < filteredOptions.length; i++) {
      for (let j = i + 1; j < filteredOptions.length; j++) {
        return compareDuplicateOption(filteredOptions[i], filteredOptions[j])
      }
    }
    return false
  }
}
