<!--
  Provides the template for the application title and summary details at the top of
  the application.
-->
<template>
  <div
    class="ApplicationSummary"
    v-if="model.length && !state.isEditing && !isSentToErn && !isWithdrawn"
  >
    <AdsButton
      class="mr-2 withdrawBtn"
      x-large
      secondary
      @click="openWithdrawDialog"
      button-text="Withdraw"
      icon="remove_circle_outline"
      aria-label="Withdraw"
    />
    <AdsButton
      x-large
      aria-label="Send to ERN"
      :disabled="isSendToErnDisabled"
      @click="sendToERN"
      button-text="Send to ERN"
      icon="mdi-send-outline"
      class="sendToErnButton"
      :loading="state.isSendingToErn && !isSentToErn"
    />
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
export default {
  name: 'ApplicationSummary',
  mixins: [fieldHelperMixin],
  components: { AdsButton },
  computed: {
    state() {
      return this.$store.state
    },
    contact() {
      return this.getValue('parentCarers').find(
        (parent) => parent.isEnrolmentOwner === true
      )
    },
    applicationId() {
      return this.getValue('applicationID')
    },
    isSendToErnDisabled() {
      // Prevent Send to ERN if any outstanding actions, or if no linked SRN.
      // Note: When sending to ERN there will always be a linked SRN, as this
      // will have been created by the SRN Search dialog if there was no existing
      // SRN to link to.
      return (
        this.totalAlerts > 0 ||
        (this.state.application && !this.state.application.student.srn)
      )
    },
    showFamilyTreeLinkMessage() {
      const unmatchedErnRecord = this.model.filter(
        (modelRow) =>
          modelRow.section.id === 'parents' &&
          modelRow.isUnmatchedErnRecord &&
          !this.isWithdrawn
      )
      return unmatchedErnRecord.length > 0
    }
  },
  methods: {
    contactDetails(type) {
      var contactDetails = this.contact.contactDetails || []
      var phone = contactDetails.find((item) => item.contactType === type) || {}
      return phone.contactValue
    },
    sendToERN() {
      this.$store.dispatch(
        'sendApplicationToERN',
        this.showFamilyTreeLinkMessage
      )
    },
    openWithdrawDialog() {
      this.$store.dispatch('set', ['openWithdrawModal', true])
      this.$store.dispatch('set', ['applicationWithdrawn', 'pending'])
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationSummary {
  padding: 1.5rem 3rem 1.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;

  .summary {
    flex: 1;
    min-width: 350px;

    h1 {
      margin-top: 0;
      font-size: 1.5rem;
      font-weight: 400;

      span {
        margin-left: 20px;
      }
    }

    .fields {
      td {
        padding-bottom: 1rem;
        vertical-align: top;
      }
      .label {
        color: $color-text-light;
        white-space: nowrap;
        &:after {
          content: ':';
        }
        padding-right: 0.5rem;
      }
      .value {
        color: black;
        padding-right: 3rem;
      }
    }
  }
}
.withdrawBtn,
.sendToErnButton {
  margin: 0;
}

::v-deep .v-btn:not(.v-btn--round).v-size--x-large {
  height: 44px;
}

::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $grey-darken1 !important;
  color: $ads-selection-control-gray !important;
  .v-icon {
    color: $ads-selection-control-gray !important;
  }
}

#app.v-application button.v-btn:not(.v-btn--round).review-btn {
  color: black;
}

#app.v-application button.v-btn.sendToErnButton:focus:before {
  opacity: 0; /* Overrides the opacity of other :focus styles on this specific button */
}
</style>
