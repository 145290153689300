<!-- 
  Provides a dialog for showing possible SRN matches. Choosing one will link the OES
  application to the one in ERN, allowing conflicts to then be highlighted. If "New student"
  is selected, a second step is necessary for picking any existing ERN sibling. This creates
  a new SRN with the correct family relationships. 
-->
<template>
  <div>
    <SrnSearchSiblingDialog
      v-if="displayCreateSiblingSrnDialog"
      :student-record="getStudentDataForSiblingDialog"
      :sibling-record="selectedRecord"
      @cancelLinkSrnForThisSibling="cancelLinkSrnForThisSibling"
      @linkSrnForThisSibling="linkSrnForThisSibling"
      @createSrnForThisStudent="createSrnForThisStudent"
    />
    <AppModal
      v-if="studentMatches && !displayCreateSiblingSrnDialog"
      @close="cancel()"
      ref="AppModal"
      icon="search"
      max-width="950px"
    >
      <span slot="header">{{
        showSiblings ? 'Find sibling in ERN' : 'Find student in ERN'
      }}</span>
      <div slot="body" class="content">
        <!-- Step 1: Student SRN search -->

        <!-- 
          Note: Transition fade between steps is necessary to emphasize the change in content. 
          Otherwise it can look like nothing has happened because steps 1 & 2 display very similar 
          lists.  
        -->
        <transition name="fade">
          <div v-if="!showSiblings">
            <SrnSearchRecord
              :record="getStudentData"
              :disabled="true"
              label="Current student"
            />
            <fieldset class="srnMatchesFieldset">
              <legend>
                <h3>Closest matches in ERN ({{ studentMatches.length }}):</h3>
              </legend>
              <div class="scroll">
                <div v-for="(record, index) in studentMatches" :key="index">
                  <SrnSearchRecord
                    :record="record"
                    :compare="getStudentData"
                    :is-linked="record.srn == currentSrn"
                    :is-selected="
                      selectedRecord && selectedRecord.srn === record.srn
                    "
                    @click="selectRecord(record)"
                    label="Student"
                  />
                </div>
                <button
                  v-if="shouldCreateNewStudent"
                  ref="newStudent"
                  type="button"
                  role="checkbox"
                  :aria-checked="isNewStudentSelected"
                  :class="
                    'newStudent unstyled' +
                    (isNewStudentSelected ? ' isSelected' : '')
                  "
                  @click="selectRecord({})"
                >
                  <font-awesome-icon
                    focusable="false"
                    icon="check"
                    class="checkbox"
                  />Create as new student
                </button>
              </div>
            </fieldset>
          </div>
        </transition>

        <!-- Step 2: Sibling SRN search if "New student" selected -->
        <transition name="fade">
          <div v-if="showSiblings">
            <SrnSearchRecord
              :record="getSiblingData"
              :disabled="true"
              label="Current sibling"
            />
            <fieldset class="srnMatchesFieldset">
              <legend>
                <h3>
                  Closest sibling matches in ERN ({{ siblingMatches.length }}):
                </h3>
              </legend>
              <div class="scroll">
                <div v-for="(record, index) in siblingMatches" :key="index">
                  <SrnSearchRecord
                    :record="record"
                    :compare="getSiblingData"
                    :is-linked="record.srn == currentSrn"
                    :is-selected="
                      selectedRecord && selectedRecord.srn === record.srn
                    "
                    @click="selectRecord(record)"
                    label="Student"
                  />
                </div>
                <button
                  type="button"
                  role="checkbox"
                  :aria-checked="isNewStudentSelected"
                  :class="
                    'newStudent unstyled' +
                    (isNewStudentSelected ? ' isSelected' : '')
                  "
                  @click="selectRecord({})"
                >
                  <font-awesome-icon
                    focusable="false"
                    icon="check"
                    class="checkbox"
                  />Create new student without ERN sibling
                </button>
              </div>
            </fieldset>
          </div>
        </transition>
      </div>
      <div slot="footer">
        <AdsButton
          id="v-btn-srn"
          class="primary"
          @click="proceed"
          :disabled="isOkButtonDisabled"
          :button-text="getOkButtonText"
          :aria-label="getOkButtonText"
        />
        <button id="v-btn-cancel" @click="cancel" type="button">
          {{ getCancelButtonText }}
        </button>
      </div>
    </AppModal>
  </div>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue'
import SrnSearchRecord from '@/components/application/SrnSearchRecord'
import SrnSearchSiblingDialog from '@/components/application/SrnSearchSiblingDialog'
import { AdsButton } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import UTILS from '@/store/utils'

export default {
  name: 'ApplicationSrnSearch',
  components: {
    AppModal,
    SrnSearchRecord,
    AdsButton,
    SrnSearchSiblingDialog
  },
  data() {
    return {
      studentMatches: null,
      siblingMatches: null,
      selectedRecord: null,
      showSiblings: false,
      displayCreateSiblingSrnDialog: false
    }
  },
  computed: {
    ...mapGetters({
      oesProperties: 'oesProperties',
      application: 'application'
    }),
    getOkButtonText() {
      if (this.showSiblings) {
        return this.isSiblingSelected ? 'Create sibling SRN' : 'Create SRN'
      }
      if (this.isSiblingSearchAvailable) {
        return 'Find sibling >'
      }
      return this.isNewStudentSelected ? 'Create SRN' : 'Link SRN'
    },

    getCancelButtonText() {
      return this.showSiblings ? '< Back' : 'Cancel'
    },

    isSiblingSearchAvailable() {
      return (
        !this.showSiblings &&
        this.siblingMatches &&
        this.siblingMatches.length &&
        this.isNewStudentSelected
      )
    },

    isSiblingSelected() {
      return this.showSiblings && this.selectedRecord && this.selectedRecord.srn
    },

    isOkButtonDisabled() {
      return (
        !this.selectedRecord ||
        (this.currentSrn && this.selectedRecord.srn == this.currentSrn)
      )
    },

    isNewStudentSelected() {
      return this.selectedRecord && !this.selectedRecord.srn
    },

    getStudentData() {
      // Gets student data from the current application and maps the field names
      // to those returned from the student search, so the two can be compared.
      var app = this.$store.state.application
      return {
        dateOfBirth: app.student.dateOfBirth,
        postcode: app.residentialAddress.postCode,
        firstName: app.student.firstName,
        gender: app.student.genderCode === 'F' ? 'Female' : 'Male',
        parents: this.currentParents,
        schoolNYear: app.prevSchoolName
          ? `${app.prevSchoolName}, Year ${app.scholasticYear}`
          : '',
        surname: app.student.familyName
      }
    },

    getStudentDataForSiblingDialog() {
      const application = this.$store.state.application
      return {
        ...this.getStudentData,
        schoolNYear: `${application.schoolName}, Year ${application.scholasticYear}`,
        residentialAddress: application.residentialAddress
      }
    },

    getSiblingData() {
      // Gets sibling data from the current application and maps the field names
      // to those returned from the sibling search, so the two can be compared.
      var app = this.$store.state.application
      var sibling = app.siblings[0]
      return {
        dateOfBirth: sibling.siblingsDOB,
        postcode: app.residentialAddress.postCode,
        firstName: sibling.siblingsFirstName,
        gender: sibling.siblingsGenderCode === 'F' ? 'Female' : 'Male',
        parents: this.currentParents,
        schoolNYear: sibling.siblingsSchoolName,
        surname: sibling.siblingsFamilyName
      }
    },

    currentSrn() {
      return this.$store.state.application.student.srn
    },

    currentParents() {
      // Gets current parent carer enrolment owner names for displaying at the top of the dialog
      var parents = []
      var enrolmentOwners = this.$store.state.application.parentCarers.filter(
        (parent) => parent.isEnrolmentOwner
      )
      enrolmentOwners.forEach((parent) => {
        parents.push(
          `${parent.parentCarerFamilyName},  ${parent.parentCarerGivenName}`
        )
      })
      return parents
    },

    shouldCreateNewStudent() {
      return (
        !this.$store.state.application.student?.srnCreatedByOes &&
        !this.isY67TOfferedApp
      )
    },

    isY67TOfferedApp() {
      return !!this.$store.state.application?.eoiID
    }
  },

  methods: {
    getStudentMatches() {
      var me = this
      this.$store.dispatch('showSpinner')
      this.$store.dispatch('getSrnMatches', {
        onComplete(matches) {
          me.populatePostcodes(matches)
          me.studentMatches = matches
          // Select current linked record, if present
          var currentLinkedRecord = matches.find(
            (record) => record.srn == me.currentSrn
          )
          if (currentLinkedRecord) {
            me.selectedRecord = currentLinkedRecord
          }
          if (me.siblingMatches) {
            me.$store.dispatch('hideSpinner') // Only hide spinner if all requests completed
          }
        }
      })
    },

    getSiblingMatches() {
      var me = this
      if (this.$store.state.application.siblings[0]) {
        this.$store.dispatch('showSpinner')
        this.$store.dispatch('getSrnMatches', {
          matchSibling: true,
          onComplete(matches) {
            me.populatePostcodes(matches)
            me.siblingMatches = matches
            if (me.studentMatches) {
              me.$store.dispatch('hideSpinner') // Only hide spinner if all requests completed
            }
          }
        })
      } else {
        this.siblingMatches = []
      }
    },

    populatePostcodes(matches) {
      // Extracts postcodes from address as API doesn't provide them separately
      matches.forEach((match) => {
        var parts = match.displayAddress.split(' ')
        parts.forEach((part) => {
          if (part.length === 4 && Number(part) >= 2000) {
            match.postcode = part
          }
        })
      })
    },

    selectRecord(record) {
      this.selectedRecord = record
    },

    async proceed() {
      // If we've gotten to SRN matching on a 6/7 offered app then it has come through with no ERN record
      // but does have an SRN.
      if (this.isY67TOfferedApp) {
        this.$store.dispatch('set', ['showSrnSearch', false])
        this.$store.dispatch('showMessageBox', {
          icon: 'mdi-exclamation-thick',
          html: `<h2>Student already linked</h2> This student has already been linked to another record.`
        })
        return
      }

      if (this.isSiblingSearchAvailable) {
        // Move on to sibling search if "New student" is selected & sibling matches exist.
        // Also ensure modal body is scrolled back to the top
        this.selectedRecord = null
        this.showSiblings = true
        this.$refs.AppModal.$refs.AppModalBody.scrollTop = 0
        return
      }

      if (this.isSiblingSelected) {
        const address = await this.$store
          .dispatch('getErnAddress', this.selectedRecord.srn)
          .then((response) => {
            return response
          })
        this.selectedRecord.residentialAddress = address
        this.displayCreateSiblingSrnDialog = true
        return
      }

      // If resolved conflicts exist, prompt user to undo them all before proceeding
      var me = this
      var resolvedConflicts = this.$store.getters.model.filter(
        (modelRow) => modelRow.isResolved
      )
      if (resolvedConflicts.length) {
        var messageDetail = this.isNewStudentSelected
          ? ''
          : `<div>In order to link this application to a different SRN all resolved conflicts must be undone.</div>`
        this.$store.dispatch('showMessageBox', {
          icon: 'mdi-exclamation-thick',
          html: `<h2>Undo conflict resolutions to proceed</h2>${messageDetail}`,
          textConfirm: 'Undo all',
          onConfirm() {
            me.$store.dispatch('undoConflictResolutions')
            me.linkOrCreateSrn()
          }
        })
      } else {
        this.linkOrCreateSrn()
      }
    },

    linkOrCreateSrn() {
      if (this.$store.state.showSpinner) {
        return // Prevents any possibility of being triggered multiple times
      }

      var isNewSrnSelected = this.isNewStudentSelected || this.isSiblingSelected
      var reuseSrn = this.$store.getters.srnCreatedByOes
      const params = {
        SRN: this.selectedRecord.srn
      }
      if (isNewSrnSelected && !reuseSrn) {
        // Create new SRN...
        // unstampedLocalApplication is called within if applicable
        var siblingSrn = this.isSiblingSelected ? this.selectedRecord.srn : null
        this.$store.dispatch('createSrn', siblingSrn)
        this.$store.dispatch('set', ['showSrnSearch', false])
      } else if (
        // Local application
        UTILS.isNextCalendarYear7LocalApp(
          this.application,
          this.oesProperties
        ) &&
        !isNewSrnSelected
      ) {
        // Call unstampedLocalApplication API before linking to existing SRN...
        // linkSrn is called within
        this.$store.dispatch('y67tCoreLinkUnlinkSrn', params)
        this.$store.dispatch('set', ['showSrnSearch', false])
      } else if (
        // Ooa application
        UTILS.isNextCalendarYear7OoaApp(this.application, this.oesProperties) &&
        !isNewSrnSelected
      ) {
        this.$store.dispatch('y67tOoaLinkUnlinkSrn', params)
        this.$store.dispatch('set', ['showSrnSearch', false])
      } else {
        // Link or reuse SRN...
        var srn = isNewSrnSelected ? reuseSrn : this.selectedRecord.srn
        this.$store.dispatch('linkSrn', srn)
        this.$store.dispatch('set', ['showSrnSearch', false])
      }
    },

    cancel() {
      if (this.showSiblings) {
        // When sibling search is displayed (2nd step of the UI), "Cancel" becomes "Back"
        // and returns to the first step (student SRN search).
        this.selectedRecord = {} // Return highlight to "New student"
        this.showSiblings = false
        var me = this
        // Return focus to "New student". Timeout is necessary to allow UI to update
        // before focus can be set.
        setTimeout(function () {
          me.$refs.newStudent.focus()
        }, 0)
      } else {
        this.$store.dispatch('set', ['showSrnSearch', false])
      }
    },

    cancelLinkSrnForThisSibling() {
      this.displayCreateSiblingSrnDialog = false
    },

    linkSrnForThisSibling(sibling) {
      this.selectRecord(sibling)
      this.linkOrCreateSrn()
    },

    createSrnForThisStudent() {
      this.selectRecord({})
      this.linkOrCreateSrn()
    }
  },

  mounted() {
    this.getStudentMatches()
    this.getSiblingMatches()
  }
}
</script>

<style scoped lang="scss">
.content {
  border-top: 1px solid #d4d4d4;
}
h3 {
  font-size: 1rem;
  margin: 1em 0 0.5em 0;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #d4d4d4;
  font-weight: normal;

  &:first-of-type {
    margin-top: 0;
  }
}
.newStudent {
  width: 100%;
  border-radius: 0.15em !important;
  background-color: $color-student-record-bg !important;
  margin: 0.25em 0 !important;
  text-align: left !important;
  height: 5em;
  padding: 0.5em !important;

  &.isSelected {
    background-color: $color-selected-item !important;
  }
}
.checkbox {
  font-size: 1.5rem;
  width: 1em;
  height: 1em;
  color: white;
  background-color: white;
  border-radius: 1em;
  padding: 0.2em;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.isSelected .checkbox {
  color: $color-primary;
}
.scroll {
  // If enough screen height, make list of matches scrollable
  @media (min-height: 600px) {
    overflow: auto;
    // Makes the list of matches scrollable (viewport height minus height of dialog
    // header, current student details and footer)
    max-height: calc(100vh - 260px);
    // Padding gives space so that the focus ring is not cut off.
    // Negative margin counteracts the padding so size retains the same.
    padding: 0 1em;
    margin: 0 -1em;
  }
}
// Classes for Vue "fade" transition. Transition will fade in main dialog
// content when swapping between UI steps 1 & 2. This is necessary to show
// the content has changed, otherwise it is sometimes hard to see what has happened.
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: none;
}
.fade-enter {
  opacity: 0;
}
.fade-leave {
  display: none;
}
fieldset.srnMatchesFieldset {
  border: none;
  legend {
    width: 100%;
  }
}
#v-btn-srn {
  height: 35px;
}
#v-btn-cancel {
  &:focus {
    text-decoration: underline;
  }
}
</style>
