import { MATCH } from '../constants'
import md5 from 'md5'

function isNextCalendarYear7App(application, oesProperties) {
  if (!application || !oesProperties) {
    return false
  }
  return (
    application.scholasticYear === '7' &&
    application.intendedStartDate?.split('-')[0] ===
      oesProperties.y67application?.activeEOICalendarYear
  )
}

export default {
  log(message) {
    // Logs a console message for dev environments and takes care of disabling linting
    if (process.env.NODE_ENV === 'development') {
      /*eslint-disable */
      console.log(message)
      /*eslint-enable */
    }
  },

  isArray(value) {
    return (
      value !== null && typeof value === 'object' && value.length !== undefined
    )
  },

  isObject(value) {
    return value !== null && typeof value === 'object'
  },

  isEmpty(value) {
    // Note that a value containing only spaces is counted as empty
    return (
      value === null ||
      value === undefined ||
      String(value).trim() === '' ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    )
  },

  clone(obj) {
    var clone = JSON.stringify(obj)
    return JSON.parse(clone)
  },

  removeKeys(obj, valueToRemove) {
    // Remove keys of a specified value from an object
    Object.keys(obj).forEach((key) => {
      if (obj[key] === valueToRemove) {
        delete obj[key]
      }
    })
    return obj
  },

  isMatch(val1, val2, matching) {
    /*
      Returns true if values match. Handles all data types including object comparison
        val1 <any datatype>   - Required: First comparison value
        val2 <any datatype>   - Required: Second comparison value
        matching <string>|<json> - Optional: See below

          <string>: Specifies a matching mode using the MATCH constant (e.g. MATCH.INSENSITIVE)
                    Not applicable when comparing objects.

          <json>:   Specifies matching criteria, i.e. which fields to match and what matching
                    to use. val1 & val2 must be json containing the keys in the criteria. E.g.
                    {
                      someFieldName: MATCH.EXACT,
                      anotherFieldName: MATCH.INSENSITIVE
                    }
    */

    if (this.isObject(matching)) {
      // If matching criteria has been passed in, match on multiple fields...
      let isMatch = true
      Object.keys(matching).forEach((key) => {
        if (!this.isMatch(val1[key], val2[key], matching[key])) {
          isMatch = false
        }
      })
      return isMatch
    } else if (this.isObject(val1)) {
      // If comparison values are objects, perform object comparison...
      return JSON.stringify(val1) === JSON.stringify(val2)
    } else if (matching === MATCH.INSENSITIVE) {
      return val1.toLowerCase() === val2.toLowerCase()
    } else if (matching === MATCH.FUZZY) {
      return this.isFuzzyMatch(val1, val2)
    }
    return val1 === val2
  },

  isFuzzyMatch(val1, val2) {
    // Loosely compares two strings by ignoring case, whitespace and symbols.
    return clean(val1) === clean(val2)

    function clean(value) {
      if (typeof value == 'string') {
        return value.toLowerCase().replace(/\W/g, '')
      }
      return value
    }
  },

  sanitize(userText) {
    // Neutralises any malicious user content by removing '<' chrs if
    // the string contains both '<' and '>' chrs. If there is a valid
    // use-case for user content to contain both these characters, then
    // this function should not be used in these cases.
    if (userText.indexOf('<') >= 0 && userText.indexOf('>') > 0) {
      return userText.replace(/</g, '')
    }
    return userText
  },

  trimFrom(sourceText, searchString, useLastOf, offset) {
    // Trims content from the end of a string, starting at the index of searchString

    // UTILS.trim('allergies[0].medications[0].dosage', 'FROM', '.', true)
    // Returns: 'allergies[0].medications[0]'

    // sourceText - String to be trimmed
    // searchString - String to search for
    // useLastOf - Optional bool: If true, use the last occurance of searchString
    // offset - Optional int: Moves the trim position
    var index = useLastOf
      ? sourceText.lastIndexOf(searchString)
      : sourceText.indexOf(searchString)
    if (index === -1) {
      return sourceText
    }
    return sourceText.substr(0, index + (offset || 0))
  },

  getListDivider(list) {
    // Gets a string to be used as a list divider, based on the
    // current longest value in the list.
    var maxItemLength = this.maxListItemLength(list, 'text')
    return '—'.repeat(maxItemLength / 2)
  },

  maxListItemLength(jsonArray, jsonKey) {
    // Iterates through a JSON array and calculates the longest string used
    // for a given column (specified by jsonKey)
    return Math.max(
      ...jsonArray.map((el) => {
        try {
          return el[jsonKey].length
        } catch (e) {
          return 0
        }
      })
    )
  },

  isChildWindow() {
    try {
      return window.opener.location.hostname === window.location.hostname
    } catch (e) {
      return false
    }
  },

  toFullNameString(...names) {
    return names.join(' ').trim()
  },
  hasArrayWithValues(obj) {
    let arrays = Object.values(obj)
    const reducer = (acc, cur) => acc + cur.length
    return arrays.reduce(reducer, 0)
  },
  md5Hash(str) {
    return str ? md5(str) : null
  },
  isValidPhoneNumber(val) {
    return /^[0-9 ()+-]+$/.test(val)
  },

  isNextCalendarYear7LocalApp(application, oesProperties) {
    return (
      isNextCalendarYear7App(application, oesProperties) &&
      application?.isInArea === true &&
      !application?.eoiID
    )
  },

  isNextCalendarYear7OoaApp(application, oesProperties) {
    return (
      isNextCalendarYear7App(application, oesProperties) &&
      application?.isInArea === false &&
      !application?.eoiID
    )
  }
}
