<!--
  Displays content for the Actions panel (left of the application)
-->
<template>
  <div v-if="application" class="ApplicationAlerts">
    <h2>
      Actions
      {{
        !isWithdrawn && !application.ooa && totalAlerts
          ? `(${totalAlerts})`
          : ''
      }}
    </h2>

    <div class="alerts">
      <template v-if="!isWithdrawn && !application.ooa">
        <div v-if="totalAlerts === 0 || isSentToErn" class="successAlert">
          <font-awesome-icon
            focusable="false"
            icon="check"
            class="tickCircle"
          />
          <span v-if="isSentToErn">Sent to ERN</span>
          <span v-else>Ready to send to ERN</span>
        </div>
        <div v-if="isCheckForSrn">
          <h3>SRN Check</h3>
          <Alert :is-check-for-srn-alert="true" />
        </div>
        <div
          v-if="
            missingFields.length ||
            conflictFields.length ||
            unresolvedMatching.length ||
            invalidFields.length
          "
        >
          <h3></h3>
          <v-row>
            <v-col cols="2" class="py-0">
              <v-icon class="error--text" data-testid="resolve-issue-icon">
                error
              </v-icon>
            </v-col>
            <v-col cols="10" class="pa-0" data-testid="resolve-issue-error">
              Resolve remaining issues before sending to ERN
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-else-if="!(isWithdrawn || isDeclined || isAccepted)">
        <div class="infoAlert">
          <font-awesome-icon
            focusable="false"
            icon="exclamation-circle"
            class="exclamationCircle"
          />
          <span>Assess criteria and assign appropriate status</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/application/Alert.vue'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'

export default {
  name: 'ApplicationAlerts',
  components: { Alert },
  mixins: [fieldHelperMixin]
}
</script>

<style scoped lang="scss">
.ApplicationAlerts {
  h2 {
    padding: 0.75rem 0;
    margin: 0;
  }
  h3 {
    color: $color-secondary;
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 1rem 0;
    font-weight: normal;
  }
  ul {
    margin: 0;
    padding-left: 0;
    list-style-position: inside;
  }
  .alerts > div {
    padding-bottom: 1rem;
  }
}

.successAlert,
.infoAlert {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem 0;
  font-weight: bold;

  .tickCircle {
    width: 1em;
    height: 1em;
    color: white;
    background-color: green;
    border-radius: 1em;
    padding: 0.2em;
    font-size: 2rem;
    vertical-align: middle;
    margin-right: 0.25em;
  }

  .exclamationCircle {
    width: 1em;
    height: 1em;
    color: $color-primary;
    padding: 0.1em;
    font-size: 2rem;
    vertical-align: middle;
    margin-right: 0.25em;
  }

  span {
    vertical-align: middle;
  }
}

.infoAlert {
  display: flex;
  align-items: center;

  svg {
    align-self: start;
  }

  span {
    color: $color-primary;
    font-weight: normal;
  }
}
</style>
