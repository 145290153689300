var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.cssClasses},[_c('h3',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.heading)}}),(_vm.isRecordMatchingOptionsVisible)?_c('button',{ref:"resolveLinking",staticClass:"linking unstyled",attrs:{"type":"button"},on:{"click":function($event){return _vm.openResolveLinking()}}},[(_vm.modelRow.isMatchedRecord)?_c('div',{staticClass:"tickIcon"},[_c('font-awesome-icon',{attrs:{"focusable":"false","icon":"check"}})],1):_vm._e(),(_vm.modelRow.isMatchedRecord)?_c('span',{staticClass:"linkingText"},[_vm._v(" Matched in ERN: "),_c('span',[_vm._v("Change")])]):_vm._e(),(_vm.modelRow.isUnresolvedMatching && !_vm.isWithdrawn)?_c('div',{staticClass:"alertIcon"},[_c('font-awesome-icon',{attrs:{"focusable":"false","icon":"exclamation-triangle"}})],1):_vm._e(),(_vm.modelRow.isUnresolvedMatching && !_vm.isWithdrawn)?_c('span',{staticClass:"linkingText error--text"},[_vm._v(" Not found in ERN: "),_c('span',[_vm._v("Resolve")])]):_vm._e(),(_vm.modelRow.isNewRecord)?_c('div',{staticClass:"infoIcon"},[_c('font-awesome-icon',{attrs:{"focusable":"false","icon":"info"}})],1):_vm._e(),(_vm.modelRow.isNewRecord)?_c('span',{staticClass:"linkingText"},[_vm._v(" New record: "),_c('span',[_vm._v("Change")])]):_vm._e()]):_vm._e(),(
        _vm.modelRow.isUnmatchedErnRecord &&
        !_vm.modelRow.field.showGotoErnWhenUnmatched &&
        !_vm.isArchived
      )?_c('div',{staticClass:"unmatchedErnRecord"},[_c('div',{staticClass:"infoIcon"},[_c('font-awesome-icon',{attrs:{"focusable":"false","icon":"info"}})],1),_c('span',[_vm._v("Unmatched ERN record")])]):_vm._e(),(_vm.isRecordRemoveable)?_c('button',{staticClass:"remove unstyled",attrs:{"type":"button","title":'Remove ' + _vm.heading},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('font-awesome-icon',{attrs:{"focusable":"false","icon":"times"}})],1):_vm._e()]),(
      _vm.modelRow.isUnmatchedErnRecord &&
      _vm.modelRow.field.showGotoErnWhenUnmatched &&
      !_vm.isWithdrawn &&
      !_vm.isArchived
    )?_c('v-alert',{staticClass:"goToErnMessage",attrs:{"outlined":"","icon":"error"}},[_c('div',{staticClass:"messageContent"},[_c('div',[_vm._v(" Unmatched ERN record."),_c('br'),_vm._v("To remove this parent/carer from family tree please update in ERN ")]),_c('div',[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$store.dispatch('openErnFamilyTree')}}},[_vm._v("Go to ERN")])],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }