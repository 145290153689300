<template>
  <Banner
    :default-background1="true"
    icon="person_outline"
    icon-size="36"
    data-testid="application-view-header-banner"
  >
    <template #title>
      <v-row>
        <h1 class="appTitle">
          {{ getValue('student.familyName') }},
          {{ getValue('student.firstName') }}
          <Chip
            v-bind="getStatusChipDetails(getValue('applicationStatus'))"
            :class="statusBasedClass(getValue('applicationStatus'))"
            data-testid="application-view-header-application-status"
          />
        </h1>
      </v-row>
    </template>
    <template #subtitle>
      <v-row :class="isY67TApplication ? 'pt-2 ml-n10' : 'pt-2 ml-n8'">
        <v-col
          v-if="!isY67TApplication"
          class="pl-0 pt-0 text-no-wrap"
          data-testid="application-view-header-application-id"
        >
          <b>Application ID:</b><br />{{ getValue('applicationID') }}
        </v-col>
        <v-col
          v-if="isY67TApplication && isSecondarySchool"
          class="pt-0"
          data-testid="application-view-primary-srn-details"
        >
          {{ getValue('currentPrimarySchool.schoolName') }}<br />
          <b>SRN:</b> {{ getValue('student.SRN') }}
        </v-col>
        <v-col
          v-if="isOutOfAreaApplication || isY67TApplication"
          class="pt-0"
          data-testid="application-view-header-address"
        >
          <b>Home address:</b><br />
          {{ getValue('residentialAddress.addressLine1') }}<br />
          {{ getValue('residentialAddress.suburbName') }}
          {{ getValue('residentialAddress.stateCode') }}
          {{ getValue('residentialAddress.postCode') }}
        </v-col>
        <v-col
          class="pt-0"
          data-testid="application-view-header-contact-details"
        >
          <b>Contact details:</b><br />
          <span class="text-no-wrap">
            {{ parentCarerTitle }}
            {{ parentCarerGivenName }}
            {{ parentCarerFamilyName }}
            <a :href="`mailto:${contactDetails('EMAIL')}`" class="white--text">
              ({{ contactDetails('EMAIL') }})
            </a>
          </span>
          <br />{{ contactDetails('PHMOB') }}
        </v-col>
        <v-col
          v-if="isOutOfAreaApplication"
          class="pt-0"
          data-testid="application-view-header-siblings"
        >
          <b>Siblings attending:</b><br />{{ getValue('noOfSiblings') }}
        </v-col>
        <v-col v-if="isY67TApplication && isSecondarySchool" class="pt-0">
          <strong>School preference:</strong><br />{{ preferenceNo }}
        </v-col>
      </v-row>
    </template>
    <template #rightPannel>
      <div class="pagination"><RecordPagination /></div>
    </template>
  </Banner>
</template>

<script>
import { Banner, Chip } from '@nswdoe/doe-ui-core'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import RecordPagination from '@/components/application/RecordPagination.vue'
import { numToOrdinal } from '@/helpers/generalUtils'
import { mapGetters } from 'vuex'
import {
  STATUS_CHIPS,
  STATUS_CHIPS_DARK_BACKGROUND,
  OOA_STATUS_CHIPS_DETAILS_PAGE,
  Y67T_STATUS_CHIPS,
  Y67T_STATUS_CHIPS_DARK_BACKGROUND,
  STREAM
} from '@/constants'

export default {
  name: 'ApplicationViewHeader',
  components: { Banner, Chip, RecordPagination },
  props: {
    isLocalAreaApplication: {
      type: Boolean,
      default: false
    },
    isOutOfAreaApplication: {
      type: Boolean,
      default: false
    },
    isY67TApplication: {
      type: Boolean,
      default: false
    }
  },
  mixins: [fieldHelperMixin],
  computed: {
    ...mapGetters(['selectedSchool', 'application', 'currentStream']),
    contact() {
      if (this.isLocalAreaApplication) {
        const isCompletingApplication = this.getValue('parentCarers').find(
          (parent) => parent.isCompletingApplication === true
        )
        if (isCompletingApplication) {
          return isCompletingApplication
        } else {
          return this.getValue('parentCarers').find(
            (parent) => parent.isEnrolmentOwner === true
          )
        }
      }
      return this.getValue('parentCarers').find(
        (parent) => parent.parentCarerCompletingApp === true
      )
    },
    preferenceNo() {
      if (this.selectedSecondarySchoolData?.preferenceNo) {
        return numToOrdinal(this.selectedSecondarySchoolData.preferenceNo)
      }
      return ''
    },
    isSecondarySchool() {
      if (this.currentStream === STREAM.Y67T_SECONDARY) {
        return true
      } else if (
        this.currentStream === STREAM.ARCHIVE &&
        !this.$route.params.primary
      ) {
        return true
      }
      return false
    },
    parentCarerTitle() {
      return this.contact?.parentCarerTitle
    },
    parentCarerGivenName() {
      return this.contact?.parentCarerGivenName
    },
    parentCarerFamilyName() {
      return this.contact?.parentCarerFamilyName
    }
  },
  methods: {
    statusBasedClass(status) {
      return `application-status ${
        this.isY67TApplication ? `y67t-${status.toLowerCase()}` : ''
      }`
    },
    getStatusChipDetails(status) {
      if (this.isLocalAreaApplication) {
        return STATUS_CHIPS_DARK_BACKGROUND[status]
          ? STATUS_CHIPS_DARK_BACKGROUND[status]
          : STATUS_CHIPS[status]
      }
      if (this.isOutOfAreaApplication) {
        return OOA_STATUS_CHIPS_DETAILS_PAGE[status]
      }
      return Y67T_STATUS_CHIPS_DARK_BACKGROUND[status]
        ? Y67T_STATUS_CHIPS_DARK_BACKGROUND[status]
        : Y67T_STATUS_CHIPS[status]
    },
    contactDetails(type) {
      if (!this.isLocalAreaApplication && type === 'EMAIL') {
        return this.contact?.parentCarerEmail
      }
      const contact = this.contact?.contactDetails?.find(
        (contact) => contact.contactType === type
      )
      return contact?.contactValue
    }
  },
  created() {
    const ooaHighSchools = this.application.ooaHighSchools || []
    const localHighSchool = this.application.localHighSchool || {}
    this.selectedSecondarySchoolData = [
      ...ooaHighSchools,
      localHighSchool
    ].find((school) => school.schoolCode === this.selectedSchool.schoolCode)
  }
}
</script>

<style scoped lang="scss">
::v-deep .float-left {
  margin-left: 24px;
}
::v-deep .application-status {
  width: 142px;
  .v-chip__content span,
  .v-chip__content {
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
}

::v-deep .v-chip.v-chip--outlined.v-chip.v-chip.y67t-offered {
  background-color: $ads-primary-blue !important;
}

.appTitle {
  font-size: 34px;
  margin-left: 19px;
  padding-top: 3px;
}

@include desktop {
  .pagination {
    flex: 1;
    margin: 4rem 6rem 0 0;
  }
}
</style>
