<template>
  <v-app id="app" app>
    <AppSessionCountdownBar :minutes-warning="minutesTimeoutWarning" />
    <AppPrintHeader v-if="$store.getters.isPrintPreview" />
    <AppHeader v-else @fullScreenModalOpened="fullScreenModalOpened" />
    <v-main role="main" v-if="loaded" :aria-hidden="ariaHideApp">
      <v-container fluid>
        <IeWarning
          v-if="!hideIewWarning"
          class="ieWarning mx-3 mt-3"
          :class="{ sidebarMargin: isApplicationView }"
        />
        <keep-alive include="ApplicationListView">
          <router-view />
        </keep-alive>
      </v-container>
    </v-main>
    <AppSpinner />
    <AppPostForm v-if="isPostForm" />
    <AppMessageBox v-if="isShowMessageBox" />
    <AppSnackbar />
    <AppEnvironmentInfo />
  </v-app>
</template>

<script>
import API from '@/store/apiUtils'
import AppSessionCountdownBar from '@/components/app/AppSessionCountdownBar.vue'
import AppHeader from '@/components/app/AppHeader.vue'
import AppPrintHeader from '@/components/app/AppPrintHeader.vue'
import AppMessageBox from '@/components/app/AppMessageBox.vue'
import AppSnackbar from '@/components/app/AppSnackbar.vue'
import AppSpinner from '@/components/app/AppSpinner.vue'
import AppPostForm from '@/components/app/AppPostForm.vue'
import AppEnvironmentInfo from '@/components/app/AppEnvironmentInfo'
import { NODE_ENVIRONMENTS } from '@/constants'
import IeWarning from '@/components/app/IeWarning'
import { isWindowsOS } from '@/helpers/generalUtils'

export default {
  name: 'App',
  components: {
    AppSessionCountdownBar,
    AppHeader,
    AppPrintHeader,
    AppMessageBox,
    AppSnackbar,
    AppSpinner,
    AppPostForm,
    AppEnvironmentInfo,
    IeWarning
  },
  data() {
    return {
      minutesTimeoutWarning: parseInt(
        process.env.VUE_APP_ACTIVITY_TIMEOUT_WARNING_MINUTES
      ),
      loaded: false,
      ariaHideApp: false,
      isApplicationView: false
    }
  },
  methods: {
    fullScreenModalOpened(value) {
      this.ariaHideApp = value
      if (isWindowsOS() && value) {
        this.hideMainScrollbar()
      } else {
        this.showMainScrollbar()
      }
    },
    hideMainScrollbar() {
      const styleForScrollbar = document.createElement('style')
      styleForScrollbar.id = 'style-for-scrollbar'
      styleForScrollbar.innerHTML =
        'html::-webkit-scrollbar { display: none; } html { scrollbar-width: none; }'
      document.head.appendChild(styleForScrollbar)
    },
    showMainScrollbar() {
      const styleForScrollbar = document.querySelector('#style-for-scrollbar')
      if (styleForScrollbar) {
        document.head.removeChild(styleForScrollbar)
      }
    }
  },
  computed: {
    isShowMessageBox() {
      return this.$store.state.messageBox
    },
    isPostForm() {
      return this.$store.state.postForm
    },
    userID() {
      return this.$store.getters.userID
    }
  },
  beforeCreate() {
    if (API.isOfflineTesting) {
      this.$store.dispatch('showMessageBox', {
        message: 'OFFLINE TEST DATA ACTIVE'
      })
    }

    //Set if we're in training environment or not.
    this.$store.dispatch('set', [
      'inTrainingMode',
      process.env.VUE_APP_ENV_NAME &&
        process.env.VUE_APP_ENV_NAME !== NODE_ENVIRONMENTS.PROD &&
        process.env.VUE_APP_TRAINING_MODE === 'true'
    ])

    // We need to pass this information between components
    // so that focus and scroll can be set when the components
    // mount. However they don't need reactivity and attempts at
    // using the Store for this have resulted in the page jumping
    // before setting the correct scroll position. More research
    // to be done on finding a better way...
    window.OES = {
      initialFocusField: null,
      initialFieldOffset: null,
      initialSectionOffset: null
    }
  },
  created() {
    this.$store.dispatch('setCurrentUser')
  },
  beforeMount() {
    this.$activityMonitor.activate()
  },
  beforeDestroy() {
    this.$activityMonitor.deactivate()
  },
  watch: {
    userID(userId) {
      if (!this.loaded && userId && userId !== '') {
        // oauth loaded. Kick off app loading
        this.$store.dispatch('getUserGroupAndSchools')
        this.loaded = true
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        // #DSE-1327: The following fixes an issue with IE11 where the browser was
        // not refreshing a view upon returning to a previous view. The use of
        // browser sniffing is regrettable, but otherwise unavoidable in this
        // instance.
        // Reference: github.com/vuejs/vue-router/issues/1849#issuecomment-427744124
        if (
          '-ms-scroll-limit' in document.documentElement.style &&
          '-ms-ime-align' in document.documentElement.style
        ) {
          // Restrict the forced update to ApplicationListView
          if (to.path === '/') {
            this.$forceUpdate()
          }
        }

        // I know, but ie11 doesn't support .startsWith()
        this.isApplicationView = to.path.indexOf('/application') === 0
        this.hideIewWarning = to.name === 'PrintView'
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/app.scss';
.v-application .ieWarning.sidebarMargin {
  margin-left: calc(#{$desktop-sidebar-width} + 12px) !important;
}
</style>
