<!-- 
  Displays bar at top of application form with the "Back" button
-->
<template>
  <nav class="ApplicationNavBar" aria-label="breadcrumb">
    <div class="back float-left">
      <router-link v-if="!isEditing" to="/" class="unstyled">
        <v-icon>arrow_back</v-icon>
      </router-link>
      <span v-else>
        <v-icon disabled>arrow_back</v-icon>
      </span>
    </div>
    <div class="ml-3 pt-1 float-left">
      <ModifiedByInfo
        v-if="
          isOutOfAreaApplication &&
          (!isLastUpdatedByParentEmail(application.updatedBy) ||
            isParentDeclinedOffer ||
            isParentAcceptedOffer)
        "
        :last-modified-by="application.updatedBy"
        :last-modified="application.lastUpdatedDateTime"
        :parent-declined-offer="isParentDeclinedOffer"
        :parent-accepted-offer="isParentAcceptedOffer"
        :parent-notified-by-email="isParentNotifiedByEmail"
      />
      <ModifiedByInfo
        v-if="
          isY67TApplication &&
          ((application.isPrimary &&
            !isLastUpdatedByParentEmail(application.updatedBy)) ||
            checkStatus(applicationStatus) ||
            checkNewStatus(applicationStatus) ||
            isActiveLocalPaper)
        "
        :last-modified-by="application.updatedBy"
        :last-modified="application.lastUpdatedDateTime"
        :email-sent="
          application.isEmailSentForHSAction ||
          application.emailSentCoreAppSubmittedForLHS
        "
        :new-status-with-parent-update="
          checkNewStatus(application.applicationStatus) &&
          isLastUpdatedByParentEmail(application.updatedBy)
        "
      />
      <ModifiedByInfo
        v-if="
          isLocalAreaApplication &&
          !isLastUpdatedByParentEmail(application.lastModifiedUser)
        "
        :last-modified-by="application.lastModifiedUser"
        :last-modified="application.lastModifiedDatetime"
      />
    </div>
    <div
      class="download-btn float-right"
      v-if="
        isDownloadAvailable &&
        !isOesSupport &&
        application &&
        application.applicationID &&
        isERNAdmin
      "
    >
      <DownloadDocument
        :school-code="application.schoolCode"
        :application-type="state.applicationType"
      />
    </div>
    <div class="print float-right" v-if="isLocalAreaApplication">
      <router-link
        :to="'/print/' + application.applicationID"
        class="unstyled"
        :disabled="isEditing"
      >
        <font-awesome-icon focusable="false" class="icon" icon="print" />
        <span>Print</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
import DownloadDocument from '@/components/application/DownloadDocument.vue'
import ModifiedByInfo from '@/components/application/ModifiedByInfo.vue'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import {
  USER_GROUP,
  OOA_STATUS,
  Y67T_STATUS,
  OOA_OFFERED_STATUSES
} from '@/constants'

export default {
  name: 'ApplicationNavBar',
  components: {
    DownloadDocument,
    ModifiedByInfo
  },
  mixins: [fieldHelperMixin],
  computed: {
    state() {
      return this.$store.state
    },
    application() {
      return this.state.application || {}
    },
    applicationStatus() {
      return this.application.applicationStatus
    },
    isDownloadAvailable() {
      return (
        this.state.applicationType === 'OOA' ||
        this.state.applicationType === 'Y67T'
      )
    },
    isLocalAreaApplication() {
      return this.state.applicationType === 'LA'
    },
    isOutOfAreaApplication() {
      return this.state.applicationType === 'OOA'
    },
    isY67TApplication() {
      return this.state.applicationType === 'Y67T'
    },
    isOesSupport() {
      if (this.state.applicationType === 'Y67T') {
        return false
      }
      return this.$store.state.userGroup === USER_GROUP.OES_SUPPORT
    },
    isERNAdmin() {
      return this.$store.state.userGroup === USER_GROUP.ERN_ADMIN
    },
    isParentDeclinedOffer() {
      return (
        this.applicationStatus === OOA_STATUS.NOT_ACCEPTED &&
        this.application.updatedUserGroup === USER_GROUP.PARENT
      )
    },
    isParentNotifiedByEmail() {
      const isSentByEmail =
        this.application.changeOutcomeEmailSent === undefined
          ? this.application.updateEmailSent
          : this.application.changeOutcomeEmailSent
      return (
        (OOA_OFFERED_STATUSES.includes(this.applicationStatus) ||
          this.applicationStatus === OOA_STATUS.DECLINED ||
          this.applicationStatus === OOA_STATUS.DECLINED_POST_APPEAL ||
          this.applicationStatus === OOA_STATUS.WITHDRAWN ||
          this.applicationStatus === OOA_STATUS.OFFER_EXPIRED ||
          this.applicationStatus === OOA_STATUS.NOT_ACCEPTED) &&
        isSentByEmail
      )
    },
    isParentAcceptedOffer() {
      return (
        this.applicationStatus === OOA_STATUS.ACCEPTED &&
        this.application.updatedUserGroup === USER_GROUP.PARENT
      )
    },
    isActiveLocalPaper() {
      const lastItem =
        this.application.comment[this.application.comment.length - 1]
      return (
        this.applicationStatus === Y67T_STATUS.ACTIVE &&
        (lastItem.eventName === 'ERNPaperLocalAppReceivedForLHS' ||
          lastItem.eventName === 'UnLinkedSRN') &&
        lastItem.appStatus === Y67T_STATUS.ACTIVE
      )
    }
  },
  methods: {
    isLastUpdatedByParentEmail(val) {
      return val && val.indexOf('@') !== -1
    },
    checkStatus(appStatus) {
      return (
        appStatus === Y67T_STATUS.OFFERED ||
        appStatus === Y67T_STATUS.DECLINED ||
        appStatus === Y67T_STATUS.WITHDRAWN ||
        appStatus === Y67T_STATUS.NOT_ACCEPTED ||
        appStatus === Y67T_STATUS.SUBMITTED ||
        appStatus === Y67T_STATUS.OFFER_EXPIRED
      )
    },
    checkNewStatus(appStatus) {
      return appStatus === Y67T_STATUS.NEW
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationNavBar {
  padding: 1.5rem 3rem 2.5rem 3rem;
  div.back.float-left a {
    text-decoration: none !important;
  }
  .icon {
    font-size: 1.5em;
    vertical-align: middle;
    margin-right: 0.5em;
  }
  .back,
  .print,
  .download-btn {
    display: inline-block;
    white-space: nowrap;
    span {
      vertical-align: middle;
    }
  }
  .print,
  .download-btn {
    text-align: right;
  }
}
</style>
