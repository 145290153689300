<template>
  <div v-show="state.application" :class="isEditing ? 'isEditing' : ''">
    <div class="content">
      <div class="sideContent">
        <ArchiveAlerts v-if="isArchived" />
        <Y67TAlerts v-else-if="isY67TApplication" data-testid="y67t-alerts" />
        <Alerts v-else-if="!isY67TApplication" />
      </div>
      <div v-if="state.application" class="mainContent">
        <ApplicationViewHeader
          :is-local-area-application="isLocalAreaApplication"
          :is-out-of-area-application="isOutOfAreaApplication"
          :is-y67-t-application="isY67TApplication"
        />
        <NavBar />
        <div v-if="isY67TApplication && !isArchived">
          <div class="mr-4 clear">
            <Y67TSummary
              :focus-button="focusButton"
              @focusReset="focusReset"
              @setStatus="handleY67TSetStatus"
              @withdraw="handleY67TWithdraw"
              @sendToTracking="handleY67TSendToTracking"
            />
          </div>
          <div class="clear">
            <SrnMatchStatusBar
              @setStatus="handleY67TSetStatus"
              :focus-button="focusButton"
              @focusReset="focusReset"
            />
          </div>
        </div>
        <template v-if="isLocalAreaApplication && !isArchived">
          <SrnStatusBar />
          <EhubPrefillNotification v-if="isEhubLinkedStudent || parentId" />
          <SafeSummary />
        </template>
        <div class="clear">
          <ApplicationHistory
            v-if="isY67TApplication"
            data-testid="y67t-app-history"
          />
        </div>
        <div class="card" :class="isArchived ? 'mt-6' : ''">
          <template v-if="isOutOfAreaApplication">
            <OoaSummary :school-code="routeSchoolCode" />
            <OoaForm />
          </template>
          <Sections v-if="isLocalAreaApplication" />
          <Y67TForm v-if="isY67TApplication && y67TApplicationType === 'EOI'" />
          <Y67TPnrForm
            v-if="isY67TApplication && y67TApplicationType === 'PNR'"
          />
        </div>
      </div>
    </div>
    <ResolveConflict v-if="state.resolveConflict" />
    <ResolveLinking v-if="state.resolveLinking" />
    <SrnSearch v-if="state.showSrnSearch" />
    <SrnMatchSearch v-if="isY67TApplication && showSrnMatchSearch" />
    <EditAddress v-if="state.editAddress" />
    <SendToErnProgress v-if="state.isSendingToErn" />
    <WithdrawApplication v-if="state.openWithdrawModal" />
    <UnlinkSrn v-if="state.openUnlinkSrnModal" />
    <Y67TWithdrawModal
      v-model="showY67TWithdrawnModal"
      :student-name="studentName"
      :is-pnr-advice="isY67TApplication && y67TApplicationType === 'PNR'"
    />
    <Y67TSendToERNModal v-model="showY67TSendToERNModal" />
    <SetStatusModalY67T
      v-model="showY67TSetStatusModal"
      @focus="setButtonFocus"
      :student-name="studentName"
      :parent-name="parentName"
      :status="selectedStatus"
      :is-change="isChange"
    />
    <ConfirmAddress v-if="isY67TApplication && showConfirmAddress" />
  </div>
</template>

<script>
import ApplicationViewHeader from '@/views/ApplicationViewHeader.vue'
import ArchiveAlerts from '@/components/application/ArchiveAlerts.vue'
import Alerts from '@/components/application/Alerts.vue'
import EditAddress from '@/components/application/EditAddress.vue'
import NavBar from '@/components/application/NavBar.vue'
import ResolveConflict from '@/components/application/ResolveConflict.vue'
import ResolveLinking from '@/components/application/ResolveLinking.vue'
import Sections from '@/components/application/Sections.vue'
import OoaForm from '@/components/application/ooa/form/Form.vue'
import Y67TForm from '@/components/application/y67t/form/Form.vue'
import Y67TPnrForm from '@/components/application/y67t/form/PnrForm.vue'
import SendToErnProgress from '@/components/application/SendToErnProgress.vue'
import SrnSearch from '@/components/application/SrnSearch.vue'
import SrnStatusBar from '@/components/application/SrnStatusBar.vue'
import EhubPrefillNotification from '@/components/application/EhubPrefillNotification.vue'
import Summary from '@/components/application/Summary.vue'
import OoaSummary from '@/components/application/OoaSummary.vue'
import Y67TSummary from '@/components/application/y67t/Y67TSummary.vue'
import ApplicationHistory from '@/components/application/y67t/ApplicationHistory.vue'
import WithdrawApplication from '@/components/application/WithdrawApplication.vue'
import UnlinkSrn from '@/components/application/UnlinkSrn.vue'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import SrnMatchStatusBar from '@/components/application/y67t/SrnMatchStatusBar.vue'
import SrnMatchSearch from '@/components/application/y67t/SrnMatchSearch.vue'
import Y67TWithdrawModal from '@/components/application/y67t/WithdrawModal'
import Y67TSendToERNModal from '@/components/application/y67t/SendToERNConfirmationModal'
import SetStatusModalY67T from '@/components/application/y67t/SetStatusModalY67T'
import Y67TAlerts from '@/components/application/y67t/Y67TAlerts'
import ConfirmAddress from '@/components/application/y67t/ConfirmAddress'
import { STREAM, Y67T_STATUS } from '@/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplicationView',
  components: {
    ApplicationViewHeader,
    ApplicationHistory,
    SrnMatchSearch,
    ArchiveAlerts,
    Alerts,
    EditAddress,
    NavBar,
    ResolveConflict,
    ResolveLinking,
    Sections,
    SendToErnProgress,
    SrnSearch,
    SrnStatusBar,
    SrnMatchStatusBar,
    WithdrawApplication,
    UnlinkSrn,
    SafeSummary: Summary,
    OoaSummary,
    Y67TSummary,
    Y67TWithdrawModal,
    Y67TSendToERNModal,
    SetStatusModalY67T,
    Y67TAlerts,
    ConfirmAddress,
    OoaForm,
    Y67TForm,
    Y67TPnrForm,
    EhubPrefillNotification
  },
  mixins: [fieldHelperMixin],
  data() {
    return {
      showY67TWithdrawnModal: false,
      showY67TSendToERNModal: false,
      showY67TSetStatusModal: false,
      selectedStatus: null,
      isChange: false,
      focusButton: ''
    }
  },
  computed: {
    ...mapGetters([
      'selectedSchool',
      'isEhubLinkedStudent',
      'parentId',
      'isAddressConflict',
      'showConfirmAddress',
      'showSrnMatchSearch',
      'isAddressMatched',
      'y67tSearchFilter',
      'currentStream'
    ]),
    state() {
      return this.$store.state
    },
    isDirty() {
      return (
        this.$store.getters.isEditing &&
        JSON.stringify(this.state.application) !==
          JSON.stringify(this.state.cleanApplication)
      )
    },
    application() {
      return this.$store.getters.application || {}
    },
    isLocalAreaApplication() {
      return this.$store.state.applicationType === 'LA'
    },
    isOutOfAreaApplication() {
      return this.$store.state.applicationType === 'OOA'
    },
    isY67TApplication() {
      return this.$store.state.applicationType === 'Y67T'
    },
    y67TApplicationType() {
      if (
        this.isY67TApplication &&
        this.application.adviceType &&
        this.application.adviceType === 'PNR'
      ) {
        return 'PNR'
      }
      return 'EOI'
    },
    studentName() {
      if (this.application && this.application.student) {
        return (
          [
            this.application.student.firstName,
            this.application.student.familyName
          ]
            .join(' ')
            .trim() || 'this student'
        )
      }
      return ''
    },
    parentName() {
      if (this.application?.parentCarers) {
        return (
          [
            this.application.parentCarers[0].parentCarerGivenName,
            this.application.parentCarers[0].parentCarerFamilyName
          ]
            .join(' ')
            .trim() || 'this parent'
        )
      }
      return ''
    },
    routeSchoolCode() {
      return this.$route ? this.$route.params.schoolCode : null
    },
    isArchived() {
      return this.currentStream === STREAM.ARCHIVE
    }
  },
  methods: {
    setButtonFocus(value) {
      this.focusButton = value
    },
    focusReset() {
      this.focusButton = ''
    },
    handleY67TWithdraw() {
      this.showY67TWithdrawnModal = true
    },
    handleY67TSendToTracking() {
      localStorage.setItem(
        'y67tSearch',
        this.y67tSearchFilter('PRIMARYEOILIST')
      )
      const otherEOIs = this.application.otherEOIs
      if (otherEOIs && otherEOIs.length > 0) {
        this.showY67TSendToERNModal = true
      } else {
        this.$store
          .dispatch(
            'sendToTracking',
            this.$store.state.application.applicationID
          )
          .then(() => {
            this.$router.push({ path: '/' })
            const studentName = `${this.application.student.firstName} ${this.application.student.familyName}`
            const message = `<strong>${studentName}'s</strong> application has been sent to HS.`
            this.$store.dispatch('showSnackbar', {
              display: true,
              html: message,
              icon: {
                name: 'mdi-send',
                color: 'success'
              }
            })
          })
      }
    },
    handleY67TSetStatus(status) {
      status ? (this.selectedStatus = status) : (this.isChange = true)
      this.showY67TSetStatusModal = true
    },
    loadApplication(id, schoolCode) {
      if (this.isY67TApplication) {
        this.$store.commit('setIsAddressConflict', false)
        this.$store.commit('setReadyToSendErn', false)
        this.$store.commit('setIsAddressMatched', false)
      }
      if (!id || !schoolCode) {
        id = this.$route.params.id
        schoolCode = this.$route.params.schoolCode
      }

      //handle the case where userGroup info will be lost on page refresh.
      if (!this.$store.state.userGroup) {
        this.$store.dispatch('getUserGroupAndSchools')
      }

      if (schoolCode && !this.$store.getters.selectedSchool) {
        // user probably refreshed the screen or navigated via link

        const schools = this.$store.state.schools
        if (schools) {
          const selectedSchool = schools.find(
            (s) => s.schoolCode === schoolCode
          )
          if (selectedSchool) {
            this.$store.commit('set', ['selectedSchool', selectedSchool])
          } else {
            // you don't have access to this school
            this.$router.push('/')
          }
        }
      }
      if (id.startsWith('OOA-')) {
        // OOA application
        this.$store.dispatch('set', ['applicationType', 'OOA'])
      } else if (id.startsWith('Y67T-')) {
        // Y67T application
        this.$store.dispatch('set', ['applicationType', 'Y67T'])
        this.$store.commit('setIsAddressConflict', false)
        this.$store.commit('setReadyToSendErn', false)
        this.$store.commit('setIsAddressMatched', false)
      } else {
        // Local area application
        this.$store.dispatch('set', ['applicationType', 'LA'])
      }
      this.$store.dispatch('set', ['sectionExpandedId', null]) // Collapse sections when opening an application

      this.$store.dispatch('getApplication', [id, schoolCode]).then(() => {
        if (this.isY67TApplication && this.y67TApplicationType === 'EOI') {
          this.addressValidation()
        } else {
          if (
            this.application &&
            this.application.applicationStatus === Y67T_STATUS.IN_PROGRESS &&
            this.application.student.SRN
          ) {
            this.$store.commit('setIsAddressConflict', false)
            this.$store.commit('setReadyToSendErn', true)
          }
        }
      })

      // Get all Australian schools for AuSchoolLookUp
      if (!this.$store.state.auSchools) {
        this.$store.dispatch('getAllAuSchools').then((auSchools) => {
          this.$store.commit('set', ['auSchools', auSchools.data])
        })
      }
    },
    addressValidation() {
      if (
        this.application &&
        this.application.applicationStatus === Y67T_STATUS.IN_PROGRESS &&
        this.application.student.SRN &&
        !(this.application.adviceType && this.application.adviceType === 'PNR')
      ) {
        this.$store.dispatch('getAddressForSRN', this.application.student.SRN)
      }
    }
  },
  mounted() {
    this.loadApplication()

    // Warn if trying to navigate away with unsaved data
    var me = this
    window.onbeforeunload = function (e) {
      if (me.isDirty) {
        e.preventDefault()
        return ''
      }
    }
  },
  // Necessary when switching between different applications
  beforeRouteUpdate(to, from, next) {
    this.loadApplication(to.params.id, to.params.schoolCode)
    next()
  }
}
</script>

<style scoped lang="scss">
$header-height: 64px;

.card {
  background-color: white;
}
.content,
.sideContent,
.mainContent {
  background-color: #f5f5f5;
}
.sideContent {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
}

::v-deep .theme--light.v-chip[outlined='true'] {
  border: 1px solid !important;
}

// Desktop layout shows Actions as a left sidebar
@include desktop {
  .content {
    height: 100%;
    width: 100%;
  }
  .card {
    margin: 0 3rem;
  }
  .content,
  .mainContent {
    position: absolute;
  }
  .sideContent {
    position: fixed;
    height: calc(100% - #{$header-height});
    overflow-x: hidden;
    overflow-y: auto;
    top: $header-height;
    left: 0;
    width: $desktop-sidebar-width;
    padding: 1rem 1rem 1rem 2rem;
    border-bottom: 0;
  }
  .mainContent {
    top: 0;
    left: $desktop-sidebar-width;
    width: calc(100% - #{$desktop-sidebar-width});
  }

  .isEditing .sideContent {
    padding-bottom: $save-bar-height; // Extra padding for when save bar is visible, so bottom content can be reached
  }

  .isEditing .mainContent {
    padding-bottom: $save-bar-height;
  }
  .clear {
    clear: both;
  }
}
</style>
